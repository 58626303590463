import {
  formatDate,
  formatMoney,
  secondsToTime,
} from '@/features/utils/format';
import classnames from 'classnames';
import { memo } from 'react';

export interface FormattedDurationProps {
  value?: number | null;
  unit?: string;
}

const FormattedDuration = memo<FormattedDurationProps>((props) => (
  <>
    {props.value !== null && props.value !== undefined
      ? `${secondsToTime(props.value * 60)} ${props.unit || ''}`.trim()
      : 'n. a.'}
  </>
));

export interface FormattedDateProps {
  value?: Date | string | null;
  withTime?: boolean;
  format?: string;
}

const FormattedDate = memo<FormattedDateProps>((props) =>
  props.value ? (
    <>{formatDate(props.value, props.withTime, props.format)}</>
  ) : null
);

export interface FormattedCurrencyProps {
  value?: number;
  colored?: boolean;
  precision?: number;
}

const FormattedCurrency = memo<FormattedCurrencyProps>((props) => {
  const { value, colored, precision = 2 } = props;
  if (value === undefined) {
    return <></>;
  }
  return (
    <span
      className={classnames('ui currency', {
        ok: colored && value > 0,
      })}
    >
      {formatMoney(value, precision)}
    </span>
  );
});

export const Formatted = Object.assign(
  {},
  {
    Date: FormattedDate,
    Currency: FormattedCurrency,
    Duration: FormattedDuration,
  }
);
