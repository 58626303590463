import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import { ProjectListDataFragmentDoc } from '../projects/list/list.generated';
import { CustomerListDataFragmentDoc } from '../customers/list/list.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserListDataFragment = { __typename?: 'User', id: any, firstName: string, lastName: string, initials?: string | null, email: string, label: string, active: boolean };

export type ProductListDataFragment = { __typename?: 'Product', id: any, name: string, amount: { __typename?: 'Amount', type: Types.AmountType, value: number, net: number, gross: number, tax: number } };

export type InvoiceListDataFragment = { __typename?: 'Invoice', id: any, createdAt: any, sentAt?: any | null };

export type ResourcesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ResourcesQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', id: any, projectNumber?: number | null, title: string, slugs?: string | null, timeSpent: number, amountPerHour?: number | null, status: Types.ProjectStatus, customer: { __typename?: 'Customer', id: any, firstName?: string | null, lastName?: string | null, companyName?: string | null, contactEmail: string, slugs: string, amountPerHour?: number | null } }>, customers: Array<{ __typename?: 'Customer', id: any, customerNumber?: number | null, firstName?: string | null, lastName?: string | null, companyName?: string | null, contactEmail: string, phone?: string | null, label: string, longLabel: string, slugs: string, amountPerHour?: number | null, timeSpent: number }>, users: Array<{ __typename?: 'User', id: any, firstName: string, lastName: string, initials?: string | null, email: string, label: string, active: boolean }>, products: Array<{ __typename?: 'Product', id: any, name: string, amount: { __typename?: 'Amount', type: Types.AmountType, value: number, net: number, gross: number, tax: number } }>, invoices: Array<{ __typename?: 'Invoice', id: any, createdAt: any, sentAt?: any | null }>, tags: Array<{ __typename?: 'Tag', id: any, name: string, context: string }> };

export const UserListDataFragmentDoc = gql`
    fragment UserListData on User {
  id
  firstName
  lastName
  initials
  email
  label
  active
}
    `;
export const ProductListDataFragmentDoc = gql`
    fragment ProductListData on Product {
  id
  name
  amount {
    type
    value
    net
    gross
    tax
  }
}
    `;
export const InvoiceListDataFragmentDoc = gql`
    fragment InvoiceListData on Invoice {
  id
  createdAt
  sentAt
}
    `;
export const ResourcesDocument = gql`
    query resources {
  projects {
    ...ProjectListData
  }
  customers {
    ...CustomerListData
  }
  users {
    ...UserListData
  }
  products {
    ...ProductListData
  }
  invoices {
    ...InvoiceListData
  }
  tags {
    id
    name
    context
  }
}
    ${ProjectListDataFragmentDoc}
${CustomerListDataFragmentDoc}
${UserListDataFragmentDoc}
${ProductListDataFragmentDoc}
${InvoiceListDataFragmentDoc}`;

/**
 * __useResourcesQuery__
 *
 * To run a query within a React component, call `useResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useResourcesQuery(baseOptions?: Apollo.QueryHookOptions<ResourcesQuery, ResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourcesQuery, ResourcesQueryVariables>(ResourcesDocument, options);
      }
export function useResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourcesQuery, ResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourcesQuery, ResourcesQueryVariables>(ResourcesDocument, options);
        }
export function useResourcesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ResourcesQuery, ResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ResourcesQuery, ResourcesQueryVariables>(ResourcesDocument, options);
        }
export type ResourcesQueryHookResult = ReturnType<typeof useResourcesQuery>;
export type ResourcesLazyQueryHookResult = ReturnType<typeof useResourcesLazyQuery>;
export type ResourcesSuspenseQueryHookResult = ReturnType<typeof useResourcesSuspenseQuery>;
export type ResourcesQueryResult = Apollo.QueryResult<ResourcesQuery, ResourcesQueryVariables>;