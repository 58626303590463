import { Tag } from '@/graphql/generated/types';

export function getTagColor(tag: Tag) {
  let hash = 0;
  if (tag.context.length === 0) return '#000000';
  for (let i = 0; i < tag.context.length; i++) {
    hash = tag.context.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 255;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}
