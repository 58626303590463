import { Customer } from '@/graphql/generated/types';
import * as globalStyles from '@/styles/globals.scss';
import { ComponentType } from 'react';

interface CustomerLabelProps {
  customer?: Pick<Customer, 'customerNumber' | 'label'>;
}

export const CustomerLabel: ComponentType<CustomerLabelProps> = (props) => {
  const { customer } = props;
  if (!customer) {
    return <></>;
  }
  return (
    <>
      <span className={globalStyles.highlight}>{customer.customerNumber}</span>{' '}
      {customer.label}
    </>
  );
};
