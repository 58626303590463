import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ServicesByCustomerQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type ServicesByCustomerQuery = { __typename?: 'Query', servicesByCustomer: Array<{ __typename?: 'Service', id: any, startDate: any, endDate?: any | null, invoicePeriod: number, product: { __typename?: 'Product', id: any, name: string } }> };


export const ServicesByCustomerDocument = gql`
    query servicesByCustomer($id: UUID!) {
  servicesByCustomer(id: $id) {
    id
    startDate
    endDate
    invoicePeriod
    product {
      id
      name
    }
  }
}
    `;

/**
 * __useServicesByCustomerQuery__
 *
 * To run a query within a React component, call `useServicesByCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesByCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesByCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServicesByCustomerQuery(baseOptions: Apollo.QueryHookOptions<ServicesByCustomerQuery, ServicesByCustomerQueryVariables> & ({ variables: ServicesByCustomerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesByCustomerQuery, ServicesByCustomerQueryVariables>(ServicesByCustomerDocument, options);
      }
export function useServicesByCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesByCustomerQuery, ServicesByCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesByCustomerQuery, ServicesByCustomerQueryVariables>(ServicesByCustomerDocument, options);
        }
export function useServicesByCustomerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ServicesByCustomerQuery, ServicesByCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ServicesByCustomerQuery, ServicesByCustomerQueryVariables>(ServicesByCustomerDocument, options);
        }
export type ServicesByCustomerQueryHookResult = ReturnType<typeof useServicesByCustomerQuery>;
export type ServicesByCustomerLazyQueryHookResult = ReturnType<typeof useServicesByCustomerLazyQuery>;
export type ServicesByCustomerSuspenseQueryHookResult = ReturnType<typeof useServicesByCustomerSuspenseQuery>;
export type ServicesByCustomerQueryResult = Apollo.QueryResult<ServicesByCustomerQuery, ServicesByCustomerQueryVariables>;