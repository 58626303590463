import { Profile } from '@/features/profile/profile.context';
import { useResources } from '@/features/resources/resources.provider';
import { getTagColor } from '@/features/tags/util';
import { DebouncedInput } from '@/features/ui/debounced-input/debounced-input.component';
import { useUrlQuery } from '@/features/utils/url-query';
import { format, formatISO, parseISO } from 'date-fns';
import { ComponentType } from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Form, Label, Select } from 'semantic-ui-react';

export interface FilterState {
  query?: string | null;
  userId: string | null;
  projectId: string | null;
  customerId: string | null;
  range: string | null;
  billed: string | null;
  flagged: string | null;
  tags: string;
  excludeTags: string;
  billable: string | null;
}

export const Filter: ComponentType = (props) => {
  const { users, projects, customers, tags } = useResources();

  const today = format(new Date(), 'yyyy-MM-dd');

  const profile = Profile.use();

  const [urlQuery, updateUrlQuery] = useUrlQuery<
    FilterState & { skip: number }
  >();

  return (
    <Form>
      <Form.Group style={{ flexWrap: 'wrap' }}>
        <Form.Field>
          <label>
            Date{' '}
            <Label
              size="tiny"
              basic
              compact
              content="Today"
              onClick={() =>
                updateUrlQuery({ ...urlQuery, range: `${today},${today}` })
              }
            />
          </label>

          <SemanticDatepicker
            locale="de-DE"
            type="range"
            value={
              urlQuery.range
                ? urlQuery.range.split(',').map((d) => parseISO(d))
                : null
            }
            onChange={(_, data) => {
              if (data.value === null) {
                updateUrlQuery({ ...urlQuery, range: '', skip: 0 });
              } else if ((data.value as Date[]).length === 2) {
                updateUrlQuery({
                  ...urlQuery,
                  range: (data.value as Date[])
                    .map((d) => formatISO(d))
                    .join(','),
                  skip: 0,
                });
              }
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>Search</label>
          <DebouncedInput
            placeholder="Timelog, Issue, Customer, Company, Project, ..."
            delay={500}
            clearable
            type="text"
            value={urlQuery.query || ''}
            onDebouncedChange={(v) => updateUrlQuery({ ...urlQuery, query: v })}
          />
        </Form.Field>
        <Form.Field>
          <label>
            User{' '}
            <Label
              size="tiny"
              basic
              compact
              content="Me"
              onClick={() =>
                updateUrlQuery({ ...urlQuery, userId: profile?.id })
              }
            />
          </label>
          <Select
            search
            multiple={false}
            clearable
            options={[
              { text: '', value: '' },
              ...users.map((u) => ({
                text: u.label,
                value: u.id,
              })),
            ]}
            value={urlQuery.userId || undefined}
            onChange={(_, data) => {
              updateUrlQuery({
                ...urlQuery,
                userId: data.value as string,
                skip: 0,
              });
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>Project</label>
          <Select
            search
            multiple={false}
            clearable
            options={[
              { text: '', value: '' },
              ...projects.map((p) => ({
                text: `${p.title} (${p.customer.companyName})`,
                value: p.id,
              })),
            ]}
            value={urlQuery.projectId || undefined}
            onChange={(_, data) => {
              updateUrlQuery({
                ...urlQuery,
                projectId: data.value as string,
                skip: 0,
              });
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>Customer</label>
          <Select
            search
            multiple={false}
            clearable
            options={[
              { text: '', value: '' },
              ...customers.map((c) => ({
                text: c.label,
                value: c.id,
              })),
            ]}
            value={urlQuery.customerId || ''}
            onChange={(_, data) => {
              updateUrlQuery({
                ...urlQuery,
                customerId: data.value as string,
                skip: 0,
              });
            }}
          />
        </Form.Field>

        <Form.Field>
          <label>Billable</label>
          <Select
            search
            multiple={false}
            clearable
            options={[
              { text: '', value: '' },
              {
                text: 'Yes',
                value: 'true',
              },
              {
                text: 'No',
                value: 'false',
              },
            ]}
            value={urlQuery.billable || ''}
            onChange={(_, data) => {
              updateUrlQuery({
                ...urlQuery,
                billable: data.value as string,
                skip: 0,
              });
            }}
          />
        </Form.Field>

        <Form.Field>
          <label>Billed status</label>
          <Select
            search
            multiple={false}
            clearable
            options={[
              { text: '', value: '' },
              {
                text: 'Billed',
                value: 'true',
              },
              {
                text: 'Unbilled',
                value: 'false',
              },
            ]}
            value={urlQuery.billed || ''}
            onChange={(_, data) => {
              updateUrlQuery({
                ...urlQuery,
                billed: data.value as string,
                skip: 0,
              });
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>Flagged</label>
          <Select
            search
            multiple={false}
            clearable
            options={[
              { text: '', value: '' },
              {
                text: 'Yes',
                value: 'true',
              },
              {
                text: 'No',
                value: 'false',
              },
            ]}
            value={urlQuery.flagged || ''}
            onChange={(_, data) => {
              updateUrlQuery({
                ...urlQuery,
                flagged: data.value as string,
                skip: 0,
              });
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <label>Tags</label>
          <Select
            search
            multiple
            clearable
            options={tags.map((t) => ({
              text: t.name,
              value: t.id,
              description: (
                <span style={{ color: getTagColor(t) as string }}>
                  {t.context}
                </span>
              ),
            }))}
            value={urlQuery.tags?.split(',')}
            onChange={(_, data) => {
              updateUrlQuery({
                ...urlQuery,
                tags: (data.value as string[]).join(','),
                skip: 0,
              });
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>Tags ausschließen</label>
          <Select
            search
            multiple
            clearable
            options={tags.map((t) => ({
              text: t.name,
              value: t.id,
              description: (
                <span style={{ color: getTagColor(t) as string }}>
                  {t.context}
                </span>
              ),
            }))}
            value={urlQuery.excludeTags?.split(',')}
            onChange={(_, data) => {
              updateUrlQuery({
                ...urlQuery,
                excludeTags: (data.value as string[]).join(','),
                skip: 0,
              });
            }}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
