import { ResourcesQuery } from '@/features/resources/resources.generated';
import { useModal } from '@/features/ui/modal/modal.hook';
import { useCallback, useState } from 'react';
import Switch from 'react-switch';
import { Form, Input, Select } from 'semantic-ui-react';

export function useCreateIssueModal(
  projects: ResourcesQuery['projects'],
  onSubmit: (
    ticketId: string,
    projectId: string,
    billable: boolean,
    issueTitle: string
  ) => void
) {
  const [title, setTitle] = useState('');

  const [ticketId, setTicketId] = useState('');
  const [projectId, setProjectId] = useState('');
  const [issueTitle, setIssueTitle] = useState('');
  const [billable, setBillable] = useState(true);

  const [modal, { open, close }] = useModal({
    title,
    content: (
      <>
        <p>
          Please give some minimal Issue details!
          <br />
          <b>Hint:</b> try to match the title with the Jira Issue title.
        </p>
        <Form>
          <Form.Field>
            <label>Title / Description</label>

            <Input
              value={issueTitle}
              onChange={(_, data) => setIssueTitle(data.value)}
            />
          </Form.Field>

          <Form.Field>
            <label>Project</label>
            <Select
              defaultValue={projectId}
              search
              fluid
              value={projectId}
              onChange={(_, data) => {
                setProjectId(data.value ? (data.value as string) : '');
              }}
              multiple={false}
              clearable
              selectOnNavigation
              closeOnChange
              selectOnBlur={false}
              options={projects.map((p) => ({
                text: `${p.title} (${p.customer.companyName})`,
                value: p.id,
              }))}
            />
          </Form.Field>
          <Form.Field>
            <label>Billable</label>

            <Switch
              checked={billable}
              onChange={(c) => setBillable(c)}
              onColor="#21ba45"
            />
          </Form.Field>
        </Form>
      </>
    ),
    actions: [
      {
        label: 'Cancel',
      },
      {
        label: 'OK',
        primary: true,
        onClick: () => onSubmit(ticketId, projectId, billable, issueTitle),
      },
    ],
  });

  const ask = useCallback(
    (ticketId: string, title: string) => {
      setTitle(title);
      setTicketId(ticketId);
      open();
    },
    [open]
  );

  return {
    modal,
    ask,
  };
}
