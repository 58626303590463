import { menuItems } from '@/constants';
import { ComponentType, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Profile, ProfileStatus } from '../profile/profile.context';

export const AuthGuard: ComponentType = (props) => {
  const { children } = props;
  const status = Profile.useStatus();
  const profile = Profile.use();
  const navigate = useNavigate();
  const location = useLocation();

  const authorized = useMemo(() => {
    if (location.pathname === '/login') {
      return true;
    }
    return status === ProfileStatus.AVAILABLE;
  }, [status, location, profile]);

  useEffect(() => {
    if (!authorized) {
      navigate('/login', { replace: true });
    }
  }, [authorized]);

  if (!authorized) {
    return null;
  }

  if (!profile?.isAdmin) {
    const currentItem = menuItems.find((i) =>
      location.pathname.startsWith(i.href)
    );
    if (currentItem?.onlyAdmins) {
      return null;
    }
  }
  return <>{children}</>;
};
