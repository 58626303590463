import { useCallback, useEffect, useState } from 'react';

type TyrionProps = { relativeThresholdX: number; relativeThresholdY: number };
export function useTyrion(props: TyrionProps) {
  const { relativeThresholdX, relativeThresholdY } = props;
  const [transform, setTransform] = useState(10);

  const handleMouseMove = useCallback((e) => {
    const x = e.pageX / window.innerWidth;
    const y = e.pageY / window.innerHeight;
    const xTarget = 0.16;
    const yTarget = 0.96;

    if (x < relativeThresholdX && y > relativeThresholdY) {
      const deltaX = Math.max(0, Math.min(1, x - xTarget));
      const deltaY = Math.max(0, Math.min(1, yTarget - y));
      const deltaTotal =
        Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2)) * 10;
      setTransform(deltaTotal);
    } else {
      setTransform(10);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  return { transform };
}
