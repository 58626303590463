import { ComponentType } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Edit } from './edit/edit.component';
import { List } from './list/list.component';
import { PrefixMapping } from './mapping/mapping.component';

export const Issues: ComponentType = (props) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="/list" element={<List />} />
        <Route path="/create" element={<Edit />} />
        <Route path="/:id/edit" element={<Edit />} />
        <Route path="/mappings" element={<PrefixMapping />} />
      </Routes>
    </>
  );
};
