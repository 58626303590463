import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type YesterdaysJamQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.FilterTimelogsInput>;
}>;


export type YesterdaysJamQuery = { __typename?: 'Query', timelogs: { __typename?: 'PaginatedTimelogsResult', data: Array<{ __typename?: 'Timelog', duration?: number | null, user: { __typename?: 'User', id: any, firstName: string } }> } };


export const YesterdaysJamDocument = gql`
    query yesterdaysJam($filter: FilterTimelogsInput) {
  timelogs(pagination: {take: 99999, skip: 0}, filter: $filter) {
    data {
      duration
      user {
        id
        firstName
      }
    }
  }
}
    `;

/**
 * __useYesterdaysJamQuery__
 *
 * To run a query within a React component, call `useYesterdaysJamQuery` and pass it any options that fit your needs.
 * When your component renders, `useYesterdaysJamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYesterdaysJamQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useYesterdaysJamQuery(baseOptions?: Apollo.QueryHookOptions<YesterdaysJamQuery, YesterdaysJamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<YesterdaysJamQuery, YesterdaysJamQueryVariables>(YesterdaysJamDocument, options);
      }
export function useYesterdaysJamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<YesterdaysJamQuery, YesterdaysJamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<YesterdaysJamQuery, YesterdaysJamQueryVariables>(YesterdaysJamDocument, options);
        }
export function useYesterdaysJamSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<YesterdaysJamQuery, YesterdaysJamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<YesterdaysJamQuery, YesterdaysJamQueryVariables>(YesterdaysJamDocument, options);
        }
export type YesterdaysJamQueryHookResult = ReturnType<typeof useYesterdaysJamQuery>;
export type YesterdaysJamLazyQueryHookResult = ReturnType<typeof useYesterdaysJamLazyQuery>;
export type YesterdaysJamSuspenseQueryHookResult = ReturnType<typeof useYesterdaysJamSuspenseQuery>;
export type YesterdaysJamQueryResult = Apollo.QueryResult<YesterdaysJamQuery, YesterdaysJamQueryVariables>;