import { ComponentType } from 'react';
import { Pagination as ReactPagination } from 'semantic-ui-react';

interface Props {
  currentPage: number;
  numPages: number;
  setCurrentPage: (page: number) => void;
  totalCount: number;
}

export const Pagination: ComponentType<Props> = (props) => {
  const { currentPage, numPages, setCurrentPage, totalCount } = props;
  return (
    <>
      <ReactPagination
        totalPages={numPages}
        activePage={currentPage + 1}
        onPageChange={(e, data) =>
          setCurrentPage(data.activePage ? (data.activePage as number) - 1 : 0)
        }
      />
      <div style={{ fontSize: '0.8em' }}>Total: {totalCount}</div>
    </>
  );
};
