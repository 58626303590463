import { useResources } from '@/features/resources/resources.provider';
import { Frame } from '@/features/ui/frame/frame';
import { ComponentType } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Dimmer, Loader, Table, TableRow } from 'semantic-ui-react';

export const List: ComponentType = () => {
  let navigate = useNavigate();
  const { tags, loading } = useResources();

  return (
    <>
      <Frame.TitleBar>
        <Frame.Title>Tags</Frame.Title>
        <Frame.Actions>
          <Button basic color="black" onClick={() => navigate('/tags/create')}>
            Create Tag
          </Button>
        </Frame.Actions>
      </Frame.TitleBar>
      <Frame.Content>
        {loading && (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}
        {!loading && (
          <>
            {tags.length > 0 ? (
              <Table basic="very" size="small">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Context</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {tags.map(({ id, name, context }) => (
                    <TableRow key={id}>
                      <Table.Cell>{name}</Table.Cell>
                      <Table.Cell>{context}</Table.Cell>

                      <Table.Cell>
                        <Link to={`/tags/${id}/edit`}>
                          <Button basic>Details</Button>
                        </Link>
                      </Table.Cell>
                    </TableRow>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <h2>
                Oops, it seems like you have no projects recorded in the
                system...
              </h2>
            )}
          </>
        )}
      </Frame.Content>
    </>
  );
};
