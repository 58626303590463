import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectListDataFragment = { __typename?: 'Project', id: any, projectNumber?: number | null, title: string, slugs?: string | null, timeSpent: number, amountPerHour?: number | null, status: Types.ProjectStatus, customer: { __typename?: 'Customer', id: any, firstName?: string | null, lastName?: string | null, companyName?: string | null, contactEmail: string, slugs: string, amountPerHour?: number | null } };

export type ProjectsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.SearchProjectsInput>;
}>;


export type ProjectsQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', id: any, projectNumber?: number | null, title: string, slugs?: string | null, timeSpent: number, amountPerHour?: number | null, status: Types.ProjectStatus, customer: { __typename?: 'Customer', id: any, firstName?: string | null, lastName?: string | null, companyName?: string | null, contactEmail: string, slugs: string, amountPerHour?: number | null } }> };

export const ProjectListDataFragmentDoc = gql`
    fragment ProjectListData on Project {
  id
  projectNumber
  title
  slugs
  timeSpent
  amountPerHour
  status
  customer {
    id
    firstName
    lastName
    companyName
    contactEmail
    slugs
    amountPerHour
  }
}
    `;
export const ProjectsDocument = gql`
    query projects($filter: SearchProjectsInput) {
  projects(filter: $filter) {
    ...ProjectListData
  }
}
    ${ProjectListDataFragmentDoc}`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export function useProjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsSuspenseQueryHookResult = ReturnType<typeof useProjectsSuspenseQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;