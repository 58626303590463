import { useCreateIssueMutation } from '@/features/issues/edit/edit.generated';
import { useIssuesLazyQuery } from '@/features/issues/list/list.generated';
import { useResources } from '@/features/resources/resources.provider';
import { CheckboxField } from '@/features/ui/checkbox-field/checkbox-field.component';
import { DateField } from '@/features/ui/form/date-field.component';
import { FinderField } from '@/features/ui/form/finder-field/finder-field.component';
import { InputField } from '@/features/ui/form/input-field.component';
import { SelectField } from '@/features/ui/form/select-field.component';
import { ToggleField } from '@/features/ui/form/toggle-field.component';
import { Frame } from '@/features/ui/frame/frame';
import { NewFeature } from '@/features/ui/new-feature/new-feature.component';
import { yupResolver } from '@hookform/resolvers/yup';
import { ComponentType, useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'semantic-ui-react';
import * as styles from '../../../styles/Edit.module.scss';
import { useCreateIssueModal } from '../edit/create-issue.modal';
import { useBulkUpdateTimelogsMutation } from './edit.generated';
import schema from './schema';

const sanitizeInput = (input: Record<string, any>) => {
  return Object.entries(input).reduce((prev, curr) => {
    return { ...prev, [curr[0]]: curr[1] === undefined ? null : curr[1] };
  }, {});
};

export const Edit: ComponentType = () => {
  const navigate = useNavigate();
  const selection = JSON.parse(localStorage.getItem('timelogIds') || '[]');
  const [touchUser, setTouchUser] = useState(false);
  const [touchDate, setTouchDate] = useState(false);
  const [touchIssue, setTouchIssue] = useState(false);
  const [touchDescription, setTouchDescription] = useState(false);
  const [touchDuration, setTouchDuration] = useState(false);
  const [touchAmountPerHour, setTouchAmountPerHour] = useState(false);
  const [touchTags, setTouchTags] = useState(false);
  const [touchFlagged, setTouchFlagged] = useState(false);
  const [createIssue] = useCreateIssueMutation();

  const { users, tags, projects } = useResources();

  const [loadIssues, { data: issueData, loading: issuesLoading }] =
    useIssuesLazyQuery();

  const { control, handleSubmit, formState, setValue } = useForm<any>({
    resolver: yupResolver(schema),
    shouldUnregister: true,
    defaultValues: {
      flagged: false,
    },
  });

  const [bulkUpdate] = useBulkUpdateTimelogsMutation();

  const onSubmit = useMemo(
    () =>
      handleSubmit(
        async (input) => {
          const sanitizedInput = sanitizeInput(input);
          if (selection.length > 0) {
            try {
              await bulkUpdate({
                variables: { input: sanitizedInput, ids: selection },
              });
              localStorage.removeItem('timelogIds');
              toast.success(`${selection.length} Timelogs updated.`);
              navigate('/timelogs');
            } catch (error: any) {
              toast.error(error.message);
            }
          } else {
            toast.error('No timelogs selected.');
          }
        },
        (error) => {
          toast.error(error);
        },
      ),
    [selection],
  );

  const onCreateIssue = useCallback(
    async (ticketId: string, projectId: string, billable, title) => {
      try {
        const createdIssue = await createIssue({
          variables: { input: { ticketId, projectId, billable, title } },
        });

        if (createdIssue.data) {
          await loadIssues({
            variables: {
              pagination: { skip: 0, take: 99999 },
              filter: { query: ticketId as string },
            },
          });
          setValue('issueId', createdIssue.data.createIssue.id, {
            shouldDirty: true,
            shouldValidate: true,
            shouldTouch: true,
          });
          toast.success(`Issue ${ticketId} created.`);
        }
      } catch (e) {
        toast.error('Error creating issue.');
      }
    },
    [setValue],
  );

  const { modal, ask } = useCreateIssueModal(projects, onCreateIssue);

  const itemText = selection.length === 1 ? 'item' : 'items';

  return (
    <>
      {modal}
      <Frame.TitleBar>
        <Frame.Title>{`Bulk Edit Timelogs (${selection.length} ${itemText})`}</Frame.Title>
        <Frame.Actions>
          <Button basic color="red" onClick={() => navigate('/timelogs')}>
            Cancel
          </Button>
        </Frame.Actions>
      </Frame.TitleBar>
      <Frame.Content>
        <Form className={styles.form} error={!formState.isValid}>
          <CheckboxField
            checked={touchUser}
            onChange={(e, data) => {
              setTouchUser(data.checked || false);
            }}
            label="User"
            field={
              <Controller
                name="userId"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectField
                    searchable
                    setValue={setValue}
                    options={users.map((u) => ({ text: u.label, value: u.id }))}
                    label="User"
                    field={field}
                    fieldState={fieldState}
                    fluid
                  />
                )}
              />
            }
          />

          <CheckboxField
            checked={touchDate}
            onChange={(e, data) => {
              setTouchDate(data.checked || false);
            }}
            label="Date"
            field={
              <Controller
                name="date"
                control={control}
                render={({ field, fieldState }) => (
                  <DateField
                    label="Date"
                    field={field}
                    fieldState={fieldState}
                    setValue={setValue}
                  />
                )}
              />
            }
          />

          <CheckboxField
            checked={touchIssue}
            onChange={(e, data) => {
              setTouchIssue(data.checked || false);
            }}
            label="Issue"
            field={
              <Controller
                name="issueId"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <div className="pos-relative">
                      <FinderField
                        field={field}
                        fieldState={fieldState}
                        label="Issue"
                        options={
                          issueData?.issues.data.map((i) => ({
                            text: i.label,
                            value: i.id,
                          })) || []
                        }
                        setValue={setValue}
                        onAdd={(value) => ask(value, `Create Issue ${value}?`)}
                        onSearchChange={(value) => {
                          loadIssues({
                            variables: {
                              pagination: { skip: 0, take: 99999 },
                              filter: { query: value },
                            },
                          });
                        }}
                        loading={issuesLoading}
                      />
                      <NewFeature />
                    </div>
                  </>
                )}
              />
            }
          />
          <CheckboxField
            checked={touchTags}
            onChange={(e, data) => {
              setTouchTags(data.checked || false);
            }}
            label="Tags"
            field={
              <Controller
                name="tagIds"
                defaultValue={[]}
                control={control}
                render={({ field, fieldState }) => (
                  <SelectField
                    searchable
                    multiple
                    setValue={setValue}
                    options={tags.map((t) => ({
                      text: t.name,
                      value: t.id,
                    }))}
                    label="Tags"
                    field={field}
                    fieldState={fieldState}
                  />
                )}
              />
            }
          />

          <CheckboxField
            checked={touchDescription}
            onChange={(e, data) => {
              setTouchDescription(data.checked || false);
            }}
            label="Description"
            field={
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <InputField
                    label="Description"
                    field={field}
                    fieldState={fieldState}
                  />
                )}
              />
            }
          />

          <CheckboxField
            checked={touchDuration}
            onChange={(e, data) => {
              setTouchDuration(data.checked || false);
            }}
            label="Duration (minutes)"
            field={
              <Controller
                name="duration"
                control={control}
                render={({ field, fieldState }) => (
                  <InputField
                    label="Duration (minutes)"
                    field={field}
                    fieldState={fieldState}
                  />
                )}
              />
            }
          />

          <CheckboxField
            checked={touchAmountPerHour}
            onChange={(e, data) => {
              setTouchAmountPerHour(data.checked || false);
            }}
            label="Amount per hour"
            field={
              <Controller
                name="amountPerHour"
                control={control}
                render={({ field, fieldState }) => (
                  <InputField
                    onClear={() => setValue('amountPerHour', null)}
                    label="Amount per hour"
                    field={field}
                    fieldState={fieldState}
                  />
                )}
              />
            }
          />
          <CheckboxField
            checked={touchFlagged}
            onChange={(e, data) => {
              setTouchFlagged(data.checked || false);
            }}
            label="Flagged"
            field={
              <Controller
                name="flagged"
                control={control}
                render={({ field, fieldState }) => (
                  <ToggleField
                    label="Flagged"
                    field={field}
                    fieldState={fieldState}
                  />
                )}
              />
            }
          />

          <Button
            color="green"
            style={{ marginTop: '1.2rem' }}
            onClick={onSubmit}
          >
            Save
          </Button>
        </Form>
      </Frame.Content>
    </>
  );
};
