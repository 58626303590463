import { useResources } from '@/features/resources/resources.provider';
import { DateField } from '@/features/ui/form/date-field.component';
import { InputField } from '@/features/ui/form/input-field.component';
import { SelectField } from '@/features/ui/form/select-field.component';
import { Frame } from '@/features/ui/frame/frame';
import { useConfirm } from '@/features/ui/modal/modal.hook';
import { CreateServiceInput } from '@/graphql/generated/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { ComponentType, useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'semantic-ui-react';
import * as styles from '../../../../styles/Edit.module.scss';
import {
  useCreateServiceMutation,
  useServiceQuery,
  useUpdateServiceMutation,
} from './edit.generated';
import schema from './schema';

export const Edit: ComponentType = () => {
  const navigate = useNavigate();
  const { serviceId, customerId } = useParams();

  const { loading, error, data } = useServiceQuery({
    variables: { id: serviceId },
    skip: serviceId === undefined,
    fetchPolicy: 'network-only',
  });
  const service = data?.service;

  const { products, customers } = useResources();

  const customer = useMemo(
    () => customers.find((c) => c.id === customerId),
    [customerId, customers],
  );

  const { control, handleSubmit, reset, formState, setValue } =
    useForm<CreateServiceInput>({
      resolver: yupResolver(schema),
      shouldUnregister: true,
    });

  const { refresh } = useResources();

  const [modal, { ask }] = useConfirm((id: any) => {
    alert('Delete function to implement');
  });

  const [create] = useCreateServiceMutation();
  const [update] = useUpdateServiceMutation();

  useEffect(() => {
    if (service) {
      reset({
        productId: service.productId,
        invoicePeriod: service.invoicePeriod,
        startDate: service.startDate,
        endDate: service.endDate,
      });
    }
  }, [service]);

  const onSubmit: SubmitHandler<CreateServiceInput> = async (input) => {
    if (serviceId) {
      try {
        await update({
          variables: { id: serviceId, input: { ...input, customerId } },
        });
        await refresh();
        toast.success('Service successfully updated!');
      } catch (err) {
        toast.error('An error occurred :(  ' + err);
      }
    } else {
      try {
        const result = await create({
          variables: { input: { ...input, customerId } },
        });
        if (result.data) {
          navigate(
            `/customers/${result.data.createService.customer.id}/services/${result.data.createService.id}/edit`,
          );
          await refresh();
          toast.success('New service successfully saved!');
        }
      } catch (err) {
        toast.error('An error occurred :(  ' + err);
      }
    }
  };

  const onError = (error) => {
    console.log(error);
  };

  return (
    <>
      <Frame.TitleBar>
        <Frame.Title>
          {serviceId ? 'Edit Service' : 'Create Service'} for{' '}
          {customer?.companyName}
        </Frame.Title>
        <Frame.Actions>
          <Button basic color="red" onClick={() => navigate('/products')}>
            Cancel
          </Button>
        </Frame.Actions>
      </Frame.TitleBar>
      <Frame.Content>
        <Form
          className={styles.form}
          onSubmit={handleSubmit(onSubmit, onError)}
          error={!formState.isValid}
        >
          <Controller
            name="productId"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <SelectField
                  options={products?.map(({ name, id }) => ({
                    text: name,
                    value: id,
                  }))}
                  setValue={setValue}
                  label="Product"
                  field={field}
                  fieldState={fieldState}
                />
              </>
            )}
          />

          <Controller
            name="startDate"
            control={control}
            render={({ field, fieldState }) => (
              <DateField
                label="Start date"
                field={field}
                fieldState={fieldState}
                setValue={setValue}
              />
            )}
          />
          <Controller
            name="endDate"
            control={control}
            render={({ field, fieldState }) => (
              <DateField
                label="End date"
                field={field}
                fieldState={fieldState}
                setValue={setValue}
              />
            )}
          />

          <Controller
            name="invoicePeriod"
            control={control}
            render={({ field, fieldState }) => (
              <InputField
                label="Invoice Period"
                field={field}
                fieldState={fieldState}
              />
            )}
          />

          <Button.Group>
            <Button type="submit" color="green">
              Save
            </Button>
            {/* {serviceId && (
              <Button
                type="button"
                color="red"
                basic
                onClick={() =>
                  ask(`Are you sure you want to delete this service?`)
                }
              >
                Delete
              </Button>
            )} */}
          </Button.Group>
          {modal}
        </Form>
      </Frame.Content>
    </>
  );
};
