import { ComponentType } from 'react';
import * as styles from './changelog.module.scss';
import { Change } from './changes';

interface ChangeLogProps {
  changes: Change[];
}

export const Changelog: ComponentType<ChangeLogProps> = (props) => {
  const { changes } = props;
  return (
    <ul className={styles.host}>
      {changes.map((c) => (
        <li key={c.version} className={styles.change}>
          <div className={styles.version}>{c.version}</div>
          <div className={styles.date}>{c.date}</div>
          <div className={styles.changes}>{c.changes}</div>
        </li>
      ))}
    </ul>
  );
};
