import { Project } from '@/graphql/generated/types';
import * as globalStyles from '@/styles/globals.scss';
import { ComponentType } from 'react';

interface ProjectLabelProps {
  project?: Pick<Project, 'projectNumber' | 'title'>;
}

export const ProjectLabel: ComponentType<ProjectLabelProps> = (props) => {
  const { project } = props;
  if (!project) {
    return <></>;
  }
  return (
    <>
      <span className={globalStyles.highlight}>{project.projectNumber}</span>{' '}
      {project.title}
    </>
  );
};
