// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fGI_vUS0RECxXl5_vXi5{color:red}.cuLKSpfGRn78WMUdHjsV{margin-bottom:2rem;margin-top:1rem}.viyIxINg68fRQXDzPTNf{margin-top:3.5rem}.AOqNX_IPTxNNyJ8f28xb{color:#d13636}.shtomLtoq4NCwBpUBO_Y{display:flex;flex-direction:column;align-items:flex-start}.cfUQrEKgm01v31QEUzFK{font-weight:700}.CZjsUkf49nsikdm_Prhg{font-style:italic;font-size:.9rem}.Yk3eLupGnV1Ad341p66Z .p6g7tVPbnkH3UCmYIVS9{border-bottom:3px solid red !important}.Yk3eLupGnV1Ad341p66Z .p6g7tVPbnkH3UCmYIVS9 .SXIEzUCn_qsFPSbA9CH4{color:#aca9a9;background-color:#f3f3f3}.s4fKTMC9sYM4PJ4hjSyp{left:initial !important}.FleGc4UFxhm0hWZIcpYI{display:flex;flex-direction:column;justify-content:space-between;width:6rem;height:6rem}`, "",{"version":3,"sources":["webpack://./src/styles/Edit.module.scss"],"names":[],"mappings":"AAAA,sBACE,SAAA,CAGF,sBACE,kBAAA,CACA,eAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,aAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CAIF,sBACE,eAAA,CAGF,sBACE,iBAAA,CACA,eAAA,CAKA,4CACE,sCAAA,CAGA,kEACE,aAAA,CACA,wBAAA,CAKN,sBACE,uBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".h1 {\n  color: red;\n}\n\n.dropdown {\n  margin-bottom: 2rem;\n  margin-top: 1rem;\n}\n\n.discountBlock {\n  margin-top: 3.5rem;\n}\n\n.error {\n  color: rgb(209, 54, 54);\n}\n\n.flex_column {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  /* margin-left: 3rem; */\n}\n\n.bold {\n  font-weight: 700;\n}\n\n.italic {\n  font-style: italic;\n  font-size: 0.9rem;\n}\n\n.table {\n  // border-collapse: collapse;\n  .body {\n    border-bottom: 3px solid red !important;\n    .row {\n    }\n    .rowOriginal {\n      color: #aca9a9;\n      background-color: #f3f3f3;\n    }\n  }\n}\n\n.modal {\n  left: initial !important;\n}\n\n.buttonGroup {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 6rem;\n  height: 6rem;\n}\n"],"sourceRoot":""}]);
// Exports
export var h1 = `fGI_vUS0RECxXl5_vXi5`;
export var dropdown = `cuLKSpfGRn78WMUdHjsV`;
export var discountBlock = `viyIxINg68fRQXDzPTNf`;
export var error = `AOqNX_IPTxNNyJ8f28xb`;
export var flex_column = `shtomLtoq4NCwBpUBO_Y`;
export var bold = `cfUQrEKgm01v31QEUzFK`;
export var italic = `CZjsUkf49nsikdm_Prhg`;
export var table = `Yk3eLupGnV1Ad341p66Z`;
export var body = `p6g7tVPbnkH3UCmYIVS9`;
export var rowOriginal = `SXIEzUCn_qsFPSbA9CH4`;
export var modal = `s4fKTMC9sYM4PJ4hjSyp`;
export var buttonGroup = `FleGc4UFxhm0hWZIcpYI`;
export default ___CSS_LOADER_EXPORT___;
