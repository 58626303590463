import { Issue } from '@/graphql/generated/types';
import { format, parseISO } from 'date-fns';

export function formatDate(
  value?: string | Date | null,
  withTime?: boolean,
  formatString?: string
) {
  if (!value) {
    return '';
  }
  if (formatString) {
    if (value instanceof Date) {
      return format(value, formatString);
    }
    return format(parseISO(value), formatString);
  }

  if (value instanceof Date) {
    return format(value, withTime ? 'dd.MM.yyyy | HH:mm' : 'dd.MM.yyyy');
  }
  return format(
    parseISO(value),
    withTime ? 'dd.MM.yyyy | HH:mm' : 'dd.MM.yyyy'
  );
}

export function formatMoney(value: number, precision = 2) {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    minimumFractionDigits: precision,
    currency: 'EUR',
  }).format(value);
}

export function secondsToTime(
  value: number | null | undefined,
  withSeconds?: boolean
) {
  if (value === undefined || value === null) {
    return 'unbekannt';
  }
  let hours: string | number = Math.floor(value / 3600);
  let minutes: string | number = Math.floor((value - hours * 3600) / 60);
  let seconds: string | number = value - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  if (withSeconds) {
    return `${hours}:${minutes}:${seconds}`;
  }
  return `${hours}:${minutes}`;
}

export function getIssueLabel(issue?: Pick<Issue, 'title' | 'ticketId'>) {
  if (!issue) {
    return 'No issue';
  }

  if (issue.title) {
    return `${issue.ticketId} (${issue.title})`;
  }
  return issue.ticketId;
}
