// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f_TmJSdUMhzA2L42wTv4{list-style:none;margin:0;padding:0}.f_TmJSdUMhzA2L42wTv4 .a6MtGkH6pyUbq0GgaYm0{margin:1rem 0;padding:0}.f_TmJSdUMhzA2L42wTv4 .a6MtGkH6pyUbq0GgaYm0 .WqrnIB5bDjhtHMuxv5L9{opacity:.7}.f_TmJSdUMhzA2L42wTv4 .a6MtGkH6pyUbq0GgaYm0 .mGNSSjyvzrsWblsYTTcg{font-size:1.5rem}.f_TmJSdUMhzA2L42wTv4 .a6MtGkH6pyUbq0GgaYm0 .aYS032FrsUElJApsfdtH{white-space:pre}`, "",{"version":3,"sources":["webpack://./src/features/dashboard/changelog/changelog.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,QAAA,CACA,SAAA,CAEA,4CACE,aAAA,CACA,SAAA,CACA,kEACE,UAAA,CAEF,kEACE,gBAAA,CAEF,kEACE,eAAA","sourcesContent":[".host {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n\n  .change {\n    margin: 1rem 0;\n    padding: 0;\n    .date {\n      opacity: 0.7;\n    }\n    .version {\n      font-size: 1.5rem;\n    }\n    .changes {\n      white-space: pre;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var host = `f_TmJSdUMhzA2L42wTv4`;
export var change = `a6MtGkH6pyUbq0GgaYm0`;
export var date = `WqrnIB5bDjhtHMuxv5L9`;
export var version = `mGNSSjyvzrsWblsYTTcg`;
export var changes = `aYS032FrsUElJApsfdtH`;
export default ___CSS_LOADER_EXPORT___;
