import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkUpdateTimelogsMutationVariables = Types.Exact<{
  input: Types.TimelogBulkUpdateInput;
  ids: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input'];
}>;


export type BulkUpdateTimelogsMutation = { __typename?: 'Mutation', bulkUpdateTimelogs: boolean };


export const BulkUpdateTimelogsDocument = gql`
    mutation bulkUpdateTimelogs($input: TimelogBulkUpdateInput!, $ids: [UUID!]!) {
  bulkUpdateTimelogs(input: $input, ids: $ids)
}
    `;
export type BulkUpdateTimelogsMutationFn = Apollo.MutationFunction<BulkUpdateTimelogsMutation, BulkUpdateTimelogsMutationVariables>;

/**
 * __useBulkUpdateTimelogsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateTimelogsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateTimelogsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateTimelogsMutation, { data, loading, error }] = useBulkUpdateTimelogsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBulkUpdateTimelogsMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateTimelogsMutation, BulkUpdateTimelogsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateTimelogsMutation, BulkUpdateTimelogsMutationVariables>(BulkUpdateTimelogsDocument, options);
      }
export type BulkUpdateTimelogsMutationHookResult = ReturnType<typeof useBulkUpdateTimelogsMutation>;
export type BulkUpdateTimelogsMutationResult = Apollo.MutationResult<BulkUpdateTimelogsMutation>;
export type BulkUpdateTimelogsMutationOptions = Apollo.BaseMutationOptions<BulkUpdateTimelogsMutation, BulkUpdateTimelogsMutationVariables>;