import { ResourcesQuery } from '@/features/resources/resources.generated';
import { ModalHook, useModal } from '@/features/ui/modal/modal.hook';
import { useState } from 'react';
import { Select } from 'semantic-ui-react';

export function useAddProjectModal(
  projects: ResourcesQuery['projects'],
  onSubmit: (id: string | undefined) => void
): ModalHook {
  const [projectToAdd, setProjectToAdd] = useState<string>();
  const [modal, { open }] = useModal({
    title: 'Add Project to Moggle Board',
    content: (
      <>
        <p>Select a moggle to toggle!</p>
        <Select
          search
          multiple={false}
          options={projects.map((p) => ({ text: p.title, value: p.id }))}
          value={projectToAdd}
          onChange={(_, data) => {
            setProjectToAdd(data.value as string);
          }}
        />
      </>
    ),
    actions: [
      {
        label: 'No, I changed my mind.',
      },

      {
        label: 'Go!',
        primary: true,
        onClick: () => onSubmit(projectToAdd),
      },
    ],
  });

  return [modal, { open }];
}
