import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IssueListDataFragment = { __typename?: 'Issue', id: any, ticketId: string, billable: boolean, estimation?: number | null, fixedAmount?: number | null, title?: string | null, label: string, project?: { __typename?: 'Project', id: any, title: string, customer: { __typename?: 'Customer', id: any, label: string } } | null };

export type IssuesQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
  filter?: Types.InputMaybe<Types.SearchIssuesInput>;
}>;


export type IssuesQuery = { __typename?: 'Query', issues: { __typename?: 'PaginatedIssuesResult', totalCount: number, data: Array<{ __typename?: 'Issue', id: any, ticketId: string, billable: boolean, estimation?: number | null, fixedAmount?: number | null, title?: string | null, label: string, project?: { __typename?: 'Project', id: any, title: string, customer: { __typename?: 'Customer', id: any, label: string } } | null }> } };

export type SyncIssuesWithJiraMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SyncIssuesWithJiraMutation = { __typename?: 'Mutation', syncIssuesWithJira: { __typename?: 'SyncResult', result?: string | null, error?: string | null } };

export const IssueListDataFragmentDoc = gql`
    fragment IssueListData on Issue {
  id
  ticketId
  billable
  estimation
  fixedAmount
  title
  label
  project {
    id
    title
    customer {
      id
      label
    }
  }
}
    `;
export const IssuesDocument = gql`
    query issues($pagination: PaginationInput!, $filter: SearchIssuesInput) {
  issues(pagination: $pagination, filter: $filter) {
    totalCount
    data {
      ...IssueListData
    }
  }
}
    ${IssueListDataFragmentDoc}`;

/**
 * __useIssuesQuery__
 *
 * To run a query within a React component, call `useIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssuesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIssuesQuery(baseOptions: Apollo.QueryHookOptions<IssuesQuery, IssuesQueryVariables> & ({ variables: IssuesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IssuesQuery, IssuesQueryVariables>(IssuesDocument, options);
      }
export function useIssuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IssuesQuery, IssuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IssuesQuery, IssuesQueryVariables>(IssuesDocument, options);
        }
export function useIssuesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IssuesQuery, IssuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IssuesQuery, IssuesQueryVariables>(IssuesDocument, options);
        }
export type IssuesQueryHookResult = ReturnType<typeof useIssuesQuery>;
export type IssuesLazyQueryHookResult = ReturnType<typeof useIssuesLazyQuery>;
export type IssuesSuspenseQueryHookResult = ReturnType<typeof useIssuesSuspenseQuery>;
export type IssuesQueryResult = Apollo.QueryResult<IssuesQuery, IssuesQueryVariables>;
export const SyncIssuesWithJiraDocument = gql`
    mutation syncIssuesWithJira {
  syncIssuesWithJira {
    result
    error
  }
}
    `;
export type SyncIssuesWithJiraMutationFn = Apollo.MutationFunction<SyncIssuesWithJiraMutation, SyncIssuesWithJiraMutationVariables>;

/**
 * __useSyncIssuesWithJiraMutation__
 *
 * To run a mutation, you first call `useSyncIssuesWithJiraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncIssuesWithJiraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncIssuesWithJiraMutation, { data, loading, error }] = useSyncIssuesWithJiraMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncIssuesWithJiraMutation(baseOptions?: Apollo.MutationHookOptions<SyncIssuesWithJiraMutation, SyncIssuesWithJiraMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncIssuesWithJiraMutation, SyncIssuesWithJiraMutationVariables>(SyncIssuesWithJiraDocument, options);
      }
export type SyncIssuesWithJiraMutationHookResult = ReturnType<typeof useSyncIssuesWithJiraMutation>;
export type SyncIssuesWithJiraMutationResult = Apollo.MutationResult<SyncIssuesWithJiraMutation>;
export type SyncIssuesWithJiraMutationOptions = Apollo.BaseMutationOptions<SyncIssuesWithJiraMutation, SyncIssuesWithJiraMutationVariables>;