import { ComponentType } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Edit } from './edit/edit.component';
import { List } from './list/list.component';
import { Edit as ServiceEdit } from './services/edit/edit.component';
import { Services } from './services/list/services.component';

export const Customers: ComponentType = (props) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="/list" element={<List />} />
        <Route path="/create" element={<Edit />} />
        <Route path="/:customerId/edit" element={<Edit />} />
        <Route path="/:customerId/services" element={<Services />} />
        <Route path="/:customerId/services/create" element={<ServiceEdit />} />
        <Route
          path="/:customerId/services/:serviceId/edit"
          element={<ServiceEdit />}
        />
      </Routes>
    </>
  );
};
