export type Change = {
  date: string;
  version: string;
  changes: string;
};

export const changes: Change[] = [
  {
    date: '2022-07-18',
    version: '1.3.0',
    changes: `- New products, services and invoices
- invoices will be generated automatically every midnight, based on the invoice period`,
  },
  {
    date: '2022-03-07',
    version: '1.2.1',
    changes: `- Fixed timelog query on dates
- Fixed order of timelogs in list
- more userfriendly Selectboxes/Dropdowns`,
  },
  {
    date: '2022-02-24',
    version: '1.2.0',
    changes: `- Employee of the *yester*day`,
  },
  {
    date: '2022-02-24',
    version: '1.1.0',
    changes: `- Toggle Moggle ;-)`,
  },
  {
    date: '2022-02-23',
    version: '1.0.1',
    changes: `- user defaults to logged in user when creating timelog
- date defaults to "today" when creating timelog
- searchable dropdowns for customer, project, user selection
- Dashboard showing this list of changes`,
  },
];
