import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Profile } from '../profile/profile.context';

export function useAuth(redirectTo: string) {
  const profile = Profile.use();
  const navigate = useNavigate();
  useEffect(() => {
    if (profile && !profile.isAdmin) {
      navigate(redirectTo);
    }
  }, [profile]);
}
