import { Issue } from '@/graphql/generated/types';
import * as globalStyles from '@/styles/globals.scss';
import { ComponentType } from 'react';

interface IssueLabelprops {
  issue?: Pick<Issue, 'ticketId' | 'title'>;
}

export const IssueLabel: ComponentType<IssueLabelprops> = (props) => {
  const { issue } = props;
  if (!issue) {
    return <></>;
  }
  return (
    <>
      <span className={globalStyles.highlight}>{issue.ticketId}</span>{' '}
      {issue.title}
    </>
  );
};
