import { array, number, object, string } from 'yup';

export default object().shape({
  userId: string().required(),
  date: string().required(),
  description: string().nullable(),
  internalNotes: string().nullable(),
  duration: string()
    .matches(
      /^(?:(\d+)w\s*)?(?:(\d+)d\s*)?(?:(\d+)h\s*)?(?:(\d+)m\s*)?$/,
      'Invalid time format. Valid examples: 30m, 1h 30m, etc..'
    )
    .required(),
  amountPerHour: number().nullable(),
  tagIds: array().of(string()).nullable(),
  issueId: string().required(),
});
