import { Filter } from '@/features/projects/list/filter.component';
import { Formatted } from '@/features/ui/formatted/formatted.component';
import { Frame } from '@/features/ui/frame/frame';
import { useUrlQuery } from '@/features/utils/url-query';
import * as globalStyles from '@/styles/globals.scss';
import classNames from 'classnames';
import { ComponentType } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Table, TableRow } from 'semantic-ui-react';
import { CustomerLabel } from '../customer-label.component';
import { FilterState } from './filter.component';
import { useCustomersQuery } from './list.generated';
export const List: ComponentType = () => {
  let navigate = useNavigate();
  const [urlQuery] = useUrlQuery<FilterState>();
  const { data, loading } = useCustomersQuery({
    variables: { filter: { query: urlQuery.query } },
  });

  const customers = data?.customers || [];

  return (
    <>
      <Frame.TitleBar>
        <Frame.Title>Customers</Frame.Title>
        <Frame.Actions>
          <Button
            basic
            color="black"
            onClick={() => navigate('/customers/create')}
          >
            Create Customer
          </Button>
        </Frame.Actions>
      </Frame.TitleBar>
      <Frame.Content loading={loading}>
        <Filter />
        {customers.length > 0 ? (
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Customer</Table.HeaderCell>
                <Table.HeaderCell>Kontakt</Table.HeaderCell>
                <Table.HeaderCell>Time spent</Table.HeaderCell>
                <Table.HeaderCell>Amount per hour</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {customers.map((customer) => (
                <TableRow key={customer.id}>
                  <Table.Cell>
                    <CustomerLabel customer={customer} />
                    <div className={classNames(globalStyles.secondary)}>
                      {customer.slugs}
                    </div>
                  </Table.Cell>

                  <Table.Cell>
                    {customer.contactEmail}
                    {customer.phone && (
                      <>
                        <br />
                        {customer.phone}
                      </>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Formatted.Duration value={customer.timeSpent} unit="h" />
                  </Table.Cell>
                  <Table.Cell>
                    <Formatted.Currency value={customer.amountPerHour || 0} />
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={`/customers/${customer.id}/edit`}>
                      <Button basic>Details</Button>
                    </Link>
                    <Link to={`/customers/${customer.id}/services`}>
                      <Button basic>Services</Button>
                    </Link>
                  </Table.Cell>
                </TableRow>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <h2>
            Oops, it seems like you have no customers recorded in the system...
          </h2>
        )}
      </Frame.Content>
    </>
  );
};
