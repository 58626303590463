import { ProjectToggleEntry } from '@/graphql/generated/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useUpdateProjectTogglesMutation } from '../toggle-moggle.generated';
export function useProjectList() {
  const [projects, setProjects] = useState<ProjectToggleEntry[]>([]);
  const initialRef = useRef(true);

  const [save] = useUpdateProjectTogglesMutation();
  const add = useCallback((newProject: string, width: number) => {
    initialRef.current = false;
    setProjects((p) => [
      ...p.filter(({ id }) => id !== newProject),
      { id: newProject, width },
    ]);
  }, []);

  useEffect(() => {
    if (!initialRef.current) {
      save({ variables: { input: { projectToggles: projects } } });
    }
  }, [projects]);

  return {
    add,
    projects,
    setProjects,
  };
}
