import { ReactNode, useEffect, useRef } from 'react';

function useOutsideAlerter(ref: any, onOutsideClick: () => void) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export default function OutsideAlerter(props: {
  onOutsideClick: () => void;
  children: ReactNode;
  className?: string;
}) {
  const { children, className } = props;
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.onOutsideClick);

  return (
    <div ref={wrapperRef} className={className}>
      {props.children}
    </div>
  );
}
