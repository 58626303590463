import { number, object, string } from 'yup';

export default object({
  customerNumber: number().integer().required(),
  firstName: string().nullable(),
  lastName: string().nullable(),
  companyName: string().nullable(),
  slugs: string().required(),
  contactEmail: string().email().required(),
  amountPerHour: number().nullable(),
  street: string().nullable(),
  phone: string().nullable(),
  postalCode: string().nullable(),
  city: string().nullable(),
  country: string().nullable(),
});
