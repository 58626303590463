import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TagQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type TagQuery = { __typename?: 'Query', tag: { __typename?: 'Tag', id: any, name: string, context: string } };

export type SaveTagMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['UUID']['input']>;
  input: Types.TagInput;
}>;


export type SaveTagMutation = { __typename?: 'Mutation', saveTag: { __typename?: 'Tag', id: any, name: string, context: string } };

export type DeleteTagMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type DeleteTagMutation = { __typename?: 'Mutation', deleteTag: boolean };


export const TagDocument = gql`
    query tag($id: UUID!) {
  tag(id: $id) {
    id
    name
    context
  }
}
    `;

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagQuery(baseOptions: Apollo.QueryHookOptions<TagQuery, TagQueryVariables> & ({ variables: TagQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagQuery, TagQueryVariables>(TagDocument, options);
      }
export function useTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagQuery, TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagQuery, TagQueryVariables>(TagDocument, options);
        }
export function useTagSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TagQuery, TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TagQuery, TagQueryVariables>(TagDocument, options);
        }
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagSuspenseQueryHookResult = ReturnType<typeof useTagSuspenseQuery>;
export type TagQueryResult = Apollo.QueryResult<TagQuery, TagQueryVariables>;
export const SaveTagDocument = gql`
    mutation saveTag($id: UUID, $input: TagInput!) {
  saveTag(id: $id, input: $input) {
    id
    name
    context
  }
}
    `;
export type SaveTagMutationFn = Apollo.MutationFunction<SaveTagMutation, SaveTagMutationVariables>;

/**
 * __useSaveTagMutation__
 *
 * To run a mutation, you first call `useSaveTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTagMutation, { data, loading, error }] = useSaveTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTagMutation(baseOptions?: Apollo.MutationHookOptions<SaveTagMutation, SaveTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveTagMutation, SaveTagMutationVariables>(SaveTagDocument, options);
      }
export type SaveTagMutationHookResult = ReturnType<typeof useSaveTagMutation>;
export type SaveTagMutationResult = Apollo.MutationResult<SaveTagMutation>;
export type SaveTagMutationOptions = Apollo.BaseMutationOptions<SaveTagMutation, SaveTagMutationVariables>;
export const DeleteTagDocument = gql`
    mutation deleteTag($id: UUID!) {
  deleteTag(id: $id)
}
    `;
export type DeleteTagMutationFn = Apollo.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, options);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>;