import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FullProjectDataFragment = { __typename?: 'Project', id: any, projectNumber?: number | null, title: string, slugs?: string | null, amountPerHour?: number | null, status: Types.ProjectStatus, customerId: any, customer: { __typename?: 'Customer', id: any, firstName?: string | null, lastName?: string | null, companyName?: string | null, contactEmail: string, slugs: string, amountPerHour?: number | null } };

export type ProjectQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type ProjectQuery = { __typename?: 'Query', project: { __typename?: 'Project', id: any, projectNumber?: number | null, title: string, slugs?: string | null, amountPerHour?: number | null, status: Types.ProjectStatus, customerId: any, customer: { __typename?: 'Customer', id: any, firstName?: string | null, lastName?: string | null, companyName?: string | null, contactEmail: string, slugs: string, amountPerHour?: number | null } } };

export type UpdateProjectMutationVariables = Types.Exact<{
  input: Types.ProjectInput;
  id: Types.Scalars['UUID']['input'];
}>;


export type UpdateProjectMutation = { __typename?: 'Mutation', updateProject: { __typename?: 'Project', id: any, projectNumber?: number | null, title: string, slugs?: string | null, amountPerHour?: number | null, status: Types.ProjectStatus, customerId: any, customer: { __typename?: 'Customer', id: any, firstName?: string | null, lastName?: string | null, companyName?: string | null, contactEmail: string, slugs: string, amountPerHour?: number | null } } };

export type CreateProjectMutationVariables = Types.Exact<{
  input: Types.ProjectInput;
}>;


export type CreateProjectMutation = { __typename?: 'Mutation', createProject: { __typename?: 'Project', id: any, projectNumber?: number | null, title: string, slugs?: string | null, amountPerHour?: number | null, status: Types.ProjectStatus, customerId: any, customer: { __typename?: 'Customer', id: any, firstName?: string | null, lastName?: string | null, companyName?: string | null, contactEmail: string, slugs: string, amountPerHour?: number | null } } };

export type SoftDeleteProjectMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type SoftDeleteProjectMutation = { __typename?: 'Mutation', softDeleteProject: boolean };

export const FullProjectDataFragmentDoc = gql`
    fragment FullProjectData on Project {
  id
  projectNumber
  title
  slugs
  amountPerHour
  status
  customer {
    id
    firstName
    lastName
    companyName
    contactEmail
    slugs
    amountPerHour
  }
  customerId
}
    `;
export const ProjectDocument = gql`
    query project($id: UUID!) {
  project(id: $id) {
    ...FullProjectData
  }
}
    ${FullProjectDataFragmentDoc}`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables> & ({ variables: ProjectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export function useProjectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectSuspenseQueryHookResult = ReturnType<typeof useProjectSuspenseQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const UpdateProjectDocument = gql`
    mutation updateProject($input: ProjectInput!, $id: UUID!) {
  updateProject(input: $input, id: $id) {
    ...FullProjectData
  }
}
    ${FullProjectDataFragmentDoc}`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const CreateProjectDocument = gql`
    mutation createProject($input: ProjectInput!) {
  createProject(input: $input) {
    ...FullProjectData
  }
}
    ${FullProjectDataFragmentDoc}`;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const SoftDeleteProjectDocument = gql`
    mutation softDeleteProject($id: UUID!) {
  softDeleteProject(id: $id)
}
    `;
export type SoftDeleteProjectMutationFn = Apollo.MutationFunction<SoftDeleteProjectMutation, SoftDeleteProjectMutationVariables>;

/**
 * __useSoftDeleteProjectMutation__
 *
 * To run a mutation, you first call `useSoftDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteProjectMutation, { data, loading, error }] = useSoftDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoftDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteProjectMutation, SoftDeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteProjectMutation, SoftDeleteProjectMutationVariables>(SoftDeleteProjectDocument, options);
      }
export type SoftDeleteProjectMutationHookResult = ReturnType<typeof useSoftDeleteProjectMutation>;
export type SoftDeleteProjectMutationResult = Apollo.MutationResult<SoftDeleteProjectMutation>;
export type SoftDeleteProjectMutationOptions = Apollo.BaseMutationOptions<SoftDeleteProjectMutation, SoftDeleteProjectMutationVariables>;