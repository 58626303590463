import { useResources } from '@/features/resources/resources.provider';
import { InputField } from '@/features/ui/form/input-field.component';
import { SelectField } from '@/features/ui/form/select-field.component';
import { Frame } from '@/features/ui/frame/frame';
import { useConfirm } from '@/features/ui/modal/modal.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'semantic-ui-react';
import { ProjectInput } from '../../../graphql/generated/types';
import * as styles from '../../../styles/Edit.module.scss';
import {
  useCreateProjectMutation,
  useProjectQuery,
  useSoftDeleteProjectMutation,
  useUpdateProjectMutation,
} from './edit.generated';
import schema from './schema';

export const Edit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [open, setOpen] = useState(false);

  const { loading, error, data } = useProjectQuery({
    variables: { id },
    skip: id === undefined,
    fetchPolicy: 'network-only',
  });
  const project = data?.project;

  const { customers, refresh } = useResources();

  const [modal, { ask }] = useConfirm((id: any) => {
    alert('Delete function to implement');
  });

  const { control, handleSubmit, reset, formState, setValue, watch } =
    useForm<ProjectInput>({
      shouldUnregister: true,
      resolver: yupResolver(schema),
    });

  const [create] = useCreateProjectMutation();
  const [update] = useUpdateProjectMutation();
  const [softDelete] = useSoftDeleteProjectMutation();

  useEffect(() => {
    if (project) {
      reset(project);
    }
  }, [project]);

  const onSubmit = useMemo(
    () =>
      handleSubmit(
        async (input) => {
          if (id) {
            try {
              await update({ variables: { id, input } });
              await refresh();
              toast.success('Project successfully updated!');
            } catch (err) {
              toast.error('An error occurred :(  ' + err);
            }
          } else {
            try {
              const result = await create({ variables: { input } });
              if (result.data) {
                navigate(`/projects/${result.data.createProject.id}/edit`);
                await refresh();
                toast.success('New project successfully saved!');
              }
            } catch (err) {
              toast.error('An error occurred :(  ' + err);
            }
          }
        },
        (error) => {
          console.log({ error });
        },
      ),
    [id],
  );

  const handleSoftDelete = async (id: string | string[] | undefined) => {
    try {
      await softDelete({ variables: { id } });
      toast.success('Project successfully deleted!');
      setOpen(false);
    } catch (err) {
      toast.error('An error occurred :(  ' + err);
      setOpen(false);
    }
  };

  return (
    <>
      <Frame.TitleBar>
        <Frame.Title>{id ? 'Edit Project' : 'Create Project'}</Frame.Title>
        <Frame.Actions>
          <Button basic color="red" onClick={() => navigate('/projects')}>
            {!id && <>Cancel</>}
            {id && <>Close</>}
          </Button>
        </Frame.Actions>
      </Frame.TitleBar>
      <Frame.Content loading={loading}>
        <Form className={styles.form} error={!formState.isValid}>
          <Controller
            name="projectNumber"
            control={control}
            render={({ field, fieldState }) => (
              <InputField
                label="Project number"
                field={field}
                fieldState={fieldState}
              />
            )}
          />

          <Controller
            name="title"
            control={control}
            render={({ field, fieldState }) => (
              <InputField label="Title" field={field} fieldState={fieldState} />
            )}
          />
          <Controller
            name="slugs"
            control={control}
            render={({ field, fieldState }) => (
              <InputField label="Slugs" field={field} fieldState={fieldState} />
            )}
          />
          <Controller
            name="customerId"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                searchable
                options={customers.map((c) => ({
                  text: c.label,
                  value: c.id,
                }))}
                setValue={setValue}
                label="Customer"
                field={field}
                fieldState={fieldState}
              />
            )}
          />
          <Controller
            name="amountPerHour"
            control={control}
            render={({ field, fieldState }) => (
              <InputField
                label="Amount per hour"
                field={field}
                fieldState={fieldState}
              />
            )}
          />

          <Button.Group>
            <Button type="submit" color="green" onClick={onSubmit}>
              Save
            </Button>
            {/* {id && (
              <Button
                type="button"
                color="red"
                basic
                onClick={() =>
                  ask(`Are you sure you want to delete this project?`)
                }
              >
                Delete
              </Button>
            )} */}
          </Button.Group>
          {modal}
        </Form>
      </Frame.Content>
    </>
  );
};
