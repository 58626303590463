import { Profile } from '@/features/profile/profile.context';
import { Formatted } from '@/features/ui/formatted/formatted.component';
import { Frame } from '@/features/ui/frame/frame';
import {
  getPaginationFromQuery,
  usePagination,
} from '@/features/ui/pagination/pagination';
import { Pagination } from '@/features/ui/pagination/pagination.component';
import { usePaginatedUrlQuery } from '@/features/utils/url-query';
import { PaginationInput } from '@/graphql/generated/types';
import * as globalStyles from '@/styles/globals.scss';
import { ComponentType, useCallback } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Label, Table, TableRow } from 'semantic-ui-react';
import { IssueLabel } from '../issue-label.component';
import { Filter, FilterState } from './filter.component';
import {
  useIssuesQuery,
  useSyncIssuesWithJiraMutation,
} from './list.generated';

export const List: ComponentType = () => {
  const profile = Profile.use();
  const navigate = useNavigate();
  const [urlQuery, setUrlQuery] = usePaginatedUrlQuery<FilterState>();

  const { data, loading, refetch } = useIssuesQuery({
    fetchPolicy: 'network-only',
    variables: {
      pagination: getPaginationFromQuery(urlQuery),
      filter: {
        query: urlQuery.query,
        projectId: urlQuery.projectId,
        billable: urlQuery.billable,
      },
    },
  });

  const [sync, { loading: syncLoading }] = useSyncIssuesWithJiraMutation();

  const onPaginationChange = useCallback(
    (value: PaginationInput) => {
      setUrlQuery({
        ...urlQuery,
        skip: value.skip?.toString(),
        take: value.take?.toString(),
      });
    },
    [urlQuery],
  );

  const pagination = usePagination(
    data?.issues.totalCount || 0,
    {
      skip: Number(urlQuery.skip),
      take: Number(urlQuery.take),
    },
    onPaginationChange,
  );

  const onSync = useCallback(async () => {
    const result = await sync();
    if (result.data?.syncIssuesWithJira.result) {
      toast.success(result.data.syncIssuesWithJira.result);
      refetch();
    } else {
      toast.error(result.data?.syncIssuesWithJira.error);
    }
  }, []);
  const issues = data?.issues.data || [];

  return (
    <>
      <Frame.TitleBar>
        <Frame.Title>Issues</Frame.Title>
        <Frame.Actions>
          <Button color="red" onClick={onSync}>
            Sync issues with Jira
          </Button>
          {profile?.isAdmin && (
            <Button basic as={NavLink} to="mappings">
              Map prefixes to projects
            </Button>
          )}
          <Button
            basic
            color="green"
            onClick={() => navigate('/issues/create')}
          >
            Create Issue
          </Button>
        </Frame.Actions>
      </Frame.TitleBar>
      <Frame.Content loading={loading || syncLoading}>
        <>
          <Filter />
          <Pagination {...pagination} />
          <Table basic="very" size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Ticket</Table.HeaderCell>
                <Table.HeaderCell>Project</Table.HeaderCell>
                <Table.HeaderCell>Billable</Table.HeaderCell>
                <Table.HeaderCell>Estimation</Table.HeaderCell>
                <Table.HeaderCell>Fixed Amount</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {issues.map((issue) => (
                <TableRow key={issue.id}>
                  <Table.Cell>
                    <IssueLabel issue={issue} />
                  </Table.Cell>
                  <Table.Cell>
                    {!issue.project && (
                      <Label color="red" horizontal>
                        Not assigned!
                      </Label>
                    )}
                    {issue.project?.title}
                    <div className={globalStyles.small}>
                      {issue.project?.customer?.label}
                    </div>
                  </Table.Cell>
                  <Table.Cell>{issue.billable && '$$$'}</Table.Cell>
                  <Table.Cell>
                    <Formatted.Duration value={issue.estimation} unit="m" />
                  </Table.Cell>

                  <Table.Cell>
                    <Formatted.Currency value={issue.fixedAmount} />
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={`/issues/${issue.id}/edit`}>
                      <Button basic>Details</Button>
                    </Link>
                  </Table.Cell>
                </TableRow>
              ))}
            </Table.Body>
          </Table>
        </>
      </Frame.Content>
    </>
  );
};
