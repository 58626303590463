import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PrefixMappingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PrefixMappingsQuery = { __typename?: 'Query', prefixMappings: Array<{ __typename?: 'FoundPrefixMapping', prefix: string, projectId?: any | null }> };

export type SavePrefixMappingsMutationVariables = Types.Exact<{
  input: Array<Types.SavePrefixMappingInput> | Types.SavePrefixMappingInput;
}>;


export type SavePrefixMappingsMutation = { __typename?: 'Mutation', savePrefixMappings: boolean };

export type ApplyPrefixMappingsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ApplyPrefixMappingsMutation = { __typename?: 'Mutation', applyPrefixMappings: number };


export const PrefixMappingsDocument = gql`
    query prefixMappings {
  prefixMappings {
    prefix
    projectId
  }
}
    `;

/**
 * __usePrefixMappingsQuery__
 *
 * To run a query within a React component, call `usePrefixMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrefixMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrefixMappingsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrefixMappingsQuery(baseOptions?: Apollo.QueryHookOptions<PrefixMappingsQuery, PrefixMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrefixMappingsQuery, PrefixMappingsQueryVariables>(PrefixMappingsDocument, options);
      }
export function usePrefixMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrefixMappingsQuery, PrefixMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrefixMappingsQuery, PrefixMappingsQueryVariables>(PrefixMappingsDocument, options);
        }
export function usePrefixMappingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PrefixMappingsQuery, PrefixMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PrefixMappingsQuery, PrefixMappingsQueryVariables>(PrefixMappingsDocument, options);
        }
export type PrefixMappingsQueryHookResult = ReturnType<typeof usePrefixMappingsQuery>;
export type PrefixMappingsLazyQueryHookResult = ReturnType<typeof usePrefixMappingsLazyQuery>;
export type PrefixMappingsSuspenseQueryHookResult = ReturnType<typeof usePrefixMappingsSuspenseQuery>;
export type PrefixMappingsQueryResult = Apollo.QueryResult<PrefixMappingsQuery, PrefixMappingsQueryVariables>;
export const SavePrefixMappingsDocument = gql`
    mutation savePrefixMappings($input: [SavePrefixMappingInput!]!) {
  savePrefixMappings(input: $input)
}
    `;
export type SavePrefixMappingsMutationFn = Apollo.MutationFunction<SavePrefixMappingsMutation, SavePrefixMappingsMutationVariables>;

/**
 * __useSavePrefixMappingsMutation__
 *
 * To run a mutation, you first call `useSavePrefixMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePrefixMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePrefixMappingsMutation, { data, loading, error }] = useSavePrefixMappingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePrefixMappingsMutation(baseOptions?: Apollo.MutationHookOptions<SavePrefixMappingsMutation, SavePrefixMappingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SavePrefixMappingsMutation, SavePrefixMappingsMutationVariables>(SavePrefixMappingsDocument, options);
      }
export type SavePrefixMappingsMutationHookResult = ReturnType<typeof useSavePrefixMappingsMutation>;
export type SavePrefixMappingsMutationResult = Apollo.MutationResult<SavePrefixMappingsMutation>;
export type SavePrefixMappingsMutationOptions = Apollo.BaseMutationOptions<SavePrefixMappingsMutation, SavePrefixMappingsMutationVariables>;
export const ApplyPrefixMappingsDocument = gql`
    mutation applyPrefixMappings {
  applyPrefixMappings
}
    `;
export type ApplyPrefixMappingsMutationFn = Apollo.MutationFunction<ApplyPrefixMappingsMutation, ApplyPrefixMappingsMutationVariables>;

/**
 * __useApplyPrefixMappingsMutation__
 *
 * To run a mutation, you first call `useApplyPrefixMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyPrefixMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPrefixMappingsMutation, { data, loading, error }] = useApplyPrefixMappingsMutation({
 *   variables: {
 *   },
 * });
 */
export function useApplyPrefixMappingsMutation(baseOptions?: Apollo.MutationHookOptions<ApplyPrefixMappingsMutation, ApplyPrefixMappingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyPrefixMappingsMutation, ApplyPrefixMappingsMutationVariables>(ApplyPrefixMappingsDocument, options);
      }
export type ApplyPrefixMappingsMutationHookResult = ReturnType<typeof useApplyPrefixMappingsMutation>;
export type ApplyPrefixMappingsMutationResult = Apollo.MutationResult<ApplyPrefixMappingsMutation>;
export type ApplyPrefixMappingsMutationOptions = Apollo.BaseMutationOptions<ApplyPrefixMappingsMutation, ApplyPrefixMappingsMutationVariables>;