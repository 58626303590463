import { DebouncedInput } from '@/features/ui/debounced-input/debounced-input.component';
import { useUrlQuery } from '@/features/utils/url-query';
import { ComponentType } from 'react';
import { Form } from 'semantic-ui-react';

export interface FilterState {
  query?: string | null;
}

export const Filter: ComponentType = (props) => {
  const [urlQuery, updateUrlQuery] = useUrlQuery<FilterState>();

  return (
    <Form>
      <Form.Group style={{ flexWrap: 'wrap' }}>
        <Form.Field>
          <label>Search</label>
          <DebouncedInput
            placeholder="Title, Customer, Company, ..."
            delay={500}
            clearable
            type="text"
            value={urlQuery.query || ''}
            onDebouncedChange={(v) => updateUrlQuery({ ...urlQuery, query: v })}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
