import { ComponentType, PropsWithChildren } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import * as styles from './content.module.scss';
interface Props {
  loading?: boolean;
}

export const Content: ComponentType<PropsWithChildren<Props>> = (props) => {
  const { loading } = props;
  return (
    <div className={styles.host}>
      {props.children}
      {loading && (
        <Dimmer active>
          <Loader />
        </Dimmer>
      )}
    </div>
  );
};
