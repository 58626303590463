
import React from 'react';
import { useGetTotalHoursLoggedPerDayQuery } from '../daylogs/daylogs.generated';

const DayView: React.FC<{ date: string }> = ({ date }) => {
  const { loading, error, data } = useGetTotalHoursLoggedPerDayQuery();

  if (loading) return <span>Loading...</span>;
  if (error) {
    console.error(error);
    return <span>Error</span>;
  }

  if (!data || !data.getTotalHoursLoggedPerDay) {
    console.error('Data is undefined or empty');
    return <span>0 hrs</span>;
  }

  const dayEntries = data.getTotalHoursLoggedPerDay.filter(
    (day) => new Date(parseInt(day.date)).toISOString().split('T')[0] === date,
  );

  const totalHours = dayEntries.reduce(
    (sum, entry) => sum + entry.hoursLogged,
    0,
  );
  const roundedHours = Math.round(totalHours * 100) / 100;

  const backgroundColor = roundedHours < 16 ? '#FFCCCB' : '#90EE90';

  return (
    <div
      style={{
        backgroundColor,
        textAlign: 'center',
        height: '100%',
        width: '200%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: '5em'
      }}
    >
      <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
        {roundedHours} hrs
      </span>
    </div>
  );
};

export default DayView;
