import { ComponentType } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Edit as BulkEdit } from './bulkedit/edit.component';
import { Edit } from './edit/edit.component';
import { List } from './list/list.component';

export const Timelogs: ComponentType = (props) => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path="/list" element={<List />} />
      <Route path="/create" element={<Edit />} />
      <Route path="/:id/edit" element={<Edit />} />
      <Route path="/bulk-edit" element={<BulkEdit />} />
    </Routes>
  );
};
