import { ApolloClient, InMemoryCache } from '@apollo/client';
import introspection from './generated/introspection';

const GRAPHQL_HTTP_URI = process.env.GRAPHQL_HTTP_URI;

export const client = new ApolloClient({
  uri: GRAPHQL_HTTP_URI,
  cache: new InMemoryCache({
    addTypename: true,
    resultCaching: true,
    possibleTypes: introspection.possibleTypes,
  }),
  credentials: 'include',
});

// export const client = new ApolloClient({
//     uri: 'https://48p1r2roz4.sse.codesandbox.io',
//     cache: new InMemoryCache()
//   });
