import React, { ReactNode, useState } from 'react';
import { PropsWithChildren, ComponentType } from 'react';
import DayView from './dayView.component';
import DayDetails from './daydetail.component';
import { Frame } from '@/features/ui/frame/frame';
import {
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table,
  Icon,
  Button,
} from 'semantic-ui-react';
import { useGetTotalHoursLoggedPerDayQuery } from '../daylogs/daylogs.generated';

interface DayStats {
  id: string;
  hoursLogged: number;
}

interface Props {
  days: DayStats[];
}

const getDaysInMonth = (year: number, month: number) => {
  return new Date(year, month + 1, 0).getDate();
};

const getFirstDayOfMonth = (year: number, month: number) => {
  const day = new Date(year, month, 1).getDay();
  return day === 0 ? 6 : day - 1;
};

export const Daylogs: ComponentType<PropsWithChildren<Props>> = (props) => {
  const { days } = props;

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const { data: hoursLoggedData } = useGetTotalHoursLoggedPerDayQuery();

  const handlePreviousMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const handleCellClick = (date: string) => {
    setSelectedDate(date);
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const daysInMonth = getDaysInMonth(year, month);
  const firstDayOfMonth = getFirstDayOfMonth(year, month);

  const weeks: ReactNode[] = [];
  let daysArray: ReactNode[] = [];

  // Generate the array for days
  for (let i = 0; i < firstDayOfMonth; i++) {
    daysArray.push(<TableCell key={`empty-${i}`} />);
  }

  for (let day = 1; day <= daysInMonth; day++) {
    const date = new Date(year, month, day).toISOString().split('T')[0];

    const dayStyle = {
      fontSize: '1em',
      position: 'absolute' as const,
      top: '5px',
      left: '5px',
    };

    daysArray.push(
      <TableCell key={day}>
        <div style={{ position: 'relative', height: '100%' }}>
          <span style={dayStyle}>{day}</span>
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => handleCellClick(date)}
          >
            <DayView date={date} />
          </div>
        </div>
      </TableCell>
    );

    if ((day + firstDayOfMonth) % 7 === 0 || day === daysInMonth) {
      weeks.push(<TableRow key={`week-${weeks.length}`}>{daysArray}</TableRow>);
      daysArray = [];
    }
  }

  const selectedDayData = selectedDate
    ? hoursLoggedData?.getTotalHoursLoggedPerDay.filter(
        (day) =>
          new Date(parseInt(day.date)).toISOString().split('T')[0] ===
          selectedDate
      ) || []
    : [];

  return (
    <>
      <Frame.TitleBar>
        <Frame.Title>{'Daylogs'}</Frame.Title>
      </Frame.TitleBar>
      <div style={{ margin: '2em 4em' }}>
        <Button icon onClick={handlePreviousMonth}>
          <Icon name="angle left" />
        </Button>
        {currentDate.toLocaleString('default', { month: 'long' })} {year}
        <Button icon onClick={handleNextMonth}>
          <Icon name="angle right" />
        </Button>
      </div>
      <Table celled style={{ margin: '4em', width: '90%' }}>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Mon</TableHeaderCell>
            <TableHeaderCell>Tues</TableHeaderCell>
            <TableHeaderCell>Wed</TableHeaderCell>
            <TableHeaderCell>Thur</TableHeaderCell>
            <TableHeaderCell>Fri</TableHeaderCell>
            <TableHeaderCell>Sat</TableHeaderCell>
            <TableHeaderCell>Sun</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {weeks.map((week, weekIndex) => (
            <TableRow key={`week-${weekIndex}`}>
              {week.props.children.map((cell: React.ReactElement, cellIndex: number) => {
                if (cell.key?.startsWith('empty-')) {
                  return cell;
                }
                return (
                  <TableCell
                    key={cellIndex}
                    style={{
                      ...cell.props.style,
                      cursor: 'pointer',
                    }}
                  >
                    {cell.props.children}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {selectedDate && (
        <DayDetails date={selectedDate} data={selectedDayData} />
      )}
    </>
  );
};

