import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTotalHoursLoggedPerDayQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTotalHoursLoggedPerDayQuery = { __typename?: 'Query', getTotalHoursLoggedPerDay: Array<{ __typename?: 'HoursLoggedPerDay', date: string, username: string, hoursLogged: number }> };


export const GetTotalHoursLoggedPerDayDocument = gql`
    query getTotalHoursLoggedPerDay {
  getTotalHoursLoggedPerDay {
    date
    username
    hoursLogged
  }
}
    `;

/**
 * __useGetTotalHoursLoggedPerDayQuery__
 *
 * To run a query within a React component, call `useGetTotalHoursLoggedPerDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalHoursLoggedPerDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalHoursLoggedPerDayQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTotalHoursLoggedPerDayQuery(baseOptions?: Apollo.QueryHookOptions<GetTotalHoursLoggedPerDayQuery, GetTotalHoursLoggedPerDayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTotalHoursLoggedPerDayQuery, GetTotalHoursLoggedPerDayQueryVariables>(GetTotalHoursLoggedPerDayDocument, options);
      }
export function useGetTotalHoursLoggedPerDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTotalHoursLoggedPerDayQuery, GetTotalHoursLoggedPerDayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTotalHoursLoggedPerDayQuery, GetTotalHoursLoggedPerDayQueryVariables>(GetTotalHoursLoggedPerDayDocument, options);
        }
export function useGetTotalHoursLoggedPerDaySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTotalHoursLoggedPerDayQuery, GetTotalHoursLoggedPerDayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTotalHoursLoggedPerDayQuery, GetTotalHoursLoggedPerDayQueryVariables>(GetTotalHoursLoggedPerDayDocument, options);
        }
export type GetTotalHoursLoggedPerDayQueryHookResult = ReturnType<typeof useGetTotalHoursLoggedPerDayQuery>;
export type GetTotalHoursLoggedPerDayLazyQueryHookResult = ReturnType<typeof useGetTotalHoursLoggedPerDayLazyQuery>;
export type GetTotalHoursLoggedPerDaySuspenseQueryHookResult = ReturnType<typeof useGetTotalHoursLoggedPerDaySuspenseQuery>;
export type GetTotalHoursLoggedPerDayQueryResult = Apollo.QueryResult<GetTotalHoursLoggedPerDayQuery, GetTotalHoursLoggedPerDayQueryVariables>;