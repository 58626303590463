import { Formatted } from '@/features/ui/formatted/formatted.component';
import { Frame } from '@/features/ui/frame/frame';
import { useUrlQuery } from '@/features/utils/url-query';
import * as globalStyles from '@/styles/globals.scss';
import { ComponentType } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Table, TableRow } from 'semantic-ui-react';
import { ProjectLabel } from '../project-label.component';
import { Filter, FilterState } from './filter.component';
import { useProjectsQuery } from './list.generated';

export const List: ComponentType = () => {
  const navigate = useNavigate();
  const [urlQuery] = useUrlQuery<FilterState>();
  const { data, loading } = useProjectsQuery({
    variables: { filter: { query: urlQuery.query } },
  });
  const projects = data?.projects || [];

  return (
    <>
      <Frame.TitleBar>
        <Frame.Title>Projects</Frame.Title>
        <Frame.Actions>
          <Button
            basic
            color="black"
            onClick={() => navigate('/projects/create')}
          >
            Create Project
          </Button>
        </Frame.Actions>
      </Frame.TitleBar>
      <Frame.Content loading={loading}>
        <Filter />
        <Table basic="very" size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Project</Table.HeaderCell>
              <Table.HeaderCell>Customer</Table.HeaderCell>
              <Table.HeaderCell>Time spent</Table.HeaderCell>
              <Table.HeaderCell>Amount per hour</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {projects.map((project) => (
              <TableRow key={project.id}>
                <Table.Cell>
                  <ProjectLabel project={project} />
                  <div className={globalStyles.secondary}>{project.slugs}</div>
                </Table.Cell>
                <Table.Cell>{project.customer.companyName}</Table.Cell>
                <Table.Cell>
                  <Formatted.Duration value={project.timeSpent} unit="h" />
                </Table.Cell>
                <Table.Cell>
                  <Formatted.Currency value={project.amountPerHour || 0} />
                </Table.Cell>
                <Table.Cell>
                  <Link to={`/projects/${project.id}/edit`}>
                    <Button basic>Details</Button>
                  </Link>
                </Table.Cell>
              </TableRow>
            ))}
          </Table.Body>
        </Table>
      </Frame.Content>
    </>
  );
};
