import { Formatted } from '@/features/ui/formatted/formatted.component';
import { Frame } from '@/features/ui/frame/frame';
import { ComponentType } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Switch from 'react-switch';
import { Button, Dimmer, Loader, Table } from 'semantic-ui-react';
import { useInvoicesQuery, useToggleSentMutation } from './list.generated';

export const List: ComponentType = () => {
  let navigate = useNavigate();
  const { data, loading } = useInvoicesQuery();
  const invoices = data?.invoices;

  const [updateIsSent] = useToggleSentMutation();

  return (
    <>
      <Frame.TitleBar>
        <Frame.Title>Invoices</Frame.Title>
      </Frame.TitleBar>
      <Frame.Content>
        {loading && (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}
        {!loading && (
          <>
            {invoices && invoices.length > 0 ? (
              <Table basic="very" size="small">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.HeaderCell>Created At</Table.HeaderCell>
                    <Table.HeaderCell>Sent</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {invoices.map(({ id, createdAt, sentAt }) => (
                    <Table.Row key={id}>
                      <Table.Cell>{id}</Table.Cell>
                      <Table.Cell>
                        <Formatted.Date value={createdAt} withTime />
                      </Table.Cell>
                      <Table.Cell>
                        <Switch
                          onChange={() =>
                            updateIsSent({ variables: { id: id } })
                          }
                          checked={sentAt !== null}
                          onColor="#21ba45"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Link to={`/invoices`}>
                          <Button basic>Details</Button>
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <h2>
                Oops, it seems like you have no invoices recorded in the
                system...
              </h2>
            )}
          </>
        )}
      </Frame.Content>
    </>
  );
};
