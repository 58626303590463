// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w5tHD0GgJmuDI4JijIMf span{display:none}.w5tHD0GgJmuDI4JijIMf span.YoK0Ff23lU1527Y0u4pB{display:inline}`, "",{"version":3,"sources":["webpack://./src/features/ui/loader/character-based/loader.module.scss"],"names":[],"mappings":"AAGE,2BACE,YAAA,CACA,gDACE,cAAA","sourcesContent":["@import 'styles/variables';\n\n.host {\n  span {\n    display: none;\n    &.visible {\n      display: inline;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var host = `w5tHD0GgJmuDI4JijIMf`;
export var visible = `YoK0Ff23lU1527Y0u4pB`;
export default ___CSS_LOADER_EXPORT___;
