import { useLogoutMutation } from '@/features/login/login.generated';
import { Profile } from '@/features/profile/profile.context';
import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import tyrion from '../../../../assets/img/tyrion.png';
import { Navigation } from '../../navigation/navigation.component';
import * as styles from './sidebar.module.scss';
import { useTyrion } from './tyrion.hook';

export const Sidebar = () => {
  const profile = Profile.use();

  const { transform } = useTyrion({
    relativeThresholdX: 0.1,
    relativeThresholdY: 0.8,
  });

  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const client = useApolloClient();

  const onLogoutClick = useCallback(async () => {
    toast.info('We are logging you out... See you later!');
    await logout();
    await client.resetStore();

    navigate('/login');
  }, []);

  return (
    <>
      {profile && (
        <div className={styles.sidebar}>
          <div>
            <Link to="/">
              <h1 className={styles.sidebarBrand}>Tyrion</h1>
            </Link>
            <Navigation />
          </div>
          <div className={styles.sidebarFooter}>
            {profile && (
              <Button fluid basic color="black" onClick={onLogoutClick}>
                Log out
              </Button>
            )}
            <span className={styles.footerText}>
              This is a VOLTA production, &copy; {new Date().getFullYear()}
            </span>
          </div>

          <img
            className={styles.image}
            style={{
              transform: `translate(${-transform * 10}%,${
                transform * 1
              }%) rotate(${transform * -3}deg)`,
            }}
            src={tyrion}
            alt="Tyrion image"
          />
        </div>
      )}
    </>
  );
};
