// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lrx2X16hW__OeBOAxvzg .xxEBmzqRjQY7nPWYvf45{width:100%}`, "",{"version":3,"sources":["webpack://./src/features/toggle-moggle/modal/modal.module.scss"],"names":[],"mappings":"AACE,4CACE,UAAA","sourcesContent":[".host {\n  .input {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var host = `lrx2X16hW__OeBOAxvzg`;
export var input = `xxEBmzqRjQY7nPWYvf45`;
export default ___CSS_LOADER_EXPORT___;
