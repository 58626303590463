import { ProjectToggleEntry } from '@/graphql/generated/types';
import { differenceInSeconds, parseISO } from 'date-fns';
import { ComponentType, useCallback, useEffect, useMemo, useRef } from 'react';
import { Icon } from 'semantic-ui-react';
import { Profile } from '../profile/profile.context';
import { useResources } from '../resources/resources.provider';
import { secondsToTime } from '../utils/format';
import { useAddProjectModal } from './modal/add-project-modal.hook';
import { useCloseProjectModal } from './modal/close-project-modal.hook';
import { Moggle } from './modal/moggle/moggle.component';
import { useProjectList } from './project-list/project-list';
import {
  MogglesQuery,
  useMogglesQuery,
  useStartToggleMutation,
  useStopToggleMutation,
} from './toggle-moggle.generated';
import * as styles from './toggle-moggle.module.scss';

type MoggleClock = {
  duration: number;
  id: string;
  start: string;
};

interface ToggleMoggleProps {}

export const ToggleMoggle: ComponentType<ToggleMoggleProps> = (props) => {
  const moggleClocks = useRef<MoggleClock[]>([]);
  const timerRef = useRef<any>();
  const user = Profile.use();
  const { projects: allProjects } = useResources();

  const {
    projects: myProjectIdsAndWidths,
    add,
    setProjects,
  } = useProjectList();

  const onAddProject = useCallback((id) => {
    if (id) {
      add(id, 0.25);
    }
  }, []);

  const [modal, { open }] = useAddProjectModal(
    allProjects.filter((p) => !myProjectIdsAndWidths.includes(p.id)),
    onAddProject,
  );

  const [closeModal, { open: openCloseProjectModal }] = useCloseProjectModal(
    async (id: string, answer: string) => {
      const timelog = await stop({
        variables: { input: { id, description: answer } },
      });
      moggles.refetch();
    },
  );

  const [start] = useStartToggleMutation();
  const [stop] = useStopToggleMutation();

  const moggles = useMogglesQuery({
    variables: {
      ids: myProjectIdsAndWidths?.map((e) => e.id),
    },
    skip: !myProjectIdsAndWidths,
  });

  const myProjects = useMemo(() => {
    return (myProjectIdsAndWidths?.reduce((prev: any, current) => {
      const project = moggles.data?.projects.find((p) => p.id === current.id);
      if (!project) {
        return prev;
      }
      return [...prev, { project, width: current.width }];
    }, []) || []) as {
      project: MogglesQuery['projects'][number];
      width: number;
    }[];
  }, [moggles, myProjectIdsAndWidths, user?.settings]);

  const onAskAddProject = useCallback(() => {
    open();
  }, [open]);

  const onAskCloseTimelog = useCallback(
    (id: string) => openCloseProjectModal(id),
    [],
  );

  const onToggleMoggle = useCallback(
    async (projectId: string, timelogId?: string) => {
      const m = moggles.data?.projects.find((p) => p.id === projectId);

      if (m?.openTimelog && timelogId) {
        onAskCloseTimelog(timelogId);
      } else {
        const newTimelog = await start({ variables: { input: { projectId } } });
        moggles.refetch();
      }
    },
    [moggles],
  );

  useEffect(() => {
    setProjects((user?.settings.projectToggles || []) as ProjectToggleEntry[]);
  }, [user]);

  useEffect(() => {
    moggleClocks.current = [];
    for (const project of myProjects) {
      if (project.project.openTimelog) {
        moggleClocks.current.push({
          duration: 0,
          id: project.project.id,
          start: project.project.openTimelog.date,
        });
      }
    }
  }, [myProjects]);

  useEffect(() => {
    clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      const now = new Date();
      for (const clock of moggleClocks.current) {
        const clockDiv = document.querySelector(`#clock-${clock.id}`);
        if (clockDiv) {
          clockDiv.innerHTML = `${secondsToTime(
            differenceInSeconds(now, parseISO(clock.start)),
            true,
          )}`;
        }
      }
    }, 1000);
    return () => clearInterval(timerRef.current);
  }, []);

  return (
    <div className={styles.host}>
      {modal}
      {closeModal}
      <div className={styles.projectToggles}>
        {myProjects.map(({ project, width }) => (
          <Moggle
            key={project.id}
            project={project}
            width={width}
            onToggle={() => onToggleMoggle(project.id, project.openTimelog?.id)}
          />
        ))}
      </div>
      <div className={styles.addProjectButton}>
        <Icon
          link
          color="grey"
          size="huge"
          name="add circle"
          onClick={onAskAddProject}
        />
      </div>
    </div>
  );
};
