import queryString from 'query-string';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface UrlQueryParams<T> {
  initialQuery?: Partial<T>;
}
export function usePaginatedUrlQuery<T extends object>(
  params?: UrlQueryParams<T>,
) {
  return useUrlQuery<T & { skip?: string; take?: string }>(params);
}

export function useUrlQuery<T extends object>(
  params?: UrlQueryParams<T>,
): [T, (value: T) => void] {
  const location = useLocation();
  const navigate = useNavigate();

  const query = useMemo(
    () => queryString.parse(location.search),
    [location.search],
  ) as T;

  const setQuery = useCallback(
    (value: Partial<T>) => {
      const qs = queryString.stringify(value, { encode: true });
      navigate(`${location.pathname}?${qs}${location.hash}`, {
        state: { qs },
        replace: true,
      });
    },
    [navigate],
  );

  useEffect(() => {
    if (params?.initialQuery) {
      setQuery(params.initialQuery);
    }
  }, [params]);

  return [query, setQuery];
}

export const useUrlQueryKey = (
  key: string,
  defaultValue: string | null = null,
): [string | null, (value: any) => void] => {
  const [query, setQuery] = useUrlQuery();

  let value = defaultValue;
  if (query && query[key]) {
    value = query[key];
  }

  const setValue = useCallback(
    (value) => {
      const q = {
        ...(query || {}),
        [key]: value,
      };
      setQuery(q);
    },
    [key, setQuery],
  );

  return [value, setValue];
};
