import { useResources } from '@/features/resources/resources.provider';
import { InputField } from '@/features/ui/form/input-field.component';
import { Frame } from '@/features/ui/frame/frame';
import { useConfirm } from '@/features/ui/modal/modal.hook';
import { SaveCustomerInput } from '@/graphql/generated/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { ComponentType, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Grid } from 'semantic-ui-react';
import * as styles from '../../../styles/Edit.module.scss';
import {
  useCreateCustomerMutation,
  useCustomerQuery,
  useSoftDeleteCustomerMutation,
  useUpdateCustomerMutation,
} from './edit.generated';
import schema from './schema';

export const Edit: ComponentType = () => {
  const navigate = useNavigate();
  const { customerId } = useParams();

  const { refresh } = useResources();

  const [modal, { ask }] = useConfirm((id: any) => {
    alert('Delete function to implement');
  });

  const { data, loading } = useCustomerQuery({
    variables: { id: customerId },
    skip: customerId === undefined,
    fetchPolicy: 'network-only',
  });
  const customer = data?.customer;

  const { control, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const [create] = useCreateCustomerMutation();
  const [update] = useUpdateCustomerMutation();
  const [softDelete] = useSoftDeleteCustomerMutation();

  useEffect(() => {
    if (customer) {
      reset(customer as any);
    }
  }, [customer]);

  const onSubmit: SubmitHandler<SaveCustomerInput> = async (input) => {
    if (customerId) {
      try {
        await update({ variables: { id: customerId, input } });
        await refresh();
        toast.success('Customer successfully updated!');
      } catch (err) {
        toast.error('An error occurred :(  ' + err);
      }
    } else {
      try {
        const result = await create({ variables: { input } });
        if (result.data) {
          await refresh();
          navigate(`/customers/${result.data.createCustomer.id}/edit`);
          toast.success('New customer successfully saved!');
        }
      } catch (err) {
        toast.error('An error occurred :(  ' + err);
      }
    }
  };

  const handleSoftDelete = async (id: string | string[] | undefined) => {
    try {
      await softDelete({ variables: { id: id } });
      toast.success('Customer successfully deleted!');
    } catch (err) {
      toast.error('An error occurred :(  ' + err);
    }
  };

  return (
    <>
      <Frame.TitleBar>
        <Frame.Title>
          {customerId ? 'Edit Customer' : 'Create Customer'}
        </Frame.Title>
        <Frame.Actions>
          <Button basic color="red" onClick={() => navigate('/customers')}>
            {customerId && <>Close</>}
            {!customerId && <>Cancel</>}
          </Button>
        </Frame.Actions>
      </Frame.TitleBar>
      <Frame.Content loading={loading}>
        <Form
          className={styles.form}
          onSubmit={handleSubmit(onSubmit)}
          error={!formState.isValid}
        >
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Controller
                  name="customerNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      type="number"
                      label="Customer number"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <Controller
                  name="firstName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      label="First name"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <Controller
                  name="lastName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      label="Last name"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <Controller
                  name="companyName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      label="Company name"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />
                <Controller
                  name="contactEmail"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      label="Email"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <Controller
                  name="phone"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      label="Phone"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <Controller
                  name="street"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      label="Street"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <Controller
                  name="postalCode"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      label="Postal code"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <Controller
                  name="city"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      label="City"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <Controller
                  name="country"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      label="Country"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <Controller
                  name="slugs"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      label="Slugs"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <Controller
                  name="amountPerHour"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      label="Amount per hour"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Button.Group>
            <Button type="submit" color="green">
              Save
            </Button>
            {/* {id && (
              <Button
                type="button"
                color="red"
                basic
                onClick={() =>
                  ask(
                    `Are you sure you want to delete customer '${customer?.label}'?`
                  )
                }
              >
                Delete
              </Button>
            )} */}
          </Button.Group>
          {modal}
        </Form>
      </Frame.Content>
    </>
  );
};
