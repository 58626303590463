import { ComponentType } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { Button, Form, Input, Message, TextArea } from 'semantic-ui-react';

interface InputFieldProps {
  type?: string;
  label?: string;
  placeholder?: string;
  fieldState: ControllerFieldState;
  field: ControllerRenderProps<any, any>;
  onClear?: () => void;
  textArea?: boolean;
}

export const InputField: ComponentType<InputFieldProps> = (props) => {
  const {
    label,
    field,
    fieldState,
    type = 'text',
    onClear,
    textArea,
    placeholder,
  } = props;
  return (
    <Form.Field error={!!fieldState.error}>
      {label && <label>{label}</label>}
      {textArea && (
        <TextArea
          placeholder={placeholder}
          {...field}
          value={field.value === null ? '' : field.value}
        />
      )}
      {!textArea && (
        <Input
          placeholder={placeholder}
          action={onClear ? <Button icon="x" onClick={onClear} /> : undefined}
          type={type}
          {...field}
          value={field.value === null ? '' : field.value}
        />
      )}
      {fieldState.error && (
        <Message error>
          <p>{fieldState.error?.message}</p>
        </Message>
      )}
    </Form.Field>
  );
};
