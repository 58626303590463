export const menuItems: {
  href: string;
  title: string;
  new?: boolean;
  onlyAdmins?: boolean;
}[] = [
  {
    href: '/toggle',
    title: 'Toggle Moggle',
    new: false,
  },

  {
    href: '/timelogs',
    title: 'Timelogs',
    new: false,
  },
  {
    href: '/daylogs',
    title: 'Daylogs',
    new: false,
  },
  { href: '/issues', title: 'Issues' },
  {
    href: '/projects',
    title: 'Projects',
    onlyAdmins: true,
  },
  {
    href: '/customers',
    title: 'Customers',
    onlyAdmins: true,
  },
  {
    href: '/invoices',
    title: 'Invoices',
    onlyAdmins: true,
    new: false,
  },
  {
    href: '/products',
    title: 'Products',
    onlyAdmins: true,
    new: false,
  },

  { href: '/services', title: 'Services', new: false, onlyAdmins: true },

  { href: '/tags', title: 'Tags', onlyAdmins: true },
  {
    href: '/users',
    title: 'Users',
    onlyAdmins: true,
  },
];

export const toasterDuration = 2000;
