import { Profile } from '@/features/profile/profile.context';
import { useResources } from '@/features/resources/resources.provider';
import { InputField } from '@/features/ui/form/input-field.component';
import { SelectField } from '@/features/ui/form/select-field.component';
import { ToggleField } from '@/features/ui/form/toggle-field.component';
import { Frame } from '@/features/ui/frame/frame';
import { useConfirm } from '@/features/ui/modal/modal.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'semantic-ui-react';
import { IssueInput } from '../../../graphql/generated/types';
import * as styles from '../../../styles/Edit.module.scss';
import {
  useCreateIssueMutation,
  useDeleteIssueMutation,
  useIssueQuery,
  useUpdateIssueMutation,
} from './edit.generated';
import schema from './schema';

export const Edit = () => {
  const navigate = useNavigate();
  const { projects } = useResources();
  const { id } = useParams();
  const profile = Profile.use();
  const { loading, data } = useIssueQuery({
    variables: { id },
    skip: id === undefined,
    fetchPolicy: 'network-only',
  });
  const issue = data?.issue;

  const [modal, { ask }] = useConfirm((id: any) => {
    alert('Delete function to implement');
  });

  const { control, handleSubmit, reset, formState, setValue, watch } =
    useForm<IssueInput>({
      shouldUnregister: true,
      resolver: yupResolver(schema),
      defaultValues: {
        billable: true,
      },
    });

  const [create] = useCreateIssueMutation();
  const [update] = useUpdateIssueMutation();
  const [deleteIssue] = useDeleteIssueMutation();

  useEffect(() => {
    if (issue) {
      reset(issue);
    }
  }, [issue]);

  const onSubmit: SubmitHandler<IssueInput> = async (input) => {
    if (id) {
      try {
        await update({ variables: { id, input } });
        toast.success('Issue successfully updated!');
      } catch (err) {
        toast.error('An error occurred :(  ' + err);
      }
    } else {
      try {
        const result = await create({ variables: { input } });
        if (result.data) {
          navigate(`/issues/${result.data.createIssue.id}/edit`);
          toast.success('New issue successfully saved!');
        }
      } catch (err) {
        toast.error('An error occurred :(  ' + err);
      }
    }
  };

  const [deleteModal, { ask: askDelete }] = useConfirm(async () => {
    if (id) {
      try {
        await deleteIssue({ variables: { id } });
        toast.success('The issue has been deleted');
        navigate('/issues');
      } catch (e) {
        toast.error(
          'An error occured while deleting this issue. Perhaps there are already logged timelogs?',
        );
      }
    }
  });

  return (
    <>
      {deleteModal}
      <Frame.TitleBar>
        <Frame.Title>{id ? 'Edit Issue' : 'Create Issue'}</Frame.Title>
        <Frame.Actions>
          {profile?.isAdmin && (
            <Button
              color="red"
              onClick={() =>
                askDelete('Do you really want to delete this issue?')
              }
            >
              Delete
            </Button>
          )}
          <Button basic color="black" onClick={() => navigate('/issues')}>
            {id && <>Close</>}
            {!id && <>Cancel</>}
          </Button>
        </Frame.Actions>
      </Frame.TitleBar>
      <Frame.Content loading={loading}>
        <Form
          className={styles.form}
          onSubmit={handleSubmit(onSubmit)}
          error={!formState.isValid}
        >
          <Controller
            name="ticketId"
            control={control}
            render={({ field, fieldState }) => (
              <InputField
                label="Ticket id"
                field={field}
                fieldState={fieldState}
              />
            )}
          />

          <Controller
            name="title"
            control={control}
            render={({ field, fieldState }) => (
              <InputField label="Title" field={field} fieldState={fieldState} />
            )}
          />

          <Controller
            name="projectId"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                nullable
                searchable
                setValue={setValue}
                options={projects.map((p) => ({
                  text: `${p.title} (${p.customer.companyName})`,
                  value: p.id,
                }))}
                label="Project"
                field={field}
                fieldState={fieldState}
              />
            )}
          />

          <Controller
            name="billable"
            control={control}
            render={({ field, fieldState }) => (
              <ToggleField
                label="Billable"
                field={field}
                fieldState={fieldState}
              />
            )}
          />

          <Controller
            name="estimation"
            control={control}
            render={({ field, fieldState }) => (
              <InputField
                type="number"
                label="Estimation"
                field={field}
                fieldState={fieldState}
              />
            )}
          />

          <Controller
            name="fixedAmount"
            control={control}
            render={({ field, fieldState }) => (
              <InputField
                type="number"
                label="Fixed Amount"
                field={field}
                fieldState={fieldState}
              />
            )}
          />

          <Button.Group>
            <Button type="submit" color="green">
              Save
            </Button>
            {/* {id && (
              <Button
                type="button"
                color="red"
                basic
                onClick={() =>
                  ask(`Are you sure you want to delete this project?`)
                }
              >
                Delete
              </Button>
            )} */}
          </Button.Group>
          {modal}
        </Form>
      </Frame.Content>
    </>
  );
};
