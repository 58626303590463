import { useResources } from '@/features/resources/resources.provider';
import { Frame } from '@/features/ui/frame/frame';
import { ComponentType, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Dimmer, Loader, Table, TableRow } from 'semantic-ui-react';

export const List: ComponentType = () => {
  let navigate = useNavigate();
  const { users, loading } = useResources();

  const onCreate = useCallback(() => {
    navigate('/users/create');
  }, []);

  return (
    <>
      <Frame.TitleBar>
        <Frame.Title>Users</Frame.Title>
        <Frame.Actions>
          <Button basic color="green" onClick={onCreate}>
            New User
          </Button>
        </Frame.Actions>
      </Frame.TitleBar>

      <Frame.Content>
        {loading && (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}
        {!loading && (
          <>
            {users.length > 0 ? (
              <Table basic="very" size="small">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Initials</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>ID</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {users.map((user) => (
                    <TableRow
                      key={user.id}
                      className={!user.active ? 'inactive' : undefined}
                    >
                      <Table.Cell>
                        <Link to={`/users/${user.id}/edit`}>{user.label}</Link>
                        {!user.active && (
                          <>
                            {' '}
                            <em>inactive</em>
                          </>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <Link to={`/users/${user.id}/edit`}>{user.initials || 'N/A'}</Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Link to={`/users/${user.id}/edit`}>{user.email}</Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Link to={`/users/${user.id}/edit`}>{user.id}</Link>
                      </Table.Cell>
                    </TableRow>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <h2>
                Oops, it seems like you have no projects recorded in the
                system...
              </h2>
            )}
          </>
        )}
      </Frame.Content>
    </>
  );
};
