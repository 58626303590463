import { boolean, number, object, string } from 'yup';

export default object({
  ticketId: string().required(),
  title: string().nullable(),
  estimation: number().integer().nullable(),
  projectId: string().required(),
  billable: boolean().required(),
  fixedAmount: number().integer().nullable(),
});
