import { formatISO, parseISO } from 'date-fns';
import React, { ComponentType, useMemo } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  UseFormSetValue,
} from 'react-hook-form';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { Form, Message } from 'semantic-ui-react';

interface DateFieldProps {
  label?: string;
  fieldState: ControllerFieldState;
  field: ControllerRenderProps<any, any>;
  setValue: UseFormSetValue<any>;
}

export const DateField: ComponentType<DateFieldProps> = (props) => {
  const { label, field, fieldState, setValue } = props;

  const value = useMemo(() => {
    if (field.value) {
      return parseISO(field.value);
    }
    return undefined;
  }, [field.value]);

  return (
    <Form.Field error={!!fieldState.error}>
      {label && <label>{label}</label>}
      <SemanticDatepicker
        locale="de-DE"
        {...field}
        value={value}
        onChange={(_, data) => {
          setValue(
            field.name,
            data.value ? formatISO(data.value as Date) : null
          );
        }}
      />
      {fieldState.error && (
        <Message error>
          <p>{fieldState.error?.message}</p>
        </Message>
      )}
    </Form.Field>
  );
};
