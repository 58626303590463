import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import { ProjectListDataFragmentDoc } from '../projects/list/list.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MogglesQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input']>;
}>;


export type MogglesQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', id: any, projectNumber?: number | null, title: string, slugs?: string | null, timeSpent: number, amountPerHour?: number | null, status: Types.ProjectStatus, timelogs: Array<{ __typename?: 'Timelog', id: any, date: any, duration?: number | null, description?: string | null }>, openTimelog?: { __typename?: 'Timelog', id: any, date: any } | null, customer: { __typename?: 'Customer', id: any, firstName?: string | null, lastName?: string | null, companyName?: string | null, contactEmail: string, slugs: string, amountPerHour?: number | null } }> };

export type UpdateProjectTogglesMutationVariables = Types.Exact<{
  input: Types.SettingsInput;
}>;


export type UpdateProjectTogglesMutation = { __typename?: 'Mutation', updateSettings: { __typename?: 'User', id: any } };

export type StartToggleMutationVariables = Types.Exact<{
  input: Types.StartToggleInput;
}>;


export type StartToggleMutation = { __typename?: 'Mutation', startToggle: { __typename?: 'Timelog', id: any, date: any } };

export type StopToggleMutationVariables = Types.Exact<{
  input: Types.StopToggleInput;
}>;


export type StopToggleMutation = { __typename?: 'Mutation', stopToggle: { __typename?: 'Timelog', id: any, date: any } };


export const MogglesDocument = gql`
    query moggles($ids: [UUID!]) {
  projects(ids: $ids) {
    ...ProjectListData
    timelogs {
      id
      date
      duration
      description
    }
    openTimelog {
      id
      date
    }
  }
}
    ${ProjectListDataFragmentDoc}`;

/**
 * __useMogglesQuery__
 *
 * To run a query within a React component, call `useMogglesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMogglesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMogglesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMogglesQuery(baseOptions?: Apollo.QueryHookOptions<MogglesQuery, MogglesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MogglesQuery, MogglesQueryVariables>(MogglesDocument, options);
      }
export function useMogglesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MogglesQuery, MogglesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MogglesQuery, MogglesQueryVariables>(MogglesDocument, options);
        }
export function useMogglesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MogglesQuery, MogglesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MogglesQuery, MogglesQueryVariables>(MogglesDocument, options);
        }
export type MogglesQueryHookResult = ReturnType<typeof useMogglesQuery>;
export type MogglesLazyQueryHookResult = ReturnType<typeof useMogglesLazyQuery>;
export type MogglesSuspenseQueryHookResult = ReturnType<typeof useMogglesSuspenseQuery>;
export type MogglesQueryResult = Apollo.QueryResult<MogglesQuery, MogglesQueryVariables>;
export const UpdateProjectTogglesDocument = gql`
    mutation updateProjectToggles($input: SettingsInput!) {
  updateSettings(input: $input) {
    id
  }
}
    `;
export type UpdateProjectTogglesMutationFn = Apollo.MutationFunction<UpdateProjectTogglesMutation, UpdateProjectTogglesMutationVariables>;

/**
 * __useUpdateProjectTogglesMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTogglesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTogglesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTogglesMutation, { data, loading, error }] = useUpdateProjectTogglesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectTogglesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectTogglesMutation, UpdateProjectTogglesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectTogglesMutation, UpdateProjectTogglesMutationVariables>(UpdateProjectTogglesDocument, options);
      }
export type UpdateProjectTogglesMutationHookResult = ReturnType<typeof useUpdateProjectTogglesMutation>;
export type UpdateProjectTogglesMutationResult = Apollo.MutationResult<UpdateProjectTogglesMutation>;
export type UpdateProjectTogglesMutationOptions = Apollo.BaseMutationOptions<UpdateProjectTogglesMutation, UpdateProjectTogglesMutationVariables>;
export const StartToggleDocument = gql`
    mutation startToggle($input: StartToggleInput!) {
  startToggle(input: $input) {
    id
    date
  }
}
    `;
export type StartToggleMutationFn = Apollo.MutationFunction<StartToggleMutation, StartToggleMutationVariables>;

/**
 * __useStartToggleMutation__
 *
 * To run a mutation, you first call `useStartToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startToggleMutation, { data, loading, error }] = useStartToggleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartToggleMutation(baseOptions?: Apollo.MutationHookOptions<StartToggleMutation, StartToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartToggleMutation, StartToggleMutationVariables>(StartToggleDocument, options);
      }
export type StartToggleMutationHookResult = ReturnType<typeof useStartToggleMutation>;
export type StartToggleMutationResult = Apollo.MutationResult<StartToggleMutation>;
export type StartToggleMutationOptions = Apollo.BaseMutationOptions<StartToggleMutation, StartToggleMutationVariables>;
export const StopToggleDocument = gql`
    mutation stopToggle($input: StopToggleInput!) {
  stopToggle(input: $input) {
    id
    date
  }
}
    `;
export type StopToggleMutationFn = Apollo.MutationFunction<StopToggleMutation, StopToggleMutationVariables>;

/**
 * __useStopToggleMutation__
 *
 * To run a mutation, you first call `useStopToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopToggleMutation, { data, loading, error }] = useStopToggleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStopToggleMutation(baseOptions?: Apollo.MutationHookOptions<StopToggleMutation, StopToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopToggleMutation, StopToggleMutationVariables>(StopToggleDocument, options);
      }
export type StopToggleMutationHookResult = ReturnType<typeof useStopToggleMutation>;
export type StopToggleMutationResult = Apollo.MutationResult<StopToggleMutation>;
export type StopToggleMutationOptions = Apollo.BaseMutationOptions<StopToggleMutation, StopToggleMutationVariables>;