import { Profile } from '@/features/profile/profile.context';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { menuItems } from '../../../constants';
import { NewFeature } from '../new-feature/new-feature.component';
import * as styles from './navigation.module.scss';

export const Navigation = () => {
  const profile = Profile.use();
  const location = useLocation();

  const items = useMemo(() => {
    if (!profile) {
      return [];
    }
    if (!profile.isAdmin) {
      return menuItems.filter((i) => !i.onlyAdmins);
    }
    return menuItems;
  }, [profile]);
  return (
    <div className={styles.sidebar}>
      <Menu pointing secondary vertical>
        <Menu.Item
          active={location.pathname === '/dashboard'}
          name="dashboard"
          as={Link}
          to="/dashboard"
        >
          Dashboard
        </Menu.Item>
        <div className={styles.hr} />

        {items.map((menuItem, idx) => (
          <Menu.Item
            key={idx}
            name={menuItem.title}
            as={Link}
            to={menuItem.href}
            active={location.pathname === menuItem.href}
          >
            {menuItem.new && <NewFeature />}
            {menuItem.title}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};
