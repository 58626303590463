import { InputField } from '@/features/ui/form/input-field.component';
import { ComponentType } from 'react';
import { Controller } from 'react-hook-form';
import { Button, Dimmer, Form, Loader, Message } from 'semantic-ui-react';
import { useLoginForm } from './login.form';

export const Login: ComponentType = () => {
  const {
    loading,
    onSubmit,
    control,
    formState: { errors },
    serverError,
    setServerError,
  } = useLoginForm();

  return (
    <Form
      error={!!serverError}
      className="centered"
      style={{ width: 300, margin: '150px auto' }}
      onSubmit={(e) => e.preventDefault()}
    >
      {loading && (
        <Dimmer>
          <Loader />
        </Dimmer>
      )}
      <h1>Tyrion</h1>
      <Controller
        name="email"
        render={({ field, fieldState }) => (
          <InputField label="Email" field={field} fieldState={fieldState} />
        )}
        control={control}
      />
      <Controller
        name="password"
        render={({ field, fieldState }) => (
          <InputField
            label="Password"
            type="password"
            field={field}
            fieldState={fieldState}
          />
        )}
        control={control}
      />

      {serverError && <Message error>{serverError}</Message>}

      <div>
        <Button fluid type="submit" onClick={onSubmit} color="black">
          Anmelden
        </Button>
      </div>
    </Form>
  );
};
