import { ComponentType } from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';

interface BatchActionsProps {
  label: string;
  actions: {
    label: string;
    onClick: () => void;
  }[];
}

export const BatchActions: ComponentType<BatchActionsProps> = (props) => {
  const { actions, label } = props;
  return (
    <Menu vertical>
      <Dropdown item text={label}>
        <Dropdown.Menu>
          {actions.map((action, idx) => (
            <Dropdown.Item onClick={action.onClick} key={idx}>
              {action.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  );
};
