import { ApolloProvider } from '@apollo/client';
import { ComponentType } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Dimmer, Loader } from 'semantic-ui-react';
import { toasterDuration } from './constants';
import { AuthGuard } from './features/auth-guard/auth-guard.component';
import { Customers } from './features/customers/customers.component';
import { Dashboard } from './features/dashboard/dashboard.component';
import { Invoices } from './features/invoices/invoices.component';
import { Issues } from './features/issues/issues.component';
import { Login } from './features/login/login.component';
import { Products } from './features/products/products.component';
import { Profile, ProfileStatus } from './features/profile/profile.context';
import { Projects } from './features/projects/projects.component';
import { ResourceProvider } from './features/resources/resources.provider';
import { SelectionProvider } from './features/selection/selection';
import { Services } from './features/services/services.component';
import { Tags } from './features/tags/tags.component';
import { Timelogs } from './features/timelogs/timelogs.component';
import { Daylogs } from './features/daylogs/daylogs.component';
import { ToggleMoggle } from './features/toggle-moggle/toggle-moggle.component';
import { Frame } from './features/ui/frame/frame';
import { Users } from './features/users/users.component';
import { client } from './graphql/client';

const AwaitProfile: ComponentType = (props) => {
  const status = Profile.useStatus();

  return status === ProfileStatus.LOADING ? (
    <Dimmer>
      <Loader />
    </Dimmer>
  ) : (
    <>{props.children}</>
  );
};

export const App = () => {
  return (
    <ApolloProvider client={client}>
      <Profile>
        <AwaitProfile>
          <AuthGuard>
            <ResourceProvider>
              <SelectionProvider>
                <Frame.MainFrame>
                  <Frame.Sidebar />
                  <Frame.RightSide>
                    <Routes>
                      <Route
                        path="/"
                        element={<Navigate to="/dashboard" replace />}
                      />
                      <Route path="/login" element={<Login />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/toggle/*" element={<ToggleMoggle />} />
                      <Route path="/customers/*" element={<Customers />} />
                      <Route path="/invoices/*" element={<Invoices />} />
                      <Route path="/projects/*" element={<Projects />} />
                      <Route path="/products/*" element={<Products />} />
                      <Route path="/timelogs/*" element={<Timelogs />} />
                      <Route path="/daylogs/*" element={<Daylogs />} />
                      <Route path="/services/*" element={<Services />} />
                      <Route path="/users/*" element={<Users />} />
                      <Route path="/tags/*" element={<Tags />} />
                      <Route path="/issues/*" element={<Issues />} />
                    </Routes>
                  </Frame.RightSide>
                </Frame.MainFrame>
              </SelectionProvider>
            </ResourceProvider>
          </AuthGuard>

          <ToastContainer
            position="bottom-right"
            autoClose={toasterDuration}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </AwaitProfile>
      </Profile>
    </ApolloProvider>
  );
};
