// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gsIG3eh_lV1B2tb_IP2l{display:flex;border-bottom:1px solid silver;margin-bottom:1rem;padding-bottom:1rem}.gsIG3eh_lV1B2tb_IP2l .cYUyzR3Ti2MSAynEHL7g{color:silver}.gsIG3eh_lV1B2tb_IP2l .flqz5PWVewi_V641GdHv{margin-right:1rem}.gsIG3eh_lV1B2tb_IP2l .field{flex:1}`, "",{"version":3,"sources":["webpack://./src/features/ui/checkbox-field/checkbox-field.module.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,8BAAA,CACA,kBAAA,CACA,mBAAA,CAEA,4CACE,YCDe,CDGjB,4CACE,iBAAA,CAEF,6BACE,MAAA","sourcesContent":["@import 'styles/variables';\n\n.host {\n  display: flex;\n  border-bottom: 1px solid $color-light-grey;\n  margin-bottom: 1rem;\n  padding-bottom: 1rem;\n\n  .untouchedLabel {\n    color: $color-light-grey;\n  }\n  .checkbox {\n    margin-right: 1rem;\n  }\n  :global(.field) {\n    flex: 1;\n  }\n}\n","$color-white: #ffffff;\n$color-black: #000000;\n$color-magenta: #540d6e;\n$color-x: #38ff73;\n$color-neon-yellow: #c8ff00;\n$color-blue: #101e6e;\n$color-blue-light: #85b7d9;\n$color-lighter-grey: #f3fcf0;\n$color-light-grey: #c0c0c0;\n$color-medium-grey: #989788;\n$color-dark-grey: #313131;\n\n$color-brand: $color-neon-yellow;\n$color-accent: $color-neon-yellow;\n$color-highlight: $color-magenta;\n$color-primary: $color-blue;\n$color-secondary: $color-light-grey;\n$color-sidebar-bg: $color-light-grey;\n\n$font-size-base: 10px;\n$font-size-text: 1.6rem;\n\n$height-titlebar: 4rem;\n$width-sidebar: 210px;\n"],"sourceRoot":""}]);
// Exports
export var host = `gsIG3eh_lV1B2tb_IP2l`;
export var untouchedLabel = `cYUyzR3Ti2MSAynEHL7g`;
export var checkbox = `flqz5PWVewi_V641GdHv`;
export default ___CSS_LOADER_EXPORT___;
