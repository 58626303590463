import { useResources } from '@/features/resources/resources.provider';
import { InputField } from '@/features/ui/form/input-field.component';
import { Frame } from '@/features/ui/frame/frame';
import { useConfirm } from '@/features/ui/modal/modal.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'semantic-ui-react';
import { TagInput } from '../../../graphql/generated/types';
import * as styles from '../../../styles/Edit.module.scss';
import {
  useDeleteTagMutation,
  useSaveTagMutation,
  useTagQuery,
} from './edit.generated';

import schema from './schema';

export const Edit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [open, setOpen] = useState(false);

  // pre-populate data when editing an existing item
  const { loading, error, data } = useTagQuery({
    variables: { id },
    skip: id === undefined,
    fetchPolicy: 'network-only',
  });
  const tag = data?.tag;

  const { refresh } = useResources();

  const { control, handleSubmit, reset, formState, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const [save] = useSaveTagMutation();
  const [deleteTag] = useDeleteTagMutation();
  const onDeleteTag = useCallback(() => {
    if (tag?.id) {
      deleteTag({ variables: { id: tag.id } });
    }
  }, [tag]);

  const onSubmit: SubmitHandler<TagInput> = async (input) => {
    if (id) {
      try {
        await save({ variables: { id, input } });
        await refresh();
        toast.success('Tag successfully updated!');
      } catch (err) {
        toast.error('An error occurred :(  ' + err);
      }
    } else {
      try {
        const result = await save({ variables: { input } });
        if (result.data) {
          navigate(`/tags/${result.data.saveTag.id}/edit`);
          await refresh();
          toast.success('New tag successfully saved!');
        }
      } catch (err) {
        toast.error('An error occurred :(  ' + err);
      }
    }
  };

  useEffect(() => {
    if (tag) {
      reset(tag);
    }
  }, [tag]);

  const [modal, { ask: askDelete }] = useConfirm(onDeleteTag);

  return (
    <>
      {modal}
      <Frame.TitleBar>
        <Frame.Title>{id ? 'Edit Tag' : 'Create Tag'}</Frame.Title>
        <Frame.Actions>
          {id && (
            <Button
              color="red"
              onClick={() =>
                askDelete(`Do you want to delete the Tag "${tag?.name}"?`)
              }
            >
              Delete
            </Button>
          )}
          <Button basic color="red" onClick={() => navigate('/tags')}>
            {id && <>Close</>}
            {!id && <>Cancel</>}
          </Button>
        </Frame.Actions>
      </Frame.TitleBar>
      <Frame.Content>
        <Form
          className={styles.form}
          onSubmit={handleSubmit(onSubmit)}
          error={!formState.isValid}
        >
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <InputField label="Name" field={field} fieldState={fieldState} />
            )}
          />

          <Controller
            name="context"
            control={control}
            render={({ field, fieldState }) => (
              <InputField
                label="Context"
                field={field}
                fieldState={fieldState}
              />
            )}
          />

          <Button.Group>
            <Button type="submit" color="green">
              Save
            </Button>
          </Button.Group>
          {modal}
        </Form>
      </Frame.Content>
    </>
  );
};
