import React, { ComponentType, useMemo } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  UseFormSetValue,
} from 'react-hook-form';
import {
  DropdownItemProps,
  DropdownOnSearchChangeData,
  Form,
  Message,
  Select,
} from 'semantic-ui-react';

interface SelectFieldProps {
  multiple?: boolean;
  searchable?: boolean;
  label?: string;
  nullable?: boolean;
  options: DropdownItemProps[];
  fieldState: ControllerFieldState;
  field: ControllerRenderProps<any, any>;
  setValue: UseFormSetValue<any>;
  onAdd?: (value: any) => void;
  fluid?: boolean;
  onSearchChange?: (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownOnSearchChangeData
  ) => void;
}

export const SelectField: ComponentType<SelectFieldProps> = (props) => {
  const {
    label,
    field,
    searchable,
    fieldState,
    multiple,
    options,
    setValue,
    nullable,
    fluid,
    onAdd,
    onSearchChange,
  } = props;

  const allOptions = useMemo(() => {
    return options;
  }, [options, nullable]);

  return (
    <Form.Field error={!!fieldState.error}>
      {label && <label>{label}</label>}
      <Select
        fullTextSearch
        onSearchChange={onSearchChange}
        multiple={multiple}
        search={searchable}
        clearable={nullable}
        options={allOptions}
        value={field.value}
        defaultValue={field.value}
        onChange={(_, data) => {
          setValue(field.name, data.value ? data.value : null);
        }}
        selectOnBlur={false}
        selectOnNavigation
        closeOnChange
        allowAdditions={onAdd !== undefined}
        onAddItem={onAdd ? (_, data) => onAdd(data.value) : undefined}
      />
      {fieldState.error && (
        <Message error>
          <p>{fieldState.error?.message}</p>
        </Message>
      )}
    </Form.Field>
  );
};
