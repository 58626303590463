import { InputField } from '@/features/ui/form/input-field.component';
import { Frame } from '@/features/ui/frame/frame';
import { ComponentType, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'semantic-ui-react';
import * as styles from '../../../styles/Edit.module.scss';
import {
  useCreateUserMutation,
  useToggleActiveMutation,
  useUpdateUserMutation,
  useUserQuery,
} from './edit.generated';

export const Edit: ComponentType = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { loading, error, data } = useUserQuery({
    variables: { id },
    skip: id === undefined,
    fetchPolicy: 'network-only',
  });

  const user = data?.user;

  const form = useForm();

  useEffect(() => {
    if (user) {
      form.reset({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      });
    }
  }, [user]);

  const onSubmit: SubmitHandler<any> = async (input) => {
    if (id) {
      try {
        const updatedUser = await update({ variables: { id, input } });
        toast.success('User successfully updated!');
      } catch (err) {
        toast.error('An error occurred :(  ' + err);
      }
    } else {
      try {
        const newUser = await create({ variables: { input } });
        navigate(`/users/${newUser?.data?.createUser.id}/edit`);
        toast.success('New user successfully saved!');
      } catch (err) {
        toast.error('An error occurred :(  ' + err);
      }
    }
  };

  const [create] = useCreateUserMutation();
  const [update] = useUpdateUserMutation();
  const [toggle] = useToggleActiveMutation();

  return (
    <>
      <Frame.TitleBar>
        <Frame.Title>{id ? 'Edit User' : 'Create User'}</Frame.Title>
        <Frame.Actions>
          <Button
            basic
            onClick={() => {
              if (id) {
                toggle({ variables: { id } });
              }
            }}
          >
            {user?.active ? 'Deactivate' : 'Activate'}
          </Button>
          <Button basic color="red" onClick={() => navigate('/users')}>
            Cancel
          </Button>
        </Frame.Actions>
      </Frame.TitleBar>
      <Frame.Content>
        {!user?.active && <p>User is not active</p>}
        <Form className={styles.form} error={!form.formState.isValid}>
          <Controller
            name="firstName"
            control={form.control}
            render={({ field, fieldState }) => (
              <InputField
                label="First name"
                field={field}
                fieldState={fieldState}
              />
            )}
          />

          <Controller
            name="lastName"
            control={form.control}
            render={({ field, fieldState }) => (
              <InputField
                label="Last name"
                field={field}
                fieldState={fieldState}
              />
            )}
          />

          <Controller
            name="initials"
            control={form.control}
            render={({ field, fieldState }) => (
              <InputField
                label="Initials"
                field={field}
                fieldState={fieldState}
              />
            )}
          />

          <Controller
            name="email"
            control={form.control}
            render={({ field, fieldState }) => (
              <InputField label="Email" field={field} fieldState={fieldState} />
            )}
          />

          <Controller
            name="password"
            control={form.control}
            render={({ field, fieldState }) => (
              <InputField
                label="Passwort neu vergeben"
                field={field}
                fieldState={fieldState}
              />
            )}
          />

          <Button onClick={form.handleSubmit(onSubmit)} color="green">
            Save
          </Button>
        </Form>
      </Frame.Content>
    </>
  );
};
