import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProfileDataFragment = { __typename?: 'User', id: any, label: string, firstName: string, lastName: string, email: string, isAdmin: boolean, initials?: string | null, settings: { __typename?: 'Settings', projectToggles?: Array<{ __typename?: 'ProjectToggleEntry', id: any, width: number }> | null } };

export type CurrentProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentProfileQuery = { __typename?: 'Query', profile: { __typename?: 'User', id: any, label: string, firstName: string, lastName: string, email: string, isAdmin: boolean, initials?: string | null, settings: { __typename?: 'Settings', projectToggles?: Array<{ __typename?: 'ProjectToggleEntry', id: any, width: number }> | null } } };

export const ProfileDataFragmentDoc = gql`
    fragment ProfileData on User {
  id
  label
  firstName
  lastName
  email
  isAdmin
  initials
  settings {
    projectToggles {
      id
      width
    }
  }
}
    `;
export const CurrentProfileDocument = gql`
    query currentProfile {
  profile {
    ...ProfileData
  }
}
    ${ProfileDataFragmentDoc}`;

/**
 * __useCurrentProfileQuery__
 *
 * To run a query within a React component, call `useCurrentProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentProfileQuery(baseOptions?: Apollo.QueryHookOptions<CurrentProfileQuery, CurrentProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentProfileQuery, CurrentProfileQueryVariables>(CurrentProfileDocument, options);
      }
export function useCurrentProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentProfileQuery, CurrentProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentProfileQuery, CurrentProfileQueryVariables>(CurrentProfileDocument, options);
        }
export function useCurrentProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentProfileQuery, CurrentProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentProfileQuery, CurrentProfileQueryVariables>(CurrentProfileDocument, options);
        }
export type CurrentProfileQueryHookResult = ReturnType<typeof useCurrentProfileQuery>;
export type CurrentProfileLazyQueryHookResult = ReturnType<typeof useCurrentProfileLazyQuery>;
export type CurrentProfileSuspenseQueryHookResult = ReturnType<typeof useCurrentProfileSuspenseQuery>;
export type CurrentProfileQueryResult = Apollo.QueryResult<CurrentProfileQuery, CurrentProfileQueryVariables>;