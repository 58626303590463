import classnames from 'classnames';
import {
  ComponentType,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  UseFormSetValue,
} from 'react-hook-form';
import { Form, Message } from 'semantic-ui-react';
import { DebouncedInput } from '../../debounced-input/debounced-input.component';
import { CharacterBasedLoader } from '../../loader/character-based/loader.component';
import OutsideAlerter from '../../outside-alerter/outside-alerter.component';
import * as styles from './finder-field.module.scss';

interface FinderFieldProps {
  fieldState: ControllerFieldState;
  field: ControllerRenderProps<any, any>;
  label?: ReactNode;
  options: { text: string; value: any }[];
  initialValue?: string;
  setValue: UseFormSetValue<any>;
  onAdd?: (value: any) => void;
  onSearchChange: (value: string) => void;
  loading?: boolean;
}

export const FinderField: ComponentType<FinderFieldProps> = (props) => {
  const {
    fieldState,
    field,
    label,
    options,
    initialValue,
    setValue,
    onAdd,
    onSearchChange,
    loading,
  } = props;
  const [search, setSearch] = useState('');
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | null | undefined>(
    initialValue,
  );
  const [hiddenOverlay, setHiddenOverlay] = useState(initialValue && false);
  const [actualPosition, setActualPosition] = useState(0);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown' && actualPosition < options.length - 1) {
        setActualPosition((prev) => {
          return prev + 1;
        });
      }
      if (event.key === 'ArrowUp' && actualPosition > 0) {
        setActualPosition((prev) => {
          return prev - 1;
        });
      }
      if (event.key === 'Enter') {
        setSelectedValue(options[actualPosition].text);
        setValue(field.name, options[actualPosition].value);
        setOptionsVisible(false);
        setHiddenOverlay(false);
      }
      if (event.key === 'Backspace') {
        setActualPosition(0);
      }
      if (event.key === 'Escape') {
        setOptionsVisible(false);
      } else if (event.key !== 'Enter') {
        setOptionsVisible(true);
      }
    },
    [actualPosition, options],
  );

  useEffect(() => {
    if (search.length >= 3) {
      setTimeout(() => {
        onSearchChange(search);
      }, 400);
    }
  }, [search]);

  useEffect(() => {
    if (search.length === 0) {
      if (initialValue && options.length > 0) {
        setSelectedValue(options[0]?.text);
      }
    }
  }, [options]);

  useEffect(() => {
    if (search.length === 0) {
      onSearchChange('xxxxx');
    }
  }, [search]);

  return (
    <Form.Field error={!!fieldState.error} className={styles.host}>
      {label && <label>{label}</label>}
      <OutsideAlerter onOutsideClick={() => setOptionsVisible(false)}>
        <DebouncedInput
          delay={500}
          onDebouncedChange={(e) => {
            setSearch(e);
          }}
          value={search}
          onClick={() => {
            if (optionsVisible && !search) {
              setOptionsVisible(false);
              setSelectedValue(null);
            }
            if (!optionsVisible) {
              setOptionsVisible(true);
            }
            setHiddenOverlay(true);
          }}
          onKeyDown={(e: any) => handleKeyDown(e)}
        />
        <div
          className={classnames(
            styles.selectedValueOverlay,
            hiddenOverlay && styles.hidden,
          )}
        >
          {selectedValue}
        </div>
        {optionsVisible && (
          <div className={styles.options}>
            {search === '' && (
              <div className={styles.option}>Type to search...</div>
            )}
            {search !== '' && onAdd && (
              <div className={styles.option} onClick={() => onAdd(search)}>
                Add <b>{search}</b>...
              </div>
            )}
            {loading && <CharacterBasedLoader className={styles.option} />}
            {options.map((o, idx) => {
              return (
                <div className={styles.optionCont}>
                  <div
                    className={classnames(
                      styles.option,
                      actualPosition === idx && styles.active,
                    )}
                    onClick={() => {
                      setSelectedValue(o.text);
                      setValue(field.name, o.value);
                      setOptionsVisible(false);
                      setHiddenOverlay(false);
                    }}
                  >
                    {o.text}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </OutsideAlerter>
      {fieldState.error && (
        <Message error>
          <p>{fieldState.error?.message}</p>
        </Message>
      )}
    </Form.Field>
  );
};
