import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';

interface ModalApi {
  open: () => void;
  close: () => void;
}

export type ModalHook = [ReactNode, ModalApi];

type ModalAction = {
  label: string;
  onClick?: () => void;
  primary?: boolean;
  disabled?: boolean;
};

interface ModalOptions {
  title: string;
  content: ReactNode;
  actions: ModalAction[];
}

export function useModal(options: ModalOptions): ModalHook {
  const [isOpen, setIsOpen] = useState(false);

  const modal = useMemo(
    () => (
      <Modal
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        open={isOpen}
        size="small"
      >
        <Modal.Header>{options.title}</Modal.Header>
        <Modal.Content>{options.content}</Modal.Content>
        <Modal.Actions>
          {options.actions.map((action, index) => (
            <Button
              key={index}
              disabled={action.disabled}
              onClick={() => {
                setIsOpen(false);
                if (action.onClick) {
                  action.onClick();
                }
              }}
              basic={!action.primary}
              color={action.primary ? 'green' : undefined}
            >
              {action.label}
            </Button>
          ))}
        </Modal.Actions>
      </Modal>
    ),
    [isOpen, options, options.content, options.actions]
  );

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  return [modal, { open, close }];
}

export function usePrompt(options: ModalOptions): ModalHook {
  const [isOpen, setIsOpen] = useState(false);

  const modal = useMemo(
    () => (
      <Modal
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        open={isOpen}
        size="small"
      >
        <Modal.Header>{options.title}</Modal.Header>
        <Modal.Content>{options.content}</Modal.Content>
        <Modal.Actions>
          {options.actions.map((action, index) => (
            <Button
              key={index}
              onClick={() => {
                setIsOpen(false);
                if (action.onClick) {
                  action.onClick();
                }
              }}
              basic={!action.primary}
              color={action.primary ? 'green' : undefined}
            >
              {action.label}
            </Button>
          ))}
        </Modal.Actions>
      </Modal>
    ),
    [isOpen, options, options.content, options.actions]
  );

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);
  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  return [modal, { open, close }];
}

interface ConfirmApi {
  ask: (question: string, ...args: any[]) => void;
}

type UseConfirmHook = [ReactNode, ConfirmApi];

export function useConfirm(onYes: (...args: any[]) => void): UseConfirmHook {
  const [question, setQuestion] = useState('');
  const [args, setArgs] = useState<any[]>([]);

  const [modal, { open }] = useModal({
    title: 'Confirmation',
    content: question,
    actions: [
      {
        label: 'No',
      },
      {
        label: 'Yes',
        primary: true,
        onClick: () => onYes(args),
      },
    ],
  });

  const ask = useCallback((question: string, ...args: any[]) => {
    setQuestion(question);
    setArgs(args);
    open();
  }, []);

  return [modal, { ask }];
}
