import { ComponentType } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import Switch from 'react-switch';
import { Form, Message } from 'semantic-ui-react';

interface ToggleFieldProps {
  label?: string;
  fieldState: ControllerFieldState;
  field: ControllerRenderProps<any, any>;
}

export const ToggleField: ComponentType<ToggleFieldProps> = (props) => {
  const { label, field, fieldState } = props;
  return (
    <Form.Field error={!!fieldState.error}>
      {label && <label>{label}</label>}

      <Switch
        {...field}
        checked={field.value === null ? false : field.value}
        onColor="#21ba45"
      />

      {fieldState.error && (
        <Message error>
          <p>{fieldState.error?.message}</p>
        </Message>
      )}
    </Form.Field>
  );
};
