import { default as classnames, default as classNames } from 'classnames';
import { ComponentType, useEffect, useState } from 'react';
import * as styles from './loader.module.scss';

interface CharacterBasedLoaderProps {
  className?: string;
  label?: string;
}

export const CharacterBasedLoader: ComponentType<CharacterBasedLoaderProps> = (
  props,
) => {
  const { className, label = 'Loading' } = props;

  const [firstDotVisible, setFirstDotVisible] = useState(false);
  const [secondDotVisible, setSecondDotVisible] = useState(false);
  const [thirdDotVisible, setThirdDotVisible] = useState(false);

  useEffect(() => {
    const interval = setInterval(function () {
      if (!firstDotVisible) {
        setFirstDotVisible(true);
      }
      if (firstDotVisible && !secondDotVisible) {
        setSecondDotVisible(true);
      }
      if (secondDotVisible && !thirdDotVisible) {
        setThirdDotVisible(true);
      }
      if (firstDotVisible && secondDotVisible && thirdDotVisible) {
        setFirstDotVisible(false);
        setSecondDotVisible(false);
        setThirdDotVisible(false);
      }
    }, 400);
    return () => {
      clearInterval(interval);
    };
  }, [firstDotVisible, secondDotVisible, thirdDotVisible]);

  return (
    <div className={classnames(styles.host, className)}>
      {label}
      <span className={classNames(firstDotVisible && styles.visible)}>.</span>
      <span className={classNames(secondDotVisible && styles.visible)}>.</span>
      <span className={classNames(thirdDotVisible && styles.visible)}>.</span>
    </div>
  );
};
