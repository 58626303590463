import { useResources } from '@/features/resources/resources.provider';
import { DebouncedInput } from '@/features/ui/debounced-input/debounced-input.component';
import { useUrlQuery } from '@/features/utils/url-query';
import { ComponentType, useState, useEffect } from 'react';
import { Form, Select } from 'semantic-ui-react';

export interface FilterState {
  query?: string | null;
  projectId?: string | null;
  billable?: string | null;
}

const SEARCH_HISTORY_KEY = 'searchHistory';

export const Filter: ComponentType = (props) => {
  const [urlQuery, updateUrlQuery] = useUrlQuery<FilterState>();
  const { projects } = useResources();
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [currentSearch, setCurrentSearch] = useState<string>('');

  useEffect(() => {
    const storedHistory = localStorage.getItem(SEARCH_HISTORY_KEY);
    if (storedHistory) {
      setSearchHistory(JSON.parse(storedHistory));
    }
  }, []);

  const handleSearchChange = (query: string) => {
    setCurrentSearch(query);
    updateUrlQuery({ ...urlQuery, query });
  };

  const handleSearchSubmit = () => {
    if (currentSearch) {
      const newHistory = [
        currentSearch,
        ...searchHistory.filter((item) => item !== currentSearch),
      ].slice(0, 5);
      setSearchHistory(newHistory);
      localStorage.setItem(SEARCH_HISTORY_KEY, JSON.stringify(newHistory));
    }
  };

  const handleFocus = () => {
    setShowSuggestions(true);
  };

  const handleBlur = () => {
    handleSearchSubmit();
    setTimeout(() => setShowSuggestions(false), 100);
  };

  return (
    <Form>
      <Form.Group style={{ flexWrap: 'wrap' }}>
        <Form.Field>
          <label>Search</label>
          <div style={{ position: 'relative' }}>
            <DebouncedInput
              placeholder="Ticket id, title, ..."
              delay={500}
              clearable
              type="text"
              value={urlQuery.query || ''}
              onDebouncedChange={handleSearchChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {showSuggestions && searchHistory.length > 0 && (
              <div
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  right: 0,
                  background: 'white',
                  border: '1px solid #ccc',
                  zIndex: 1,
                }}
              >
                {searchHistory.map((item, index) => (
                  <div
                    key={index}
                    onMouseDown={() => handleSearchChange(item)}
                    style={{ padding: '8px', cursor: 'pointer' }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            )}
          </div>
        </Form.Field>
        <Form.Field>
          <label>Project</label>
          <Select
            search
            multiple={false}
            clearable
            options={[
              { text: '', value: '' },
              ...projects.map((p) => ({
                text: `${p.title} (${p.customer.companyName})`,
                value: p.id,
              })),
            ]}
            value={urlQuery.projectId || undefined}
            onChange={(_, data) => {
              updateUrlQuery({
                ...urlQuery,
                projectId: data.value as string,
              });
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>Billable</label>
          <Select
            search
            multiple={false}
            clearable
            options={[
              { text: '', value: '' },
              {
                text: 'Yes',
                value: 'true',
              },
              {
                text: 'No',
                value: 'false',
              },
            ]}
            value={urlQuery.billable || ''}
            onChange={(_, data) => {
              updateUrlQuery({
                ...urlQuery,
                billable: data.value as string,
              });
            }}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
