import {
  ComponentType,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface SelectionApi {
  selection: string[];
  setSelection: Dispatch<SetStateAction<string[]>>;
}

export const SelectionContext = createContext<SelectionApi | undefined>(
  undefined
);

export const SelectionProvider: ComponentType = ({ children }) => {
  const [selection, setSelection] = useState<string[]>([]);
  return (
    <SelectionContext.Provider value={{ selection, setSelection }}>
      {children}
    </SelectionContext.Provider>
  );
};
export function useSelection(): SelectionApi {
  const context = useContext(SelectionContext);
  if (!context) {
    throw new Error(
      'Selection Provider not mounted / Selection Context not ready'
    );
  }
  return context;
}
