import { useSelection } from '@/features/selection/selection';
import { WithId } from '@/features/utils/types';
import React, { ReactNode } from 'react';
import { Checkbox, Message, Table as TableView } from 'semantic-ui-react';

interface ColumnDefinition {
  name: string;
  sortable?: boolean;
}
interface TableProps<T> {
  withSelection?: boolean;
  columns: (ColumnDefinition | string)[];
  renderRow: (item: T) => ReactNode;
  items?: T[];
}

export function Table<T extends WithId>(props: TableProps<T>) {
  const { items, columns, renderRow, withSelection } = props;

  const { selection, setSelection } = useSelection();

  if (!items) {
    return <Message>No results :(</Message>;
  }

  return (
    <TableView basic="very">
      <TableView.Header>
        <TableView.Row>
          {withSelection && (
            <TableView.HeaderCell>
              <Checkbox
                checked={
                  items &&
                  items.length > 0 &&
                  items.every((i) => selection.includes(i.id))
                }
                onChange={(e, data) =>
                  setSelection(
                    data.checked ? items?.map((item) => item.id) : []
                  )
                }
              />
            </TableView.HeaderCell>
          )}
          {columns.map((definition, index) => (
            <TableView.HeaderCell key={index}>
              {typeof definition === 'string' ? definition : definition.name}
            </TableView.HeaderCell>
          ))}
        </TableView.Row>
      </TableView.Header>
      <TableView.Body>
        {items &&
          items.map((item, index) => (
            <TableView.Row key={index}>
              {withSelection && (
                <TableView.Cell>
                  <Checkbox
                    onChange={(e, data) => {
                      setSelection((s) =>
                        data.checked
                          ? [...s.filter((id) => id !== item.id), item.id]
                          : s.filter((id) => id !== item.id)
                      );
                    }}
                    checked={selection.includes(item.id)}
                  />
                </TableView.Cell>
              )}

              {renderRow(item)}
            </TableView.Row>
          ))}
      </TableView.Body>
    </TableView>
  );
}
