import { useCreateIssueMutation } from '@/features/issues/edit/edit.generated';
import { useIssuesLazyQuery } from '@/features/issues/list/list.generated';
import { Profile } from '@/features/profile/profile.context';
import { useResources } from '@/features/resources/resources.provider';
import { DateField } from '@/features/ui/form/date-field.component';
import { FinderField } from '@/features/ui/form/finder-field/finder-field.component';
import { InputField } from '@/features/ui/form/input-field.component';
import { SelectField } from '@/features/ui/form/select-field.component';
import { Formatted } from '@/features/ui/formatted/formatted.component';
import { Frame } from '@/features/ui/frame/frame';
import { useConfirm } from '@/features/ui/modal/modal.hook';
import { NewFeature } from '@/features/ui/new-feature/new-feature.component';
import { useDebounce } from '@/features/utils/debounce';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatISO } from 'date-fns';
import parse from 'parse-duration';
import {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Grid } from 'semantic-ui-react';
import { TimelogInput } from '../../../graphql/generated/types';
import * as styles from '../../../styles/Edit.module.scss';
import { useSoftDeleteTimelogsMutation } from '../list/list.generated';
import { useCreateIssueModal } from './create-issue.modal';
import {
  useCreateTimelogMutation,
  useLatestIssuesUsedLazyQuery,
  useLatestTagsUsedLazyQuery,
  useTimelogQuery,
  useUpdateTimelogMutation,
} from './edit.generated';
import schema from './schema';

export const Edit: ComponentType = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const profile = Profile.use();

  const { loading, data } = useTimelogQuery({
    variables: { id },
    skip: id === undefined,
    fetchPolicy: 'network-only',
  });

  const timelog = data?.timelog;

  const { users, tags: unsortedTags, projects } = useResources();
  const [parsedDuration, setParsedDuration] = useState('');
  const debouncedParsedDuration = useDebounce(parsedDuration, 500);

  const [loadLatestIssuesUsed, { data: latestIssuesUsed }] =
    useLatestIssuesUsedLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        userId: profile?.id,
      },
    });

  const [loadLatestTagsUsed, { data: latestTagsUsed }] =
    useLatestTagsUsedLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        userId: profile?.id,
      },
    });

  const [loadIssues, { loading: issuesLoading, data: foundIssues }] =
    useIssuesLazyQuery({
      fetchPolicy: 'network-only',
    });

  const issuesData = useMemo(
    () =>
      foundIssues && foundIssues.issues.data.length > 0
        ? foundIssues.issues.data
        : latestIssuesUsed?.latestIssuesUsed,
    [foundIssues?.issues.data, latestIssuesUsed?.latestIssuesUsed],
  );

  const tags = useMemo(() => {
    if (latestTagsUsed?.latestTagsUsed) {
      const sorted: typeof latestTagsUsed.latestTagsUsed = [];

      for (const tag of latestTagsUsed.latestTagsUsed) {
        sorted.push(tag);
      }

      for (const tag of unsortedTags) {
        if (sorted.some((other) => other.id === tag.id) === false) {
          sorted.push(tag);
        }
      }

      return sorted;
    }

    return unsortedTags;
  }, [unsortedTags, latestTagsUsed?.latestTagsUsed]);

  const {
    control,
    handleSubmit,
    reset,
    formState,
    setValue,
    watch,
    getValues,
  } = useForm<any>({
    shouldUnregister: true,
    resolver: yupResolver(schema),
    defaultValues: {
      userId: profile?.id,
      date: formatISO(new Date()),
    },
  });

  const onSubmit: SubmitHandler<TimelogInput> = async (input) => {
    input.duration = Number(parse(input.duration.toString(), 'm'));

    if (id) {
      try {
        await update({ variables: { id: id, input } });
        toast.success('Timelog entry successfully updated!');
      } catch (err) {
        toast.error('An error occurred :(  ' + err);
      }
    } else {
      try {
        await create({ variables: { input } });

        navigate('/timelogs');
        toast.success('New timeog entry successfully saved!');
      } catch (err) {
        toast.error('An error occurred :(  ' + err);
      }
    }

    loadLatestIssuesUsed();
    loadLatestTagsUsed();
  };

  const [create] = useCreateTimelogMutation();
  const [update] = useUpdateTimelogMutation();
  const [softDelete] = useSoftDeleteTimelogsMutation();
  const [createIssue] = useCreateIssueMutation();

  const issueId = watch('issueId');
  const duration = watch('duration');

  useEffect(() => {
    const parsed = parse(duration, 'm') ?? 0;

    setParsedDuration(parsed >= 1 ? `(${parsed}m)` : '');
  }, [duration]);

  const selectedIssue = useMemo(() => {
    if (issueId && issuesData) {
      return issuesData.find((i) => i.id === issueId);
    }
  }, [issuesData, issueId]);

  const onCreateIssue = useCallback(
    async (ticketId: string, projectId: string, billable, title) => {
      try {
        const createdIssue = await createIssue({
          variables: { input: { ticketId, projectId, billable, title } },
        });

        if (createdIssue.data) {
          await loadIssues({
            variables: {
              pagination: { skip: 0, take: 99999 },
              filter: { query: ticketId as string },
            },
          });
          setValue('issueId', createdIssue.data.createIssue.id, {
            shouldDirty: true,
            shouldValidate: true,
            shouldTouch: true,
          });
          toast.success(`Issue ${ticketId} created.`);
        }
      } catch (e) {
        toast.error('Error creating issue.');
      }
    },
    [setValue],
  );

  const { modal, ask } = useCreateIssueModal(projects, onCreateIssue);

  const [deleteModal, { ask: askDelete }] = useConfirm(async () => {
    if (id) {
      try {
        await softDelete({ variables: { ids: id } });
        toast.success('Timelog has been deleted.');
        navigate('/timelogs');
      } catch (e) {
        toast.error('Error deleting timelog.');
      }
    }
  });

  useEffect(() => {
    if (timelog) {
      reset(timelog);
    }
  }, [reset, timelog]);

  useEffect(() => {
    if (id && timelog) {
      loadIssues({
        variables: {
          pagination: { skip: 0, take: 99999 },
          filter: { query: timelog.issue.ticketId },
        },
      });
    }
  }, [id, timelog]);

  useEffect(() => {
    loadLatestIssuesUsed();
    loadLatestTagsUsed();
  }, []);

  return (
    <>
      {modal}
      {deleteModal}
      <Frame.TitleBar>
        <Frame.Title>{id ? 'Edit Timelog' : 'Create Timelog'}</Frame.Title>
        <Frame.Actions>
          {profile?.isAdmin && (
            <Button
              color="red"
              onClick={() =>
                askDelete('Do you really want to delete this timelog?')
              }
            >
              Delete timelog
            </Button>
          )}
          <Button basic color="black" onClick={() => navigate('/timelogs')}>
            {id && <>Close</>}
            {!id && <>Cancel</>}
          </Button>
        </Frame.Actions>
      </Frame.TitleBar>
      <Frame.Content loading={loading}>
        <Form className={styles.form} error={!formState.isValid}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={2}>
                <Grid.Column>
                  <Controller
                    name="date"
                    control={control}
                    render={({ field, fieldState }) => (
                      <DateField
                        label="Date"
                        field={field}
                        fieldState={fieldState}
                        setValue={setValue}
                      />
                    )}
                  />
                </Grid.Column>
              </Grid.Column>
              <Grid.Column width={14}>
                <Controller
                  name="userId"
                  control={control}
                  render={({ field, fieldState }) => (
                    <SelectField
                      searchable
                      setValue={setValue}
                      options={users.map((u) => ({
                        text: u.label,
                        value: u.id,
                      }))}
                      label="User"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={8}>
                <Controller
                  name="issueId"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <div className="pos-relative">
                        <FinderField
                          field={field}
                          fieldState={fieldState}
                          label="Issue"
                          options={
                            issuesData?.map((i) => ({
                              text: i.label,
                              value: i.id,
                            })) || []
                          }
                          setValue={setValue}
                          initialValue={timelog && timelog.issue.label}
                          onAdd={(value) =>
                            ask(value, `Create Issue ${value}?`)
                          }
                          onSearchChange={(value) => {
                            loadIssues({
                              variables: {
                                pagination: { skip: 0, take: 99999 },
                                filter: { query: value },
                              },
                            });
                          }}
                          loading={issuesLoading}
                        />
                        <NewFeature />
                      </div>
                    </>
                  )}
                />
              </Grid.Column>

              <Grid.Column width={8}>
                <Controller
                  name="tagIds"
                  control={control}
                  render={({ field, fieldState }) => (
                    <SelectField
                      searchable
                      multiple
                      setValue={setValue}
                      options={tags.map((t) => ({
                        text: t.name,
                        value: t.id,
                      }))}
                      label="Tags"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Controller
                  name="description"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      placeholder={selectedIssue?.title || ''}
                      label="Description"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Controller
                  name="duration"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      label={`Duration ${debouncedParsedDuration}`}
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Controller
                  name="amountPerHour"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      onClear={() => setValue('amountPerHour', null)}
                      label="Amount per hour"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />

                <p style={{ color: '#999' }}>
                  The inherited amount from customer / project is{' '}
                  <Formatted.Currency
                    value={timelog?.calculatedAmountPerHour}
                  />
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Controller
                  name="internalNotes"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      textArea
                      label="Internal notes"
                      field={field}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Button onClick={handleSubmit(onSubmit)} color="green">
                  Save
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Frame.Content>
    </>
  );
};
