import flagOff from 'assets/img/finish-inactive.svg';
import flagOn from 'assets/img/finish.svg';
import { ComponentType } from 'react';
import * as styles from './flag-toggle.module.scss';
interface FlagToggleProps {
  value: boolean;
  onChange: (value: boolean) => void;
}

export const FlagToggle: ComponentType<FlagToggleProps> = (props) => {
  const { value, onChange } = props;
  return (
    <div className={styles.host}>
      <img
        src={value ? flagOn : flagOff}
        onClick={() => {
          onChange(!value);
        }}
      />
    </div>
  );
};
