import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FullServiceDataFragment = { __typename?: 'Service', id: any, startDate: any, endDate?: any | null, invoicePeriod: number, productId: any, customerId: any, product: { __typename?: 'Product', name: string, amount: { __typename?: 'Amount', type: Types.AmountType, value: number, net: number, gross: number, tax: number } }, customer: { __typename?: 'Customer', id: any, label: string } };

export type ServiceQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type ServiceQuery = { __typename?: 'Query', service: { __typename?: 'Service', id: any, startDate: any, endDate?: any | null, invoicePeriod: number, productId: any, customerId: any, product: { __typename?: 'Product', name: string, amount: { __typename?: 'Amount', type: Types.AmountType, value: number, net: number, gross: number, tax: number } }, customer: { __typename?: 'Customer', id: any, label: string } } };

export type UpdateServiceMutationVariables = Types.Exact<{
  input: Types.EditServiceInput;
  id: Types.Scalars['UUID']['input'];
}>;


export type UpdateServiceMutation = { __typename?: 'Mutation', updateService: { __typename?: 'Service', id: any, startDate: any, endDate?: any | null, invoicePeriod: number, productId: any, customerId: any, product: { __typename?: 'Product', name: string, amount: { __typename?: 'Amount', type: Types.AmountType, value: number, net: number, gross: number, tax: number } }, customer: { __typename?: 'Customer', id: any, label: string } } };

export type CreateServiceMutationVariables = Types.Exact<{
  input: Types.CreateServiceInput;
}>;


export type CreateServiceMutation = { __typename?: 'Mutation', createService: { __typename?: 'Service', id: any, startDate: any, endDate?: any | null, invoicePeriod: number, productId: any, customerId: any, product: { __typename?: 'Product', name: string, amount: { __typename?: 'Amount', type: Types.AmountType, value: number, net: number, gross: number, tax: number } }, customer: { __typename?: 'Customer', id: any, label: string } } };

export const FullServiceDataFragmentDoc = gql`
    fragment FullServiceData on Service {
  id
  startDate
  endDate
  invoicePeriod
  productId
  customerId
  product {
    name
    amount {
      type
      value
      net
      gross
      tax
    }
  }
  customer {
    id
    label
  }
}
    `;
export const ServiceDocument = gql`
    query service($id: UUID!) {
  service(id: $id) {
    ...FullServiceData
  }
}
    ${FullServiceDataFragmentDoc}`;

/**
 * __useServiceQuery__
 *
 * To run a query within a React component, call `useServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceQuery(baseOptions: Apollo.QueryHookOptions<ServiceQuery, ServiceQueryVariables> & ({ variables: ServiceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
      }
export function useServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
        }
export function useServiceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
        }
export type ServiceQueryHookResult = ReturnType<typeof useServiceQuery>;
export type ServiceLazyQueryHookResult = ReturnType<typeof useServiceLazyQuery>;
export type ServiceSuspenseQueryHookResult = ReturnType<typeof useServiceSuspenseQuery>;
export type ServiceQueryResult = Apollo.QueryResult<ServiceQuery, ServiceQueryVariables>;
export const UpdateServiceDocument = gql`
    mutation updateService($input: EditServiceInput!, $id: UUID!) {
  updateService(input: $input, id: $id) {
    ...FullServiceData
  }
}
    ${FullServiceDataFragmentDoc}`;
export type UpdateServiceMutationFn = Apollo.MutationFunction<UpdateServiceMutation, UpdateServiceMutationVariables>;

/**
 * __useUpdateServiceMutation__
 *
 * To run a mutation, you first call `useUpdateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceMutation, { data, loading, error }] = useUpdateServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceMutation, UpdateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceMutation, UpdateServiceMutationVariables>(UpdateServiceDocument, options);
      }
export type UpdateServiceMutationHookResult = ReturnType<typeof useUpdateServiceMutation>;
export type UpdateServiceMutationResult = Apollo.MutationResult<UpdateServiceMutation>;
export type UpdateServiceMutationOptions = Apollo.BaseMutationOptions<UpdateServiceMutation, UpdateServiceMutationVariables>;
export const CreateServiceDocument = gql`
    mutation createService($input: CreateServiceInput!) {
  createService(input: $input) {
    ...FullServiceData
  }
}
    ${FullServiceDataFragmentDoc}`;
export type CreateServiceMutationFn = Apollo.MutationFunction<CreateServiceMutation, CreateServiceMutationVariables>;

/**
 * __useCreateServiceMutation__
 *
 * To run a mutation, you first call `useCreateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceMutation, { data, loading, error }] = useCreateServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceMutation, CreateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceMutation, CreateServiceMutationVariables>(CreateServiceDocument, options);
      }
export type CreateServiceMutationHookResult = ReturnType<typeof useCreateServiceMutation>;
export type CreateServiceMutationResult = Apollo.MutationResult<CreateServiceMutation>;
export type CreateServiceMutationOptions = Apollo.BaseMutationOptions<CreateServiceMutation, CreateServiceMutationVariables>;