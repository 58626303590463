// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wNF4kyEKa4UTkUG2NmD4{min-height:100vh}.wNF4kyEKa4UTkUG2NmD4 .wgecYexryJFScMkowRFA{display:flex}.wNF4kyEKa4UTkUG2NmD4 .loqCPHEPo5t5FHmsOS3_{margin-top:4rem;display:flex;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/features/toggle-moggle/toggle-moggle.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CAEA,4CACE,YAAA,CAGF,4CACE,eAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".host {\n  min-height: 100vh;\n\n  .projectToggles {\n    display: flex;\n  }\n\n  .addProjectButton {\n    margin-top: 4rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var host = `wNF4kyEKa4UTkUG2NmD4`;
export var projectToggles = `wgecYexryJFScMkowRFA`;
export var addProjectButton = `loqCPHEPo5t5FHmsOS3_`;
export default ___CSS_LOADER_EXPORT___;
