import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvoiceListDataFragment = { __typename?: 'Invoice', id: any, createdAt: any, sentAt?: any | null };

export type InvoicesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type InvoicesQuery = { __typename?: 'Query', invoices: Array<{ __typename?: 'Invoice', id: any, createdAt: any, sentAt?: any | null }> };

export type ToggleSentMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type ToggleSentMutation = { __typename?: 'Mutation', toggleSent: { __typename?: 'Invoice', id: any, sentAt?: any | null } };

export const InvoiceListDataFragmentDoc = gql`
    fragment InvoiceListData on Invoice {
  id
  createdAt
  sentAt
}
    `;
export const InvoicesDocument = gql`
    query invoices {
  invoices {
    ...InvoiceListData
  }
}
    ${InvoiceListDataFragmentDoc}`;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
      }
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
        }
export function useInvoicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesSuspenseQueryHookResult = ReturnType<typeof useInvoicesSuspenseQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const ToggleSentDocument = gql`
    mutation toggleSent($id: UUID!) {
  toggleSent(id: $id) {
    id
    sentAt
  }
}
    `;
export type ToggleSentMutationFn = Apollo.MutationFunction<ToggleSentMutation, ToggleSentMutationVariables>;

/**
 * __useToggleSentMutation__
 *
 * To run a mutation, you first call `useToggleSentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleSentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleSentMutation, { data, loading, error }] = useToggleSentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleSentMutation(baseOptions?: Apollo.MutationHookOptions<ToggleSentMutation, ToggleSentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleSentMutation, ToggleSentMutationVariables>(ToggleSentDocument, options);
      }
export type ToggleSentMutationHookResult = ReturnType<typeof useToggleSentMutation>;
export type ToggleSentMutationResult = Apollo.MutationResult<ToggleSentMutation>;
export type ToggleSentMutationOptions = Apollo.BaseMutationOptions<ToggleSentMutation, ToggleSentMutationVariables>;