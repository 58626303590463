import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FullTimelogDataFragment = { __typename?: 'Timelog', id: any, date: any, duration?: number | null, description?: string | null, internalNotes?: string | null, amountPerHour?: number | null, calculatedAmountPerHour: number, billedAt?: any | null, tagIds: Array<any>, userId: any, issueId: any, tags: Array<{ __typename?: 'Tag', id: any, name: string }>, user: { __typename?: 'User', id: any, firstName: string, lastName: string }, issue: { __typename?: 'Issue', id: any, ticketId: string, billable: boolean, label: string, project?: { __typename?: 'Project', id: any, title: string, customer: { __typename?: 'Customer', id: any, label: string } } | null } };

export type TimelogQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type TimelogQuery = { __typename?: 'Query', timelog: { __typename?: 'Timelog', id: any, date: any, duration?: number | null, description?: string | null, internalNotes?: string | null, amountPerHour?: number | null, calculatedAmountPerHour: number, billedAt?: any | null, tagIds: Array<any>, userId: any, issueId: any, tags: Array<{ __typename?: 'Tag', id: any, name: string }>, user: { __typename?: 'User', id: any, firstName: string, lastName: string }, issue: { __typename?: 'Issue', id: any, ticketId: string, billable: boolean, label: string, project?: { __typename?: 'Project', id: any, title: string, customer: { __typename?: 'Customer', id: any, label: string } } | null } } };

export type LatestIssuesUsedQueryVariables = Types.Exact<{
  userId: Types.Scalars['UUID']['input'];
}>;


export type LatestIssuesUsedQuery = { __typename?: 'Query', latestIssuesUsed: Array<{ __typename?: 'Issue', id: any, ticketId: string, billable: boolean, title?: string | null, label: string, project?: { __typename?: 'Project', id: any, title: string, customer: { __typename?: 'Customer', id: any, label: string } } | null }> };

export type LatestTagsUsedQueryVariables = Types.Exact<{
  userId: Types.Scalars['UUID']['input'];
}>;


export type LatestTagsUsedQuery = { __typename?: 'Query', latestTagsUsed: Array<{ __typename?: 'Tag', id: any, name: string }> };

export type UpdateTimelogMutationVariables = Types.Exact<{
  input: Types.TimelogInput;
  id: Types.Scalars['UUID']['input'];
}>;


export type UpdateTimelogMutation = { __typename?: 'Mutation', updateTimelog: { __typename?: 'Timelog', id: any, date: any, duration?: number | null, description?: string | null, internalNotes?: string | null, amountPerHour?: number | null, calculatedAmountPerHour: number, billedAt?: any | null, tagIds: Array<any>, userId: any, issueId: any, tags: Array<{ __typename?: 'Tag', id: any, name: string }>, user: { __typename?: 'User', id: any, firstName: string, lastName: string }, issue: { __typename?: 'Issue', id: any, ticketId: string, billable: boolean, label: string, project?: { __typename?: 'Project', id: any, title: string, customer: { __typename?: 'Customer', id: any, label: string } } | null } } };

export type CreateTimelogMutationVariables = Types.Exact<{
  input: Types.TimelogInput;
}>;


export type CreateTimelogMutation = { __typename?: 'Mutation', createTimelog: { __typename?: 'Timelog', id: any, date: any, duration?: number | null, description?: string | null, internalNotes?: string | null, amountPerHour?: number | null, calculatedAmountPerHour: number, billedAt?: any | null, tagIds: Array<any>, userId: any, issueId: any, tags: Array<{ __typename?: 'Tag', id: any, name: string }>, user: { __typename?: 'User', id: any, firstName: string, lastName: string }, issue: { __typename?: 'Issue', id: any, ticketId: string, billable: boolean, label: string, project?: { __typename?: 'Project', id: any, title: string, customer: { __typename?: 'Customer', id: any, label: string } } | null } } };

export type SearchTagsQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SearchTagsQuery = { __typename?: 'Query', searchTags: Array<{ __typename?: 'Tag', id: any, name: string }> };

export const FullTimelogDataFragmentDoc = gql`
    fragment FullTimelogData on Timelog {
  id
  date
  duration
  description
  internalNotes
  amountPerHour
  calculatedAmountPerHour
  billedAt
  tagIds
  tags {
    id
    name
  }
  user {
    id
    firstName
    lastName
  }
  userId
  issue {
    id
    ticketId
    billable
    project {
      id
      title
      customer {
        id
        label
      }
    }
    label
  }
  issueId
}
    `;
export const TimelogDocument = gql`
    query timelog($id: UUID!) {
  timelog(id: $id) {
    ...FullTimelogData
  }
}
    ${FullTimelogDataFragmentDoc}`;

/**
 * __useTimelogQuery__
 *
 * To run a query within a React component, call `useTimelogQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTimelogQuery(baseOptions: Apollo.QueryHookOptions<TimelogQuery, TimelogQueryVariables> & ({ variables: TimelogQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimelogQuery, TimelogQueryVariables>(TimelogDocument, options);
      }
export function useTimelogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimelogQuery, TimelogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimelogQuery, TimelogQueryVariables>(TimelogDocument, options);
        }
export function useTimelogSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TimelogQuery, TimelogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TimelogQuery, TimelogQueryVariables>(TimelogDocument, options);
        }
export type TimelogQueryHookResult = ReturnType<typeof useTimelogQuery>;
export type TimelogLazyQueryHookResult = ReturnType<typeof useTimelogLazyQuery>;
export type TimelogSuspenseQueryHookResult = ReturnType<typeof useTimelogSuspenseQuery>;
export type TimelogQueryResult = Apollo.QueryResult<TimelogQuery, TimelogQueryVariables>;
export const LatestIssuesUsedDocument = gql`
    query latestIssuesUsed($userId: UUID!) {
  latestIssuesUsed(userId: $userId) {
    id
    ticketId
    billable
    title
    project {
      id
      title
      customer {
        id
        label
      }
    }
    label
  }
}
    `;

/**
 * __useLatestIssuesUsedQuery__
 *
 * To run a query within a React component, call `useLatestIssuesUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestIssuesUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestIssuesUsedQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLatestIssuesUsedQuery(baseOptions: Apollo.QueryHookOptions<LatestIssuesUsedQuery, LatestIssuesUsedQueryVariables> & ({ variables: LatestIssuesUsedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestIssuesUsedQuery, LatestIssuesUsedQueryVariables>(LatestIssuesUsedDocument, options);
      }
export function useLatestIssuesUsedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestIssuesUsedQuery, LatestIssuesUsedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestIssuesUsedQuery, LatestIssuesUsedQueryVariables>(LatestIssuesUsedDocument, options);
        }
export function useLatestIssuesUsedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LatestIssuesUsedQuery, LatestIssuesUsedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LatestIssuesUsedQuery, LatestIssuesUsedQueryVariables>(LatestIssuesUsedDocument, options);
        }
export type LatestIssuesUsedQueryHookResult = ReturnType<typeof useLatestIssuesUsedQuery>;
export type LatestIssuesUsedLazyQueryHookResult = ReturnType<typeof useLatestIssuesUsedLazyQuery>;
export type LatestIssuesUsedSuspenseQueryHookResult = ReturnType<typeof useLatestIssuesUsedSuspenseQuery>;
export type LatestIssuesUsedQueryResult = Apollo.QueryResult<LatestIssuesUsedQuery, LatestIssuesUsedQueryVariables>;
export const LatestTagsUsedDocument = gql`
    query latestTagsUsed($userId: UUID!) {
  latestTagsUsed(userId: $userId) {
    id
    name
  }
}
    `;

/**
 * __useLatestTagsUsedQuery__
 *
 * To run a query within a React component, call `useLatestTagsUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestTagsUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestTagsUsedQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLatestTagsUsedQuery(baseOptions: Apollo.QueryHookOptions<LatestTagsUsedQuery, LatestTagsUsedQueryVariables> & ({ variables: LatestTagsUsedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestTagsUsedQuery, LatestTagsUsedQueryVariables>(LatestTagsUsedDocument, options);
      }
export function useLatestTagsUsedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestTagsUsedQuery, LatestTagsUsedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestTagsUsedQuery, LatestTagsUsedQueryVariables>(LatestTagsUsedDocument, options);
        }
export function useLatestTagsUsedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LatestTagsUsedQuery, LatestTagsUsedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LatestTagsUsedQuery, LatestTagsUsedQueryVariables>(LatestTagsUsedDocument, options);
        }
export type LatestTagsUsedQueryHookResult = ReturnType<typeof useLatestTagsUsedQuery>;
export type LatestTagsUsedLazyQueryHookResult = ReturnType<typeof useLatestTagsUsedLazyQuery>;
export type LatestTagsUsedSuspenseQueryHookResult = ReturnType<typeof useLatestTagsUsedSuspenseQuery>;
export type LatestTagsUsedQueryResult = Apollo.QueryResult<LatestTagsUsedQuery, LatestTagsUsedQueryVariables>;
export const UpdateTimelogDocument = gql`
    mutation updateTimelog($input: TimelogInput!, $id: UUID!) {
  updateTimelog(input: $input, id: $id) {
    ...FullTimelogData
  }
}
    ${FullTimelogDataFragmentDoc}`;
export type UpdateTimelogMutationFn = Apollo.MutationFunction<UpdateTimelogMutation, UpdateTimelogMutationVariables>;

/**
 * __useUpdateTimelogMutation__
 *
 * To run a mutation, you first call `useUpdateTimelogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimelogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimelogMutation, { data, loading, error }] = useUpdateTimelogMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateTimelogMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimelogMutation, UpdateTimelogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimelogMutation, UpdateTimelogMutationVariables>(UpdateTimelogDocument, options);
      }
export type UpdateTimelogMutationHookResult = ReturnType<typeof useUpdateTimelogMutation>;
export type UpdateTimelogMutationResult = Apollo.MutationResult<UpdateTimelogMutation>;
export type UpdateTimelogMutationOptions = Apollo.BaseMutationOptions<UpdateTimelogMutation, UpdateTimelogMutationVariables>;
export const CreateTimelogDocument = gql`
    mutation createTimelog($input: TimelogInput!) {
  createTimelog(input: $input) {
    ...FullTimelogData
  }
}
    ${FullTimelogDataFragmentDoc}`;
export type CreateTimelogMutationFn = Apollo.MutationFunction<CreateTimelogMutation, CreateTimelogMutationVariables>;

/**
 * __useCreateTimelogMutation__
 *
 * To run a mutation, you first call `useCreateTimelogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimelogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimelogMutation, { data, loading, error }] = useCreateTimelogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimelogMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimelogMutation, CreateTimelogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimelogMutation, CreateTimelogMutationVariables>(CreateTimelogDocument, options);
      }
export type CreateTimelogMutationHookResult = ReturnType<typeof useCreateTimelogMutation>;
export type CreateTimelogMutationResult = Apollo.MutationResult<CreateTimelogMutation>;
export type CreateTimelogMutationOptions = Apollo.BaseMutationOptions<CreateTimelogMutation, CreateTimelogMutationVariables>;
export const SearchTagsDocument = gql`
    query searchTags($search: String) {
  searchTags(search: $search) {
    id
    name
  }
}
    `;

/**
 * __useSearchTagsQuery__
 *
 * To run a query within a React component, call `useSearchTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTagsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchTagsQuery(baseOptions?: Apollo.QueryHookOptions<SearchTagsQuery, SearchTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchTagsQuery, SearchTagsQueryVariables>(SearchTagsDocument, options);
      }
export function useSearchTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTagsQuery, SearchTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchTagsQuery, SearchTagsQueryVariables>(SearchTagsDocument, options);
        }
export function useSearchTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchTagsQuery, SearchTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchTagsQuery, SearchTagsQueryVariables>(SearchTagsDocument, options);
        }
export type SearchTagsQueryHookResult = ReturnType<typeof useSearchTagsQuery>;
export type SearchTagsLazyQueryHookResult = ReturnType<typeof useSearchTagsLazyQuery>;
export type SearchTagsSuspenseQueryHookResult = ReturnType<typeof useSearchTagsSuspenseQuery>;
export type SearchTagsQueryResult = Apollo.QueryResult<SearchTagsQuery, SearchTagsQueryVariables>;