export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type Amount = {
  __typename?: 'Amount';
  gross: Scalars['Float']['output'];
  net: Scalars['Float']['output'];
  tax: Scalars['Float']['output'];
  type: AmountType;
  value: Scalars['Float']['output'];
};

export type AmountInput = {
  type: AmountType;
  value: Scalars['Float']['input'];
};

export enum AmountType {
  Gross = 'GROSS',
  Net = 'NET'
}

export type CreateDiscountInput = {
  amountAbsolute?: InputMaybe<AmountInput>;
  amountRelative?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  serviceId: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type CreateInvoiceInput = {
  customerId: Scalars['UUID']['input'];
};

export type CreateProductInput = {
  amount: AmountInput;
  name: Scalars['String']['input'];
};

export type CreateServiceInput = {
  customerId: Scalars['UUID']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  invoicePeriod?: InputMaybe<Scalars['Float']['input']>;
  productId: Scalars['UUID']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  initials: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Customer = {
  __typename?: 'Customer';
  amountPerHour?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  contactEmail: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  customerNumber?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  label: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  longLabel: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  slugs: Scalars['String']['output'];
  street?: Maybe<Scalars['String']['output']>;
  timeSpent: Scalars['Int']['output'];
};

export type Discount = {
  __typename?: 'Discount';
  amountAbsolute: Amount;
  amountRelative: Scalars['Float']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  service: Service;
  startDate: Scalars['DateTime']['output'];
};

export type EditDiscountInput = {
  amountAbsolute?: InputMaybe<AmountInput>;
  amountRelative?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EditProductInput = {
  amount?: InputMaybe<AmountInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type EditServiceInput = {
  customerId?: InputMaybe<Scalars['UUID']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  invoicePeriod?: InputMaybe<Scalars['Float']['input']>;
  productId?: InputMaybe<Scalars['UUID']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EditUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  initials?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type FilterTimelogsInput = {
  billable?: InputMaybe<Scalars['String']['input']>;
  billed?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['UUID']['input']>;
  excludeTagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  flagged?: InputMaybe<Scalars['String']['input']>;
  issueId?: InputMaybe<Scalars['UUID']['input']>;
  projectId?: InputMaybe<Scalars['UUID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  range?: InputMaybe<Scalars['String']['input']>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

export type FoundPrefixMapping = {
  __typename?: 'FoundPrefixMapping';
  prefix: Scalars['String']['output'];
  projectId?: Maybe<Scalars['UUID']['output']>;
};

export type HoursLoggedPerDay = {
  __typename?: 'HoursLoggedPerDay';
  date: Scalars['String']['output'];
  hoursLogged: Scalars['Float']['output'];
  username: Scalars['String']['output'];
};

export type Invoice = {
  __typename?: 'Invoice';
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  id: Scalars['UUID']['output'];
  invoiceItems: Array<InvoiceItem>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  amount: Amount;
  description: Scalars['String']['output'];
  discountAmountAbsolute: Amount;
  discountAmountRelative: Scalars['Float']['output'];
  id: Scalars['UUID']['output'];
  invoice: Invoice;
  service: Service;
};

export type Issue = {
  __typename?: 'Issue';
  billable: Scalars['Boolean']['output'];
  estimation?: Maybe<Scalars['Int']['output']>;
  fixedAmount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  label: Scalars['String']['output'];
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['UUID']['output']>;
  shortLabel: Scalars['String']['output'];
  status: IssueStatus;
  ticketId: Scalars['String']['output'];
  timelogs: Array<Timelog>;
  title?: Maybe<Scalars['String']['output']>;
};

export type IssueInput = {
  billable?: InputMaybe<Scalars['Boolean']['input']>;
  estimation?: InputMaybe<Scalars['Int']['input']>;
  fixedAmount?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['UUID']['input'];
  ticketId: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum IssueStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type Mutation = {
  __typename?: 'Mutation';
  applyPrefixMappings: Scalars['Int']['output'];
  authenticate: Scalars['String']['output'];
  bulkUpdateTimelogs: Scalars['Boolean']['output'];
  createCustomer: Customer;
  createDiscount: Discount;
  createInvoice: Invoice;
  createIssue: Issue;
  createProduct: Product;
  createProject: Project;
  createService: Service;
  createTimelog: Timelog;
  createUser: User;
  deleteIssue: Scalars['Boolean']['output'];
  deleteTag: Scalars['Boolean']['output'];
  logout: Scalars['Boolean']['output'];
  savePrefixMappings: Scalars['Boolean']['output'];
  saveTag: Tag;
  softDeleteCustomer: Scalars['Boolean']['output'];
  softDeleteProject: Scalars['Boolean']['output'];
  softDeleteTimelog: Scalars['Boolean']['output'];
  softDeleteTimelogs: Scalars['Boolean']['output'];
  startToggle: Timelog;
  stopToggle: Timelog;
  syncIssuesWithJira: SyncResult;
  toggleActive: User;
  toggleSent: Invoice;
  toggleTimelogBilled: Timelog;
  toggleTimelogFlagged: Timelog;
  updateCustomer: Customer;
  updateDiscount: Discount;
  updateIssue: Issue;
  updateProduct: Product;
  updateProject: Project;
  updateService: Service;
  updateSettings: User;
  updateTimelog: Timelog;
  updateUser: User;
};


export type MutationAuthenticateArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationBulkUpdateTimelogsArgs = {
  ids: Array<Scalars['UUID']['input']>;
  input: TimelogBulkUpdateInput;
};


export type MutationCreateCustomerArgs = {
  input: SaveCustomerInput;
};


export type MutationCreateDiscountArgs = {
  input: CreateDiscountInput;
};


export type MutationCreateInvoiceArgs = {
  input: CreateInvoiceInput;
};


export type MutationCreateIssueArgs = {
  input: IssueInput;
};


export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


export type MutationCreateProjectArgs = {
  input: ProjectInput;
};


export type MutationCreateServiceArgs = {
  input: CreateServiceInput;
};


export type MutationCreateTimelogArgs = {
  input: TimelogInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteIssueArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationSavePrefixMappingsArgs = {
  input: Array<SavePrefixMappingInput>;
};


export type MutationSaveTagArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  input: TagInput;
};


export type MutationSoftDeleteCustomerArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationSoftDeleteProjectArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationSoftDeleteTimelogArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationSoftDeleteTimelogsArgs = {
  ids: Array<Scalars['UUID']['input']>;
};


export type MutationStartToggleArgs = {
  input: StartToggleInput;
};


export type MutationStopToggleArgs = {
  input: StopToggleInput;
};


export type MutationToggleActiveArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationToggleSentArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationToggleTimelogBilledArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationToggleTimelogFlaggedArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationUpdateCustomerArgs = {
  id: Scalars['UUID']['input'];
  input: SaveCustomerInput;
};


export type MutationUpdateDiscountArgs = {
  id: Scalars['UUID']['input'];
  input: EditDiscountInput;
};


export type MutationUpdateIssueArgs = {
  id: Scalars['UUID']['input'];
  input: IssueInput;
};


export type MutationUpdateProductArgs = {
  id: Scalars['UUID']['input'];
  input: EditProductInput;
};


export type MutationUpdateProjectArgs = {
  id: Scalars['UUID']['input'];
  input: ProjectInput;
};


export type MutationUpdateServiceArgs = {
  id: Scalars['UUID']['input'];
  input: EditServiceInput;
};


export type MutationUpdateSettingsArgs = {
  input: SettingsInput;
};


export type MutationUpdateTimelogArgs = {
  id: Scalars['UUID']['input'];
  input: TimelogInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['UUID']['input'];
  input: EditUserInput;
};

export type PaginatedIssuesResult = {
  __typename?: 'PaginatedIssuesResult';
  data: Array<Issue>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedTimelogsResult = {
  __typename?: 'PaginatedTimelogsResult';
  data: Array<Timelog>;
  totalCount: Scalars['Int']['output'];
  totalDuration: Scalars['Int']['output'];
};

export type PaginationInput = {
  skip: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
};

export type Product = {
  __typename?: 'Product';
  amount: Amount;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type Project = {
  __typename?: 'Project';
  amountPerHour?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  customerId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  openTimelog?: Maybe<Timelog>;
  projectNumber?: Maybe<Scalars['Int']['output']>;
  slugs?: Maybe<Scalars['String']['output']>;
  status: ProjectStatus;
  timeSpent: Scalars['Int']['output'];
  timelogs: Array<Timelog>;
  title: Scalars['String']['output'];
};

export type ProjectInput = {
  amountPerHour?: InputMaybe<Scalars['Int']['input']>;
  customerId?: InputMaybe<Scalars['UUID']['input']>;
  projectNumber?: InputMaybe<Scalars['Int']['input']>;
  slugs?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProjectStatus>;
  title: Scalars['String']['input'];
};

export enum ProjectStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  OnHold = 'ON_HOLD'
}

export type ProjectToggleEntry = {
  __typename?: 'ProjectToggleEntry';
  id: Scalars['UUID']['output'];
  width: Scalars['Float']['output'];
};

export type ProjectToggleEntryInput = {
  id: Scalars['UUID']['input'];
  width: Scalars['Float']['input'];
};

export type Query = {
  __typename?: 'Query';
  customer: Customer;
  customerBySlug: Customer;
  customers: Array<Customer>;
  discount: Discount;
  discountHello: Scalars['String']['output'];
  discounts: Array<Discount>;
  getTotalHoursLoggedPerDay: Array<HoursLoggedPerDay>;
  invoice: Invoice;
  invoiceHello: Scalars['String']['output'];
  invoices: Array<Invoice>;
  issue: Issue;
  issues: PaginatedIssuesResult;
  latestIssuesUsed: Array<Issue>;
  latestTagsUsed: Array<Tag>;
  prefixMappings: Array<FoundPrefixMapping>;
  product: Product;
  productHello: Scalars['String']['output'];
  products: Array<Product>;
  profile: User;
  project: Project;
  projectBySlug: Project;
  projects: Array<Project>;
  searchTags: Array<Tag>;
  service: Service;
  serviceHello: Scalars['String']['output'];
  services: Array<Service>;
  servicesByCustomer: Array<Service>;
  tag: Tag;
  tags: Array<Tag>;
  timelog: Timelog;
  timelogs: PaginatedTimelogsResult;
  user: User;
  userByEmail: User;
  users: Array<User>;
};


export type QueryCustomerArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryCustomerBySlugArgs = {
  slugs: Scalars['String']['input'];
};


export type QueryCustomersArgs = {
  filter?: InputMaybe<SearchCustomersInput>;
};


export type QueryDiscountArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryInvoiceArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryIssueArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryIssuesArgs = {
  filter?: InputMaybe<SearchIssuesInput>;
  pagination: PaginationInput;
};


export type QueryLatestIssuesUsedArgs = {
  userId: Scalars['UUID']['input'];
};


export type QueryLatestTagsUsedArgs = {
  userId: Scalars['UUID']['input'];
};


export type QueryProductArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryProjectArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryProjectBySlugArgs = {
  slugs: Scalars['String']['input'];
};


export type QueryProjectsArgs = {
  filter?: InputMaybe<SearchProjectsInput>;
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>;
};


export type QuerySearchTagsArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryServiceArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryServicesByCustomerArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryTagArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryTimelogArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryTimelogsArgs = {
  filter?: InputMaybe<FilterTimelogsInput>;
  pagination: PaginationInput;
};


export type QueryUserArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryUserByEmailArgs = {
  email: Scalars['String']['input'];
};

export type SaveCustomerInput = {
  amountPerHour?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  customerNumber: Scalars['Int']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  slugs: Scalars['String']['input'];
  street?: InputMaybe<Scalars['String']['input']>;
};

export type SavePrefixMappingInput = {
  prefix: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['UUID']['input']>;
};

export type SearchCustomersInput = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export type SearchIssuesInput = {
  billable?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['UUID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type SearchProjectsInput = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export type Service = {
  __typename?: 'Service';
  customer: Customer;
  customerId: Scalars['UUID']['output'];
  discounts: Array<Discount>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  /** Given in months */
  invoicePeriod: Scalars['Int']['output'];
  product: Product;
  productId: Scalars['UUID']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type Settings = {
  __typename?: 'Settings';
  projectToggles?: Maybe<Array<ProjectToggleEntry>>;
};

export type SettingsInput = {
  projectToggles: Array<ProjectToggleEntryInput>;
};

export type StartToggleInput = {
  issueId: Scalars['UUID']['input'];
};

export type StopToggleInput = {
  description: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};

export type SyncResult = {
  __typename?: 'SyncResult';
  error?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
};

export type Tag = {
  __typename?: 'Tag';
  context: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type TagInput = {
  context: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type Timelog = {
  __typename?: 'Timelog';
  amountPerHour?: Maybe<Scalars['Int']['output']>;
  billable: Scalars['Boolean']['output'];
  billedAt?: Maybe<Scalars['DateTime']['output']>;
  calculatedAmountPerHour: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  flagged: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  internalNotes?: Maybe<Scalars['String']['output']>;
  issue: Issue;
  issueId: Scalars['UUID']['output'];
  tagIds: Array<Scalars['UUID']['output']>;
  tags: Array<Tag>;
  user: User;
  userId: Scalars['UUID']['output'];
};

export type TimelogBulkUpdateInput = {
  amountPerHour?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  flagged?: InputMaybe<Scalars['Boolean']['input']>;
  issueId?: InputMaybe<Scalars['UUID']['input']>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

export type TimelogInput = {
  amountPerHour?: InputMaybe<Scalars['Int']['input']>;
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Float']['input'];
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  issueId: Scalars['UUID']['input'];
  tagIds: Array<Scalars['String']['input']>;
  userId: Scalars['UUID']['input'];
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  initials?: Maybe<Scalars['String']['output']>;
  isAdmin: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  settings: Settings;
  timelogs?: Maybe<Array<Timelog>>;
};
