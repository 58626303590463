import { useModal } from '@/features/ui/modal/modal.hook';
import { ReactNode, useCallback, useState } from 'react';
import { Input } from 'semantic-ui-react';
import * as styles from './modal.module.scss';
interface ModalApi {
  open: (id: string) => void;
}

export type CloseProjectModalHook = [ReactNode, ModalApi];
export function useCloseProjectModal(
  onSubmit: (id: string, answer: string) => void,
): CloseProjectModalHook {
  const [id, setId] = useState<string>();
  const [answer, setAnswer] = useState<string>();
  const [modal, { open: openModal }] = useModal({
    title: 'Close Moggle',
    content: (
      <div className={styles.host}>
        <p>Please enter a description for the work done!</p>
        <Input
          className={styles.input}
          onChange={(e, d) => setAnswer(d.value)}
          value={answer}
        />
      </div>
    ),
    actions: [
      {
        label: 'Wait, there is still some work!',
      },

      {
        label: 'I am done.',
        disabled: !id || !answer,
        primary: true,
        onClick: id && answer ? () => onSubmit(id, answer) : undefined,
      },
    ],
  });

  const open = useCallback(
    (id: string) => {
      setId(id);
      setAnswer('');
      openModal();
    },
    [openModal],
  );

  return [modal, { open }];
}
