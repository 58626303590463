import { useResources } from '@/features/resources/resources.provider';
import { Frame } from '@/features/ui/frame/frame';
import { useAuth } from '@/features/utils/auth';
import { ComponentType, useCallback, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Select, Table, TableRow } from 'semantic-ui-react';
import {
  useApplyPrefixMappingsMutation,
  usePrefixMappingsQuery,
  useSavePrefixMappingsMutation,
} from './mapping.generated';

export const PrefixMapping: ComponentType = () => {
  let navigate = useNavigate();
  const [localMappings, setLocalMappings] = useState<{
    [key: string]: string | null;
  }>({});
  const [dirty, setDirty] = useState(true);

  const { projects } = useResources();
  const { data, loading, refetch } = usePrefixMappingsQuery({
    fetchPolicy: 'network-only',
  });

  const [saveMappings] = useSavePrefixMappingsMutation();
  const [applyMappings] = useApplyPrefixMappingsMutation();

  const prefixMappings = data?.prefixMappings || [];

  const onSaveMappings = useCallback(async () => {
    await saveMappings({
      variables: {
        input: Object.entries(localMappings).map(([prefix, projectId]) => ({
          prefix,
          projectId,
        })),
      },
    });
    toast.success('Prefix mappings have been saved.');
    setDirty(false);
  }, [localMappings]);

  const onApplyMappings = useCallback(async () => {
    const applyResult = await applyMappings();
    toast.success(
      `${applyResult.data?.applyPrefixMappings} have been assigned to projectIds`
    );
  }, []);

  useEffect(() => {
    if (data) {
      setLocalMappings(
        data.prefixMappings.reduce((prev, current) => {
          return { ...prev, [current.prefix]: current.projectId };
        }, {})
      );
      setDirty(false);
    }
  }, [data]);

  useAuth('..');

  return (
    <>
      <Frame.TitleBar>
        <Frame.Title>Prefix-Project Mapping</Frame.Title>
        <Frame.Actions>
          <Button basic as={NavLink} to="/issues">
            Back to issues
          </Button>
          <Button color="red" disabled={dirty} onClick={onApplyMappings}>
            Apply mappings
          </Button>
          <Button disabled={!dirty} color="green" onClick={onSaveMappings}>
            Save mappings
          </Button>
        </Frame.Actions>
      </Frame.TitleBar>
      <Frame.Content loading={loading}>
        <>
          <Table basic="very" size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Prefix</Table.HeaderCell>
                <Table.HeaderCell>Project</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {prefixMappings.map((mapping) => (
                <TableRow key={mapping.prefix}>
                  <Table.Cell>{mapping.prefix}</Table.Cell>
                  <Table.Cell width={8}>
                    <Select
                      fluid
                      search
                      closeOnChange
                      closeOnBlur
                      clearable
                      onChange={(_, data) => {
                        setLocalMappings({
                          ...localMappings,
                          [mapping.prefix]: data.value
                            ? (data.value as string)
                            : null,
                        });
                        setDirty(true);
                      }}
                      value={localMappings[mapping.prefix] || ''}
                      options={projects.map((p) => ({
                        text: `${p.title} (${p.customer.companyName})`,
                        value: p.id,
                      }))}
                    />
                  </Table.Cell>
                </TableRow>
              ))}
            </Table.Body>
          </Table>
        </>
      </Frame.Content>
    </>
  );
};
