import {
  ComponentType,
  PropsWithChildren,
  createContext,
  useContext,
} from 'react';
import { ResourcesQuery, useResourcesQuery } from './resources.generated';

interface Resources {
  customers: ResourcesQuery['customers'];
  invoices: ResourcesQuery['invoices'];
  products: ResourcesQuery['products'];
  projects: ResourcesQuery['projects'];
  users: ResourcesQuery['users'];
  tags: ResourcesQuery['tags'];
  loading: boolean;
  refresh: () => Promise<any>;
}
const ResourceContext = createContext<Resources>({
  customers: [],
  invoices: [],
  products: [],
  projects: [],
  users: [],
  tags: [],
  loading: false,
  refresh: async () => {},
});

export const ResourceProvider: ComponentType<PropsWithChildren> = ({
  children,
}) => {
  const { data, loading, refetch } = useResourcesQuery();

  return (
    <ResourceContext.Provider
      value={{
        customers: data?.customers || [],
        invoices: data?.invoices || [],
        products: data?.products || [],
        projects: data?.projects || [],
        users: data?.users || [],
        tags: [...(data?.tags || [])].sort((t1, t2) =>
          t1.context.localeCompare(t2.context),
        ),
        loading,
        refresh: refetch,
      }}
    >
      {children}
    </ResourceContext.Provider>
  );
};

export function useResources() {
  return useContext(ResourceContext);
}
