import { Formatted } from '@/features/ui/formatted/formatted.component';
import classNames from 'classnames';
import { ComponentType } from 'react';
import { MogglesQuery } from '../../toggle-moggle.generated';
import * as styles from './moggle.module.scss';
interface MoggleProps {
  project: MogglesQuery['projects'][number];
  width: number;
  onToggle: () => void;
}

export const Moggle: ComponentType<MoggleProps> = (props) => {
  const { project, width, onToggle } = props;

  return (
    <div
      className={classNames(styles.host, project.openTimelog && styles.open)}
      style={{ width: `${width * 100}%`, paddingTop: `${width * 100}%` }}
    >
      <div className={styles.inner}>
        <div className={styles.title}>{project.title}</div>
        <div className={classNames(styles.moggleStats, styles.timeSpent)}>
          <div className={styles.label}>Total</div>
          <Formatted.Duration value={project.timeSpent} />
        </div>
        {project.openTimelog && (
          <div className={classNames(styles.moggleStats, styles.clock)}>
            <div className={styles.label}>Current</div>
            <div id={`clock-${project.id}`} className={styles.clockValue}></div>
          </div>
        )}

        <div
          className={classNames(
            styles.button,
            project.openTimelog ? styles.stop : styles.start,
          )}
          onClick={onToggle}
        ></div>
      </div>
    </div>
  );
};
