import React, { ComponentType } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Edit } from './edit/edit.component';
import { List } from './list/list.component';

export const Products: ComponentType = (props) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="/list" element={<Edit />} />
        <Route path="/create" element={<Edit />} />
        <Route path="/:id/edit" element={<Edit />} />
      </Routes>
    </>
  );
};
