// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BplxWhCqc2La9tsWf1bY{font-size:12px}.jowaHSAEL9bO1jQp9vxB{color:#540d6e;font-weight:bold;font-size:1.2em}.q9yt0B1Qngald_aW_lcI{color:#101e6e}.VEKpDKFGBbMt2VQL8_NS{font-size:12px;color:silver}table td{vertical-align:top}.ui.table thead th{vertical-align:bottom}.pos-relative{position:relative;width:100%}`, "",{"version":3,"sources":["webpack://./src/styles/globals.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEA,sBACE,cAAA,CAGF,sBACE,aCLc,CDMd,gBAAA,CACA,eAAA,CAGF,sBACE,aCRW,CDWb,sBACE,cAAA,CACA,YCViB,CDanB,SACE,kBAAA,CAGF,mBACE,qBAAA,CAGF,cACE,iBAAA,CACA,UAAA","sourcesContent":["@import 'styles/variables';\n\n.small {\n  font-size: 12px;\n}\n\n.highlight {\n  color: $color-highlight;\n  font-weight: bold;\n  font-size: 1.2em;\n}\n\n.primary {\n  color: $color-primary;\n}\n\n.secondary {\n  font-size: 12px;\n  color: $color-secondary;\n}\n\ntable td {\n  vertical-align: top;\n}\n\n:global(.ui.table) thead th {\n  vertical-align: bottom;\n}\n\n:global(.pos-relative) {\n  position: relative;\n  width: 100%;\n}\n","$color-white: #ffffff;\n$color-black: #000000;\n$color-magenta: #540d6e;\n$color-x: #38ff73;\n$color-neon-yellow: #c8ff00;\n$color-blue: #101e6e;\n$color-blue-light: #85b7d9;\n$color-lighter-grey: #f3fcf0;\n$color-light-grey: #c0c0c0;\n$color-medium-grey: #989788;\n$color-dark-grey: #313131;\n\n$color-brand: $color-neon-yellow;\n$color-accent: $color-neon-yellow;\n$color-highlight: $color-magenta;\n$color-primary: $color-blue;\n$color-secondary: $color-light-grey;\n$color-sidebar-bg: $color-light-grey;\n\n$font-size-base: 10px;\n$font-size-text: 1.6rem;\n\n$height-titlebar: 4rem;\n$width-sidebar: 210px;\n"],"sourceRoot":""}]);
// Exports
export var small = `BplxWhCqc2La9tsWf1bY`;
export var highlight = `jowaHSAEL9bO1jQp9vxB`;
export var primary = `q9yt0B1Qngald_aW_lcI`;
export var secondary = `VEKpDKFGBbMt2VQL8_NS`;
export default ___CSS_LOADER_EXPORT___;
