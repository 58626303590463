import { Frame } from '@/features/ui/frame/frame';
import { format, sub } from 'date-fns';
import { useMemo } from 'react';
import { Grid } from 'semantic-ui-react';
import { useResources } from '../resources/resources.provider';
import { Formatted } from '../ui/formatted/formatted.component';
import { Changelog } from './changelog/changelog.component';
import { changes } from './changelog/changes';
import { useYesterdaysJamQuery } from './dashboard.generated';
import * as styles from './dashboard.module.scss';

const todayFormatted = format(sub(new Date(), { days: 1 }), 'y-MM-dd', {});
export const Dashboard = () => {
  const { users } = useResources();
  const timelogsQuery = useYesterdaysJamQuery({
    variables: {
      filter: { range: `${todayFormatted},${todayFormatted}` },
    },
  });

  const timelogsPerUser = useMemo(() => {
    if (!timelogsQuery.data) {
      return {};
    }
    return timelogsQuery.data.timelogs.data.reduce((prev, current) => {
      let sum = prev[current.user.id] || 0;
      sum = sum + (current.duration || 0);
      return { ...prev, [current.user.id]: sum };
    }, {});
  }, [timelogsQuery.data?.timelogs]);

  const highscore = useMemo(() => {
    const highscore = Object.entries(timelogsPerUser).map(([key, value]) => {
      return {
        duration: value as number,
        name: users.find((u) => u.id === key)?.firstName,
      };
    });
    return highscore
      .filter((h) => h.duration > 0)
      .sort((a, b) => {
        if (a.duration < b.duration) {
          return 1;
        }
        if (a.duration > b.duration) {
          return -1;
        }
        return 0;
      });
  }, [timelogsPerUser, users]);

  return (
    <>
      <Frame.TitleBar>
        <Frame.Title>Dashboard</Frame.Title>
      </Frame.TitleBar>
      <Frame.Content>
        <div className={styles.host}>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <div
                  className={styles.backgroundImage}
                  style={{ backgroundImage: `url(https://picsum.photos/200)` }}
                />
                <div className={styles.content}>
                  <h2 className={styles.crazy}>
                    Employee of the 👈 <em>yester</em>day 👈
                  </h2>
                  <div className={styles.highscore}>
                    {highscore.length === 0 && (
                      <>
                        <p>ZZzzzz zz zz z ...</p>
                      </>
                    )}
                    {highscore.length > 0 && (
                      <ul>
                        {highscore.map((item, index) => (
                          <li key={item.name}>
                            <div className={styles.position}>{index + 1}</div>
                            <div className={styles.name}>
                              {index === 0 && <>⭐</>}
                              {item.name}
                              {index === 0 && <>⭐</>}
                            </div>
                            <div className={styles.duration}>
                              <Formatted.Duration
                                unit="h"
                                value={item.duration}
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column>
                <h2>Changes</h2>
                <Changelog changes={changes} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Frame.Content>
    </>
  );
};
