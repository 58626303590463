import React from 'react';
import { Table } from 'semantic-ui-react';

interface DayDetailsProps {
  date: string;
  data: Array<{
    username: string;
    hoursLogged: number;
  }>;
}

const DayDetails: React.FC<DayDetailsProps> = ({ date, data }) => {
  const totalHours = data.reduce((sum, entry) => sum + entry.hoursLogged, 0);

  return (
    <div style={{ margin: '2em 4em' }}>
      <h3>Hours Logged on {new Date(date).toLocaleDateString()}</h3>
      <Table celled style={{ width: '30%'}}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Username</Table.HeaderCell>
            <Table.HeaderCell>Hours Logged</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((entry, index) => (
            <Table.Row key={index}>
              <Table.Cell>{entry.username}</Table.Cell>
              <Table.Cell>{entry.hoursLogged.toFixed(2)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>Total</Table.HeaderCell>
            <Table.HeaderCell>{totalHours.toFixed(2)}</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
};

export default DayDetails;