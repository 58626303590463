import { useApolloClient } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';
import { LoginMutationVariables, useLoginMutation } from './login.generated';

export type LoginFormValues = LoginMutationVariables;
export const validationSchema = object({
  email: string().email().required(),
  password: string().required(),
});

export function useLoginForm(redirectTo = '/') {
  const [serverError, setServerError] = useState<string | false>(false);
  const navigate = useNavigate();
  const client = useApolloClient();
  const { handleSubmit, ...form } = useForm<LoginFormValues>({
    resolver: yupResolver(validationSchema),
  });

  const [login, { loading }] = useLoginMutation();

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (variables) => {
        try {
          const { data } = await login({ variables });

          if (data) {
            if (data.authenticate) {
              await client.resetStore();
              navigate(redirectTo, { replace: true });
            } else {
              setServerError('Wrong user or password.');
            }
          }
        } catch (e: any) {
          setServerError(e.message);
        }
      }),
    [handleSubmit]
  );

  return { ...form, loading, onSubmit, serverError, setServerError };
}
