import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditCustomerDataFragment = { __typename?: 'Customer', id: any, customerNumber?: number | null, firstName?: string | null, lastName?: string | null, companyName?: string | null, street?: string | null, phone?: string | null, city?: string | null, postalCode?: string | null, country?: string | null, label: string, contactEmail: string, slugs: string, amountPerHour?: number | null };

export type CustomerQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type CustomerQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', id: any, customerNumber?: number | null, firstName?: string | null, lastName?: string | null, companyName?: string | null, street?: string | null, phone?: string | null, city?: string | null, postalCode?: string | null, country?: string | null, label: string, contactEmail: string, slugs: string, amountPerHour?: number | null } };

export type UpdateCustomerMutationVariables = Types.Exact<{
  input: Types.SaveCustomerInput;
  id: Types.Scalars['UUID']['input'];
}>;


export type UpdateCustomerMutation = { __typename?: 'Mutation', updateCustomer: { __typename?: 'Customer', id: any, customerNumber?: number | null, firstName?: string | null, lastName?: string | null, companyName?: string | null, street?: string | null, phone?: string | null, city?: string | null, postalCode?: string | null, country?: string | null, label: string, contactEmail: string, slugs: string, amountPerHour?: number | null } };

export type CreateCustomerMutationVariables = Types.Exact<{
  input: Types.SaveCustomerInput;
}>;


export type CreateCustomerMutation = { __typename?: 'Mutation', createCustomer: { __typename?: 'Customer', id: any, customerNumber?: number | null, firstName?: string | null, lastName?: string | null, companyName?: string | null, street?: string | null, phone?: string | null, city?: string | null, postalCode?: string | null, country?: string | null, label: string, contactEmail: string, slugs: string, amountPerHour?: number | null } };

export type SoftDeleteCustomerMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type SoftDeleteCustomerMutation = { __typename?: 'Mutation', softDeleteCustomer: boolean };

export const EditCustomerDataFragmentDoc = gql`
    fragment EditCustomerData on Customer {
  id
  customerNumber
  firstName
  lastName
  companyName
  street
  phone
  city
  postalCode
  country
  label
  contactEmail
  slugs
  amountPerHour
}
    `;
export const CustomerDocument = gql`
    query customer($id: UUID!) {
  customer(id: $id) {
    ...EditCustomerData
  }
}
    ${EditCustomerDataFragmentDoc}`;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerQuery(baseOptions: Apollo.QueryHookOptions<CustomerQuery, CustomerQueryVariables> & ({ variables: CustomerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
      }
export function useCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
        }
export function useCustomerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerSuspenseQueryHookResult = ReturnType<typeof useCustomerSuspenseQuery>;
export type CustomerQueryResult = Apollo.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($input: SaveCustomerInput!, $id: UUID!) {
  updateCustomer(input: $input, id: $id) {
    ...EditCustomerData
  }
}
    ${EditCustomerDataFragmentDoc}`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation createCustomer($input: SaveCustomerInput!) {
  createCustomer(input: $input) {
    ...EditCustomerData
  }
}
    ${EditCustomerDataFragmentDoc}`;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const SoftDeleteCustomerDocument = gql`
    mutation softDeleteCustomer($id: UUID!) {
  softDeleteCustomer(id: $id)
}
    `;
export type SoftDeleteCustomerMutationFn = Apollo.MutationFunction<SoftDeleteCustomerMutation, SoftDeleteCustomerMutationVariables>;

/**
 * __useSoftDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useSoftDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteCustomerMutation, { data, loading, error }] = useSoftDeleteCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoftDeleteCustomerMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteCustomerMutation, SoftDeleteCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteCustomerMutation, SoftDeleteCustomerMutationVariables>(SoftDeleteCustomerDocument, options);
      }
export type SoftDeleteCustomerMutationHookResult = ReturnType<typeof useSoftDeleteCustomerMutation>;
export type SoftDeleteCustomerMutationResult = Apollo.MutationResult<SoftDeleteCustomerMutation>;
export type SoftDeleteCustomerMutationOptions = Apollo.BaseMutationOptions<SoftDeleteCustomerMutation, SoftDeleteCustomerMutationVariables>;