import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerListDataFragment = { __typename?: 'Customer', id: any, customerNumber?: number | null, firstName?: string | null, lastName?: string | null, companyName?: string | null, contactEmail: string, phone?: string | null, label: string, longLabel: string, slugs: string, amountPerHour?: number | null, timeSpent: number };

export type CustomersQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.SearchCustomersInput>;
}>;


export type CustomersQuery = { __typename?: 'Query', customers: Array<{ __typename?: 'Customer', id: any, customerNumber?: number | null, firstName?: string | null, lastName?: string | null, companyName?: string | null, contactEmail: string, phone?: string | null, label: string, longLabel: string, slugs: string, amountPerHour?: number | null, timeSpent: number }> };

export const CustomerListDataFragmentDoc = gql`
    fragment CustomerListData on Customer {
  id
  customerNumber
  firstName
  lastName
  companyName
  contactEmail
  phone
  label
  longLabel
  slugs
  amountPerHour
  timeSpent
}
    `;
export const CustomersDocument = gql`
    query customers($filter: SearchCustomersInput) {
  customers(filter: $filter) {
    ...CustomerListData
  }
}
    ${CustomerListDataFragmentDoc}`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCustomersQuery(baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
      }
export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
        }
export function useCustomersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
        }
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersSuspenseQueryHookResult = ReturnType<typeof useCustomersSuspenseQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;