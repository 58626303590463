import { Formatted } from '@/features/ui/formatted/formatted.component';
import { Frame } from '@/features/ui/frame/frame';
import { ComponentType } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dimmer, Loader, Table } from 'semantic-ui-react';
import { useServicesQuery } from './list.generated';

export const List: ComponentType = () => {
  const { data, loading } = useServicesQuery();
  const services = data?.services;

  return (
    <>
      <Frame.TitleBar>
        <Frame.Title>Services</Frame.Title>
      </Frame.TitleBar>
      <Frame.Content>
        {loading && (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}
        {!loading && (
          <>
            {services && services.length > 0 ? (
              <Table basic="very" size="small">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.HeaderCell>Created at</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {services.map(({ id, startDate, product, customer }) => (
                    <Table.Row key={id}>
                      <Table.Cell>{product.name}</Table.Cell>
                      <Table.Cell>
                        <Formatted.Date value={startDate} withTime />
                      </Table.Cell>
                      <Table.Cell>
                        <Link
                          to={`/customers/${customer.id}/services/${id}/edit`}
                        >
                          <Button basic>Details</Button>
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <h2>
                Oops, it seems like you have no services recorded in the
                system...
              </h2>
            )}
          </>
        )}
      </Frame.Content>
    </>
  );
};
