import { Content } from './content/content.component';
import { MainFrame } from './mainFrame/mainFrame.component';
import { RightSide } from './rightSide/rightSide.component';
import { Sidebar } from './sidebar/sidebar.component';
import { Actions } from './titleBar/actions/actions.component';
import { Title } from './titleBar/title/title.component';
import { TitleBar } from './titleBar/titleBar.component';

export const Frame = Object.assign(
  {},
  { MainFrame, Sidebar, RightSide, TitleBar, Title, Actions, Content }
);
