import React, { ComponentType, ReactNode } from 'react';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import * as styles from './checkbox-field.module.scss';

interface CheckboxFieldProps {
  checked: boolean;
  onChange: (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps,
  ) => void;
  field: ReactNode;
  label: string;
}

export const CheckboxField: ComponentType<CheckboxFieldProps> = (props) => {
  const { checked, onChange, field, label } = props;
  return (
    <>
      <div className={styles.host}>
        <div className={styles.checkbox}>
          <Checkbox checked={checked} onChange={onChange} />
        </div>
        {checked && field}
        {!checked && (
          <div className="field">
            <label>{label}</label>
            <p className={styles.untouchedLabel}>Leave untouched.</p>
          </div>
        )}
      </div>
    </>
  );
};
