// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R7CF1zN4waSpU3gAwR7b{width:100%;height:40px;background-color:#000}.kUbs5EsEvNZR2BzKl3_F{width:210px}._lF04_Afyy6_SHAo4caI{height:30px}.q_lgAZWZe8hFCa5O7nGj{display:block;position:relative}`, "",{"version":3,"sources":["webpack://./src/features/ui/navigation/navigation.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,qBAAA,CAGF,sBACE,WAAA,CAGF,sBACE,WAAA,CAEF,sBACE,aAAA,CACA,iBAAA","sourcesContent":[".topbar {\n  width: 100%;\n  height: 40px;\n  background-color: black;\n}\n\n.sidebar {\n  width: 210px;\n}\n\n.hr {\n  height: 30px;\n}\n.link {\n  display: block;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
export var topbar = `R7CF1zN4waSpU3gAwR7b`;
export var sidebar = `kUbs5EsEvNZR2BzKl3_F`;
export var hr = `_lF04_Afyy6_SHAo4caI`;
export var link = `q_lgAZWZe8hFCa5O7nGj`;
export default ___CSS_LOADER_EXPORT___;
