import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TimelogDataFragment = { __typename?: 'Timelog', id: any, date: any, duration?: number | null, description?: string | null, billable: boolean, billedAt?: any | null, flagged: boolean, calculatedAmountPerHour: number, tagIds: Array<any>, tags: Array<{ __typename?: 'Tag', id: any, name: string, context: string }>, issue: { __typename?: 'Issue', id: any, ticketId: string, title?: string | null, billable: boolean, project?: { __typename?: 'Project', id: any, title: string, customer: { __typename?: 'Customer', id: any, label: string } } | null }, user: { __typename?: 'User', id: any, firstName: string, lastName: string, email: string } };

export type TimelogsQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
  filter?: Types.InputMaybe<Types.FilterTimelogsInput>;
}>;


export type TimelogsQuery = { __typename?: 'Query', timelogs: { __typename?: 'PaginatedTimelogsResult', totalCount: number, totalDuration: number, data: Array<{ __typename?: 'Timelog', id: any, date: any, duration?: number | null, description?: string | null, billable: boolean, billedAt?: any | null, flagged: boolean, calculatedAmountPerHour: number, tagIds: Array<any>, tags: Array<{ __typename?: 'Tag', id: any, name: string, context: string }>, issue: { __typename?: 'Issue', id: any, ticketId: string, title?: string | null, billable: boolean, project?: { __typename?: 'Project', id: any, title: string, customer: { __typename?: 'Customer', id: any, label: string } } | null }, user: { __typename?: 'User', id: any, firstName: string, lastName: string, email: string } }> } };

export type ToggleTimelogBilledMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type ToggleTimelogBilledMutation = { __typename?: 'Mutation', toggleTimelogBilled: { __typename?: 'Timelog', id: any, billedAt?: any | null } };

export type ToggleTimelogFlaggedMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type ToggleTimelogFlaggedMutation = { __typename?: 'Mutation', toggleTimelogFlagged: { __typename?: 'Timelog', id: any, flagged: boolean } };

export type SoftDeleteTimelogsMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input'];
}>;


export type SoftDeleteTimelogsMutation = { __typename?: 'Mutation', softDeleteTimelogs: boolean };

export const TimelogDataFragmentDoc = gql`
    fragment TimelogData on Timelog {
  id
  date
  duration
  description
  billable
  billedAt
  flagged
  calculatedAmountPerHour
  tagIds
  tags {
    id
    name
    context
  }
  issue {
    id
    ticketId
    title
    billable
    project {
      id
      title
      customer {
        id
        label
      }
    }
  }
  user {
    id
    firstName
    lastName
    email
  }
}
    `;
export const TimelogsDocument = gql`
    query timelogs($pagination: PaginationInput!, $filter: FilterTimelogsInput) {
  timelogs(pagination: $pagination, filter: $filter) {
    data {
      ...TimelogData
    }
    totalCount
    totalDuration
  }
}
    ${TimelogDataFragmentDoc}`;

/**
 * __useTimelogsQuery__
 *
 * To run a query within a React component, call `useTimelogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelogsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTimelogsQuery(baseOptions: Apollo.QueryHookOptions<TimelogsQuery, TimelogsQueryVariables> & ({ variables: TimelogsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimelogsQuery, TimelogsQueryVariables>(TimelogsDocument, options);
      }
export function useTimelogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimelogsQuery, TimelogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimelogsQuery, TimelogsQueryVariables>(TimelogsDocument, options);
        }
export function useTimelogsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TimelogsQuery, TimelogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TimelogsQuery, TimelogsQueryVariables>(TimelogsDocument, options);
        }
export type TimelogsQueryHookResult = ReturnType<typeof useTimelogsQuery>;
export type TimelogsLazyQueryHookResult = ReturnType<typeof useTimelogsLazyQuery>;
export type TimelogsSuspenseQueryHookResult = ReturnType<typeof useTimelogsSuspenseQuery>;
export type TimelogsQueryResult = Apollo.QueryResult<TimelogsQuery, TimelogsQueryVariables>;
export const ToggleTimelogBilledDocument = gql`
    mutation toggleTimelogBilled($id: UUID!) {
  toggleTimelogBilled(id: $id) {
    id
    billedAt
  }
}
    `;
export type ToggleTimelogBilledMutationFn = Apollo.MutationFunction<ToggleTimelogBilledMutation, ToggleTimelogBilledMutationVariables>;

/**
 * __useToggleTimelogBilledMutation__
 *
 * To run a mutation, you first call `useToggleTimelogBilledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleTimelogBilledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleTimelogBilledMutation, { data, loading, error }] = useToggleTimelogBilledMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleTimelogBilledMutation(baseOptions?: Apollo.MutationHookOptions<ToggleTimelogBilledMutation, ToggleTimelogBilledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleTimelogBilledMutation, ToggleTimelogBilledMutationVariables>(ToggleTimelogBilledDocument, options);
      }
export type ToggleTimelogBilledMutationHookResult = ReturnType<typeof useToggleTimelogBilledMutation>;
export type ToggleTimelogBilledMutationResult = Apollo.MutationResult<ToggleTimelogBilledMutation>;
export type ToggleTimelogBilledMutationOptions = Apollo.BaseMutationOptions<ToggleTimelogBilledMutation, ToggleTimelogBilledMutationVariables>;
export const ToggleTimelogFlaggedDocument = gql`
    mutation toggleTimelogFlagged($id: UUID!) {
  toggleTimelogFlagged(id: $id) {
    id
    flagged
  }
}
    `;
export type ToggleTimelogFlaggedMutationFn = Apollo.MutationFunction<ToggleTimelogFlaggedMutation, ToggleTimelogFlaggedMutationVariables>;

/**
 * __useToggleTimelogFlaggedMutation__
 *
 * To run a mutation, you first call `useToggleTimelogFlaggedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleTimelogFlaggedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleTimelogFlaggedMutation, { data, loading, error }] = useToggleTimelogFlaggedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleTimelogFlaggedMutation(baseOptions?: Apollo.MutationHookOptions<ToggleTimelogFlaggedMutation, ToggleTimelogFlaggedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleTimelogFlaggedMutation, ToggleTimelogFlaggedMutationVariables>(ToggleTimelogFlaggedDocument, options);
      }
export type ToggleTimelogFlaggedMutationHookResult = ReturnType<typeof useToggleTimelogFlaggedMutation>;
export type ToggleTimelogFlaggedMutationResult = Apollo.MutationResult<ToggleTimelogFlaggedMutation>;
export type ToggleTimelogFlaggedMutationOptions = Apollo.BaseMutationOptions<ToggleTimelogFlaggedMutation, ToggleTimelogFlaggedMutationVariables>;
export const SoftDeleteTimelogsDocument = gql`
    mutation softDeleteTimelogs($ids: [UUID!]!) {
  softDeleteTimelogs(ids: $ids)
}
    `;
export type SoftDeleteTimelogsMutationFn = Apollo.MutationFunction<SoftDeleteTimelogsMutation, SoftDeleteTimelogsMutationVariables>;

/**
 * __useSoftDeleteTimelogsMutation__
 *
 * To run a mutation, you first call `useSoftDeleteTimelogsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteTimelogsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteTimelogsMutation, { data, loading, error }] = useSoftDeleteTimelogsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSoftDeleteTimelogsMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteTimelogsMutation, SoftDeleteTimelogsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteTimelogsMutation, SoftDeleteTimelogsMutationVariables>(SoftDeleteTimelogsDocument, options);
      }
export type SoftDeleteTimelogsMutationHookResult = ReturnType<typeof useSoftDeleteTimelogsMutation>;
export type SoftDeleteTimelogsMutationResult = Apollo.MutationResult<SoftDeleteTimelogsMutation>;
export type SoftDeleteTimelogsMutationOptions = Apollo.BaseMutationOptions<SoftDeleteTimelogsMutation, SoftDeleteTimelogsMutationVariables>;