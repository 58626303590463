import { ApolloQueryResult } from '@apollo/client';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import {
  CurrentProfileQuery,
  ProfileDataFragment,
  useCurrentProfileQuery,
} from './profile.generated';

export enum ProfileStatus {
  LOADING,
  AVAILABLE,
  UNAVAILABLE,
}

export type ProfileContext = [
  ProfileStatus,
  ProfileDataFragment | undefined,
  () => Promise<ApolloQueryResult<CurrentProfileQuery>>
];

const Context = createContext<ProfileContext>(undefined as any);

// eslint-disable-next-line @typescript-eslint/ban-types
function Provider(props: PropsWithChildren<{}>) {
  const { children } = props;
  const query = useCurrentProfileQuery({
    fetchPolicy: 'cache-first',
    errorPolicy: 'ignore',
  });

  const status = useMemo(() => {
    if (query.loading) {
      return ProfileStatus.LOADING;
    }

    if (query.data?.profile) {
      return ProfileStatus.AVAILABLE;
    }

    return ProfileStatus.UNAVAILABLE;
  }, [query]);

  const value = useMemo<ProfileContext>(
    () => [status, query.data?.profile || undefined, query.refetch],
    [status, query.data]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

function use() {
  return useContext(Context)[1];
}

function useStatus() {
  return useContext(Context)[0];
}

function useReload() {
  return useContext(Context)[2];
}

export const Profile = Object.assign(Provider, {
  use,
  useStatus,
  useReload,
});
