import { array, boolean, number, object, string } from 'yup';

export default object().shape({
  userId: string().nullable(),
  date: string().nullable(),
  issueId: string().nullable(),
  customerId: string().nullable(),
  description: string().nullable(),
  duration: number().integer().min(1).nullable(),
  amountPerHour: number().nullable(),
  tagIds: array().of(string()).nullable(),
  flagged: boolean(),
});
