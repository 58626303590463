import { Formatted } from '@/features/ui/formatted/formatted.component';
import { Frame } from '@/features/ui/frame/frame';
import { ComponentType } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Dimmer, Loader, Table, TableRow } from 'semantic-ui-react';
import { useCustomerQuery } from '../../edit/edit.generated';
import { useServicesByCustomerQuery } from './services.generated';

interface ServicesProps {}

export const Services: ComponentType<ServicesProps> = (props) => {
  const navigate = useNavigate();
  const { customerId } = useParams();

  const { data: customerData } = useCustomerQuery({
    variables: { id: customerId },
    skip: customerId === undefined,
    fetchPolicy: 'network-only',
  });

  const { loading, error, data } = useServicesByCustomerQuery({
    variables: { id: customerId },
    skip: customerId === undefined,
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Frame.TitleBar>
        <Frame.Title>Services for {customerData?.customer.label}</Frame.Title>
        <Frame.Actions>
          <div>
            <Button
              basic
              color="black"
              onClick={() =>
                navigate(
                  `/customers/${customerData?.customer.id}/services/create`
                )
              }
            >
              Create Service for {customerData?.customer.label}
            </Button>
            <Button basic color="red" onClick={() => navigate('/customers')}>
              Cancel
            </Button>
          </div>
        </Frame.Actions>
      </Frame.TitleBar>
      <Frame.Content>
        {loading && (
          <Dimmer>
            <Loader />
          </Dimmer>
        )}
        <Table basic="very" size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Product</Table.HeaderCell>
              <Table.HeaderCell>Start Date</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data?.servicesByCustomer.map((service) => (
              <TableRow key={service.id}>
                <Table.Cell>{service.product.name}</Table.Cell>
                <Table.Cell>
                  <Formatted.Date value={service.startDate} />
                </Table.Cell>
                <Table.Cell>
                  <Link
                    to={`/customers/${customerData?.customer.id}/services/${service.id}/edit`}
                  >
                    <Button basic>Details</Button>
                  </Link>
                </Table.Cell>
              </TableRow>
            ))}
          </Table.Body>
        </Table>
      </Frame.Content>
    </>
  );
};
