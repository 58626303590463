import { useResources } from '@/features/resources/resources.provider';
import { InputField } from '@/features/ui/form/input-field.component';
import { SelectField } from '@/features/ui/form/select-field.component';
import { Frame } from '@/features/ui/frame/frame';
import { useConfirm } from '@/features/ui/modal/modal.hook';
import { AmountType, CreateProductInput } from '@/graphql/generated/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { ComponentType, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'semantic-ui-react';
import * as styles from '../../../styles/Edit.module.scss';
import {
  useCreateProductMutation,
  useProductQuery,
  useUpdateProductMutation,
} from './edit.generated';
import schema from './schema';

export const Edit: ComponentType = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { loading, error, data } = useProductQuery({
    variables: { id },
    skip: id === undefined,
    fetchPolicy: 'network-only',
  });
  const product = data?.product;

  const { control, handleSubmit, reset, formState, setValue } =
    useForm<CreateProductInput>({
      resolver: yupResolver(schema),
      shouldUnregister: true,
    });

  const { refresh } = useResources();

  const [modal, { ask }] = useConfirm((id: any) => {
    alert('Delete function to implement');
  });

  const [create] = useCreateProductMutation();
  const [update] = useUpdateProductMutation();

  useEffect(() => {
    if (product) {
      reset({
        name: product.name,
        amount: product.amount,
      });
    }
  }, [product]);

  const onSubmit: SubmitHandler<CreateProductInput> = async (input) => {
    if (id) {
      try {
        await update({ variables: { id, input } });
        await refresh();
        toast.success('Product successfully updated!');
      } catch (err) {
        toast.error('An error occurred :(  ' + err);
      }
    } else {
      try {
        const result = await create({ variables: { input } });
        if (result.data) {
          navigate(`/products/${result.data.createProduct.id}/edit`);
          await refresh();
          toast.success('New product successfully saved!');
        }
      } catch (err) {
        toast.error('An error occurred :(  ' + err);
      }
    }
  };

  const onError = (error) => {
    console.log(error);
  };

  return (
    <>
      <Frame.TitleBar>
        <Frame.Title>{id ? 'Edit Product' : 'Create Product'}</Frame.Title>
        <Frame.Actions>
          <Button basic color="red" onClick={() => navigate('/products')}>
            Cancel
          </Button>
        </Frame.Actions>
      </Frame.TitleBar>
      <Frame.Content>
        <Form
          className={styles.form}
          onSubmit={handleSubmit(onSubmit, onError)}
          error={!formState.isValid}
        >
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <InputField label="Name" field={field} fieldState={fieldState} />
            )}
          />
          <Controller
            name="amount.value"
            control={control}
            render={({ field, fieldState }) => (
              <InputField
                label="Amount"
                field={field}
                fieldState={fieldState}
              />
            )}
          />
          <Controller
            name="amount.type"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <SelectField
                  options={Object.entries(AmountType).map(([text, value]) => ({
                    text,
                    value,
                  }))}
                  setValue={setValue}
                  label="Type"
                  field={field}
                  fieldState={fieldState}
                />
              </>
            )}
          />
          <Button.Group>
            <Button type="submit" color="green">
              Save
            </Button>
            {/* {id && (
              <Button
                type="button"
                color="red"
                basic
                onClick={() =>
                  ask(`Are you sure you want to delete '${product?.name}'?`)
                }
              >
                Delete
              </Button>
            )} */}
          </Button.Group>
          {modal}
        </Form>
      </Frame.Content>
    </>
  );
};
