import { useResources } from '@/features/resources/resources.provider';
import { Formatted } from '@/features/ui/formatted/formatted.component';
import { Frame } from '@/features/ui/frame/frame';
import { ComponentType } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Dimmer, Loader, Table } from 'semantic-ui-react';

export const List: ComponentType = () => {
  let navigate = useNavigate();
  const { products, loading } = useResources();

  return (
    <>
      <Frame.TitleBar>
        <Frame.Title>Products</Frame.Title>
        <Frame.Actions>
          <Button
            basic
            color="black"
            onClick={() => navigate('/products/create')}
          >
            Create Product
          </Button>
        </Frame.Actions>
      </Frame.TitleBar>
      <Frame.Content>
        {loading && (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}
        {!loading && (
          <>
            {products.length > 0 ? (
              <Table basic="very" size="small">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Net Amount</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {products.map(({ id, name, amount }) => (
                    <Table.Row key={id}>
                      <Table.Cell>{name}</Table.Cell>
                      <Table.Cell>
                        <Formatted.Currency value={amount.net} />
                      </Table.Cell>
                      <Table.Cell>
                        <Link to={`/products/${id}/edit`}>
                          <Button basic>Details</Button>
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <h2>
                Oops, it seems like you have no products recorded in the
                system...
              </h2>
            )}
          </>
        )}
      </Frame.Content>
    </>
  );
};
