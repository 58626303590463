import { useDebounce } from '@/features/utils/debounce';
import { ComponentType, useEffect, useState } from 'react';
import { Input, InputProps } from 'semantic-ui-react';

interface DebouncedInputProps extends Omit<InputProps, 'onChange' | 'action'> {
  delay: number;
  onDebouncedChange: (value: any) => void;
  clearable?: boolean;
}

export const DebouncedInput: ComponentType<DebouncedInputProps> = (props) => {
  const { clearable, delay, value, onDebouncedChange, ...rest } = props;

  const [internalValue, setInternalValue] = useState(
    props.defaultValue || props.value,
  );

  const debouncedValue = useDebounce(internalValue, delay);

  useEffect(() => {
    onDebouncedChange(debouncedValue);
  }, [debouncedValue]);

  return (
    <>
      <Input
        {...rest}
        icon="search"
        // action={
        //   clearable ? (
        //     <Button icon="close" onClick={() => setInternalValue('')} />
        //   ) : undefined
        // }
        onChange={(_, data) => setInternalValue(data.value)}
        value={internalValue}
      />
    </>
  );
};
